<script lang="ts">
  import { goto } from "@sveltech/routify";
  import type Organization from "api/org/Organization";
  import { Activity } from "api/shared/Activity";
  import Spinner from "components/Spinner.svelte";
  import { ApiClient } from "setup";
  import { FirstOrg, HasPermission } from "state/Authentication";
  import { _ } from "svelte-i18n";
  import { fade } from "svelte/transition";
  import OrgPartial from "./_Org.svelte";

  if (!$HasPermission(Activity.OrganizationRead) && $FirstOrg) {
    $goto(`/orgs/${$FirstOrg}/zones`);
  }

  let orgs: Promise<Organization[]> = ApiClient.Org.List();
</script>

<style lang="scss">
  h5 {
    opacity: 0.6;
  }
</style>

<h5>{$_('organizations')}</h5>
{#await orgs}
  <Spinner size="large" />
{:then _orgs}
  <div in:fade class="row">
    {#each _orgs as org}
      <div class="col s12 m6 l4">
        <OrgPartial organization={org} />
      </div>
    {/each}
  </div>
{:catch err}
  <div in:fade class="center">{err}</div>
{/await}
