export default class AlertConfigClient {
    constructor(restClient) {
        this.Client = restClient;
    }
    List(orgID, zoneID, unitID) {
        return this.Client.Get(`/orgs/${orgID}/zones/${zoneID}/units/${unitID}/alertConfigurations`).Expect(200);
    }
    Get(orgID, zoneID, unitID, alertConfigID) {
        return this.Client.Get(`/orgs/${orgID}/zones/${zoneID}/units/${unitID}/alertConfigurations/${alertConfigID}`).Expect(200);
    }
}
