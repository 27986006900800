<script lang="ts">
  export let size: string = "medium";
</script>

<style lang="scss">
  @import "theme";

  div {
    position: relative;
    width: 100%;
    div,
    div:after {
      border-radius: 50%;
      // width: 5em;
      // height: 5em;
      top: 50%;
      left: 50%;
      // margin: -2.5em;
      visibility: visible;
      font-size: 10px;
      position: absolute;
      text-indent: -9999em;
      border-top: 0.3em solid rgba(255, 255, 255, 0.2);
      border-right: 0.3em solid rgba(255, 255, 255, 0.2);
      border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
      border-left: 0.3em solid $primary-color;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }

    &.medium {
      div {
        width: 5em;
        margin: -2.5em;
      }
      height: 5em;
    }

    &.small {
      div {
        width: 3em;
        margin: -1.5em;
      }
      height: 3em;
    }
    &.large {
      div {
        width: 10em;
        margin: -5em;
      }
      height: 10em;
    }
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>

<div
  class="spinner-container"
  class:large={size == 'large'}
  class:medium={size == 'medium'}
  class:small={size == 'small'}>
  <div
    class="spinner"
    class:large={size == 'large'}
    class:medium={size == 'medium'}
    class:small={size == 'small'} />
</div>
