<script lang="ts">
  import FloatingButton from "./FloatingButton.svelte";
  import Spinner from "./Spinner.svelte";
  import Collapsable from "./Collapsable.svelte";
  import CollapsableItem from "./CollapsableItem.svelte";
  import HiddenControls from "./HiddenControls.svelte";
  import Dialog from "./Dialog.svelte";
  import { fly } from "svelte/transition";
  import ContentBox from "./ContentBox.svelte";
  import Icon from "components/Icon.svelte";

  export let items: Promise<any[]>;
  let dialog: Dialog;
  export let deleteItem: (item: any) => void;
</script>

<style lang="scss">
  h5 {
    opacity: 70%;
    padding-bottom: 2rem;
    margin: 0.493333rem 0 0.456rem 0;
  }
</style>

<ContentBox>
  {#await items}
    <Spinner />
  {:then _items}
    <HiddenControls>
      <div slot="open">
        <FloatingButton>
          <Icon size="large">add</Icon>
        </FloatingButton>
      </div>
      <div>
        <h5>
          <slot name="list-title" />
        </h5>
        {#if _items.length > 0}
          <Collapsable accordion={true}>
            {#each _items as item}
              <CollapsableItem>
                <div slot="header" style="width: 100%">
                  {#if deleteItem}
                    <span
                      on:click|stopPropagation={() => {
                        dialog.Open(() => deleteItem(item));
                      }}
                      class="right waves-effect">
                      <Icon>delete</Icon>
                    </span>
                  {/if}
                  <h6>
                    <slot {item} name="item-header" />
                  </h6>
                </div>
                <slot {item} />
              </CollapsableItem>
            {/each}
          </Collapsable>
        {:else}
          <slot name="no-items" />
        {/if}
      </div>
      <div slot="controls" let:close>
        <FloatingButton
          on:click={() => {
            close();
          }}>
          <Icon size="large">close</Icon>
        </FloatingButton>
        <h5>
          <slot name="add-item-title" />
        </h5>
        <slot item={{}} />
      </div>
    </HiddenControls>

  {:catch err}
    <div class="center">{err}</div>
  {/await}
  <Dialog bind:this={dialog}>
    <slot name="are-you-sure">You sure bro?</slot>
  </Dialog>
</ContentBox>
