import { __awaiter } from "tslib";
import { Readable } from "./Readable";
function Deconstruct(args) {
    return [args.slice(0, args.length - 1), args[args.length - 1]];
}
export default class RestStore extends Readable {
    constructor() {
        super();
        this.Items = {};
    }
    List(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.Items[this.endpoint(args)]) {
                this.Items[this.endpoint(args)] = yield this.list(...args);
                this.SignalUpdate();
            }
            return this.Items[this.endpoint(args)];
        });
    }
    Get(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            let [path, key] = Deconstruct(args);
            if (this.Items[this.endpoint(path)]) {
                let item = this.Items[this.endpoint(path)].firstOrUndefined((item) => this.keySelector(item) == key);
                if (item) {
                    return Promise.resolve(item);
                }
            }
            return this.get(...args);
        });
    }
    Update(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            let [path, item] = Deconstruct(args);
            yield this.update(...args);
            for (let i = 0; i < this.Items[this.endpoint(path)].length; i++) {
                if (this.compare(this.Items[this.endpoint(path)][i], item)) {
                    this.Items[this.endpoint(path)][i] = item;
                    this.SignalUpdate();
                    return;
                }
            }
        });
    }
    Add(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            let [path, item] = Deconstruct(args);
            item = yield this.add(...args);
            this.Items[this.endpoint(path)].push(item);
            this.SignalUpdate();
        });
    }
    Delete(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            let [path, key] = Deconstruct(args);
            yield this.delete(...args);
            for (let i = 0; i < this.Items[this.endpoint(path)].length; i++) {
                if (this.keySelector(this.Items[this.endpoint(path)][i]) == key) {
                    this.Items[this.endpoint(path)].splice(i, 1);
                    this.SignalUpdate();
                    return;
                }
            }
        });
    }
    compare(a, b) {
        return this.keySelector(a) == this.keySelector(b);
    }
    endpoint(args) {
        return args.join("/");
    }
}
