export default {
    status: "Status",
    no_zones_for_mlai_configs: "There are currently no zones available to be configured for machine learning",
    prediction: "Prediction",
    anomaly_detection: "Anomaly detection",
    are_you_sure_you_want_to_train_this_model: "Are you sure you want to train this model?",
    model_data_points: "Model Data Points",
    training: "Training",
    error: "Error",
    add_mlai: "Add a machine learning",
    complete: "Complete",
    preparing: "Preparing",
    train_model: "Train Model",
    not_trained: "Not Trained",
    type: "Type",
};
