import "../../localization/i18n";
import { Translate } from "../../localization/i18n";
export default class AuthClient {
    constructor(restClient) {
        this.RestClient = restClient;
    }
    Login(username, password) {
        return this.RestClient.Post("/auth/login", {
            Username: username,
            Password: password,
        }).Expect(200, { 401: Translate("invalid_username_or_password") });
    }
    Refresh(renewToken) {
        return this.RestClient.Post("/auth/refresh", {
            RenewToken: renewToken,
        }).Expect(200);
    }
    SetPasswordWithToken(token, newPassword) {
        return this.RestClient.Post("/auth/password/token", {
            Token: token,
            Password: newPassword,
        }).Expect(204);
    }
    SetPassword(username, password, newPassword) {
        return this.RestClient.Post("/auth/password", {
            Email: username,
            OldPassword: password,
            NewPassword: newPassword,
        }).Expect(204);
    }
    ResetPassword(email, language) {
        return this.RestClient.Post("/auth/password/reset", {
            Email: email,
            Language: language,
        }).Expect(204);
    }
    Verify(request) {
        return this.RestClient.Post("/auth/verify", request).Expect(200);
    }
}
