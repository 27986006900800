<script lang="ts">
  import { onMount } from "svelte";
  import generateID from "../utils/id";
  import M from "materialize-css";
  import type { _ } from "svelte-i18n";
  import { Translate } from "localization/i18n";

  interface modalControl {
    open(): void;
    close(): void;
  }

  export let id = generateID();

  let modal: HTMLElement;
  let control: modalControl;
  let onCancel: () => void = () => {};
  let onSave: () => void = () => {};

  export const Open = function (save: () => any, cancel?: () => any) {
    onSave = save;
    onCancel = cancel ? cancel : onCancel;
    control.open();
  };

  export const Close = function () {
    control.close();
  };

  onMount(() => {
    control = M.Modal.init(modal, {
      dismissible: false,
    });
  });
</script>

<style lang="scss">
  .modal-footer:empty {
    display: none;
  }

  .modal.bottom-sheet {
    max-height: 80%;
  }

  .modal {
    z-index: 2000;
  }
</style>

<div bind:this={modal} {id} class="modal">
    <div class="modal-content">
      <h4>
        <slot />
      </h4>
    </div>
    <div class="modal-footer">
      <span
        on:click={() => {
          onSave();
          Close();
        }}
        class="modal-close waves-effect btn-flat">
        {Translate('save')}
      </span>
      <span
        on:click={() => {
          onCancel();
          Close();
        }}
        class="modal-close waves-effect btn-flat">
        {Translate('cancel')}
      </span>
    </div>
</div>
