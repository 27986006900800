export default {
    saved: "Saved",
    registration_complete: "Registration Complete!",
    connected: "Connected",
    basic_information: "Basic Information",
    mlai_configuration: "Ml/AI Configuration",
    phone_number: "Phone Number",
    password_number_requirement: "At least 1 number",
    password_successfully_changed: "Password Successfully Changed",
    events: "Events",
    serial_numbers: "Serial Numbers",
    reset_password: "Reset Password",
    organization: "Organization",
    email_notification: "Email Notification",
    alerts: "Alerts",
    create_an_account: "Create An Account",
    filter: "Filter",
    legal_data_protection: "Legal Data Protection",
    page_not_found_message: "Expecting something here? If so, please contact your site administrator.",
    invalid_username_or_password: "Invalid username or password",
    delete: "Delete",
    privacy_policy: "Privacy Policy",
    cookie_consent_button_text: "GOT IT!",
    password_uppercase_requirement: "At least 1 uppercase letter",
    email_address: "Email Address",
    cancel: "Cancel",
    internal_server_error_message: "Please wait a few minutes and try that again. If it still doesn’t work, contact your site administrator.",
    return_to_login: "RETURN TO LOGIN",
    client_error_generic_message_for_request: "An unexpected error occurred.  Please look over your inputs and try again.",
    logout: "Logout",
    current_password: "Current Password",
    organizations: "Organizations",
    time_zone: "Time Zone",
    general: "General",
    forgot_password: "FORGOT PASSWORD?",
    publish: "Publish",
    text_notification: "Text Notification",
    create_organization: "Create Organization",
    phone_error_message: "Please provide a valid phone number",
    passwords_do_not_match_error: "Confirm Password and New Password do not match",
    password_strength_tooltip: "Passwords must have at least 8\r\ncharacters, 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character",
    date_time: "Date/Time",
    create_account: "Create Account",
    status: "Status",
    search: "Search",
    sign_in: "Sign In",
    password_reset_email_sent: "You’ll receive an email soon with instructions on how to reset your password.",
    are_you_sure: "Are You Sure?",
    special_characters_are_not_allowed: "Special characters are not allowed",
    password: "Password",
    caution: "Caution",
    contact_us_validation_failed: "Please fill in all fields to continue.",
    confirm_new_password: "Confirm New Password",
    password_lowercase_requirement: "At least 1 lowercase letter",
    password_special_character_requirement: "At least 1 special character",
    please_look_over_your_inputs_and_try_again: "Please look over your inputs and try again",
    reset_your_password: "Reset Your Password",
    reactivate: "Reactivate",
    it_looks_like_your_account_hasnt_been_given_any_permissions_yet: "It looks like your account hasn't been given any permissions yet.",
    roles_and_permissions: "Roles & Permissions",
    your_organization_is_disabled: "Your organization is disabled.  Please contact your organization admin or GenOSys sales representative for assistance.",
    open_events: "Open Events",
    change_password: "Change Password",
    na: "N/A",
    ok: "Ok",
    of: "of",
    kato_engineering_inc: "Kato Engineering Inc.",
    white_paper_validation_failed: "Please enter a valid email address.",
    add: "Add",
    email: "Email",
    admin: "Admin",
    alarm: "Alarm",
    login: "Login",
    level: "Level",
    users: "Users",
    units: "Units",
    teams: "Teams",
    roles: "Roles",
    zones: "Zones",
    there_was_an_error_processing_your_request_please_try_again: "There was an error processing your request.  Please try again in a minute.",
    select_all: "Select All",
    last_name: "Last Name",
    add_a_row: "Add A Row",
    critical: "Critical",
    partially_connected: "Partially Connected",
    view_event_history: "View Event History",
    not_reporting: "Not Reporting",
    create_zone: "Create Zone",
    contact_us_failed: "Your message was not sent. Please try again later.",
    internal_server_error: "We’re sorry, but it looks like something went wrong",
    please_contact_your_organization_administrator: "Please contact your organization administrator.",
    cookie_consent_link_text: "Learn more",
    last_updated: "Last Updated",
    version: "Version",
    page_not_found: "The page you're looking for can’t be found",
    first_name: "First Name",
    my_account: "My Account",
    device_management: "Device Management",
    connectivity: "Connectivity",
    password_length_requirement: "At least 8 characters",
    reports: "Reports",
    unauthorized: "Unauthorized",
    message: "Message",
    organization_disabled: "Organization Disabled",
    alert_level: "Alert Level",
    description: "Description",
    new_password: "New Password",
    terms_and_conditions_of_use: "Terms & Conditions of Use",
    all_rights_reserved: "All rights reserved.",
    cookie_consent_message: "This website uses cookies to ensure you get the best experience on our website.",
    remember_me: "Remember me",
    passwords_must_have: "Passwords must have:",
    deactivate: "Deactivate",
    confirm_password: "Confirm Password",
    alerts_configuration: "Alerts Configuration",
    location: "Location",
    view_entire_list: "View Entire List",
    reset_password_instructions: "Enter your email below, and we’ll send you instructions on how to reset your password.",
    data: "data",
    mlai: "Machine Learning",
    copy: "Copy",
    none: "None",
    name: "Name",
    unit: "Unit",
    home: "Home",
    type: "Type",
    time: "Time",
    role: "Role",
    send: "Send",
    save: "Save",
    zone: "Zone",
    success: "Success!",
    all: "All",
};
