import { get, writable } from "svelte/store";
export class ReadablePromise {
    constructor(initialValue) {
        this.InnerStore = writable(initialValue);
    }
    Set(p) {
        this.InnerStore.set(p);
    }
    Update(updater) {
        this.InnerStore.update(updater);
    }
    SetValue(v) {
        this.Set(Promise.resolve(v));
    }
    UpdateValue(updater) {
        return new Promise((resolve) => {
            this.Update((p) => {
                let result = p.then((v) => {
                    return updater(v);
                });
                result.then(() => resolve());
                return result;
            });
        });
    }
    subscribe(run, invalidate) {
        return this.InnerStore.subscribe(run, invalidate);
    }
    then(onfulfilled, onrejected) {
        return get(this.InnerStore).then(onfulfilled, onrejected);
    }
    catch(onrejected) {
        return get(this.InnerStore).catch(onrejected);
    }
    finally(onfinally) {
        return get(this.InnerStore).finally(onfinally);
    }
}
