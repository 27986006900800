<script lang="ts">
  import type Event from "api/event/Event";
  import Card from "components/Card.svelte";
  import ContentBox from "components/ContentBox.svelte";
  import Icon from "components/Icon.svelte";
  import Select from "components/Select.svelte";
  import SubTitleBox from "components/SubTitleBox.svelte";
  import TitleBox from "components/TitleBox.svelte";
  import { Translate } from "localization/i18n";
  import { ApiClient } from "setup";
  import { _ } from "svelte-i18n";
  import { ToReadableDateTime } from "utils/date";
  import params from "utils/params";
  import { ErrorToast, Toast } from "utils/toast";

  export let event: Event;

  async function setStatus(status: string): Promise<void> {
    let oldStatus = event.Status;
    try {
      event.Status = status;
      await ApiClient.Event.Update($params.orgID, event);
      Toast(Translate("saved"));
    } catch (err) {
      event.Status = oldStatus;
      ErrorToast(err);
    }
  }
</script>

<style lang="scss">
  .level {
    vertical-align: middle;
  }

  span.status-selector {
    float: right;
    margin: 12px 0 12px 0;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }
</style>

<Card>
  <TitleBox>
    <h5>
      <span class="level">
        {#if event.Level == 'Critical'}
          <Icon color="red" float="right" size="3rem">error</Icon>
        {:else if event.Level == 'Alarm'}
          <Icon color="orange" float="right" size="3rem">error_outline</Icon>
        {:else}
          <Icon color="green" float="right" size="3ren">check_circle</Icon>
        {/if}
      </span>
      {event.Title}
    </h5>
  </TitleBox>
  <SubTitleBox>{event.Message}</SubTitleBox>
  <ContentBox>
    <table>
      <tbody>
        <tr>
          <td>{$_('status')}:</td>
          <td>
            <Select value={event.Status} on:change={(e) => setStatus(e.detail)}>
              <option value="Open">{$_('open')}</option>
              <option value="WorkInProgress">{$_('work_in_progress')}</option>
              <option value="Resolved">{$_('resolved')}</option>
            </Select>
          </td>
        </tr>
        <tr>
          <td>{$_('start')}</td>
          <td>{ToReadableDateTime(event.StartTime)}</td>
        </tr>
        <tr>
          <td>{$_('end')}</td>
          <td>{ToReadableDateTime(event.EndTime)}</td>
        </tr>
        <tr>
          <td>{$_('zone')}</td>
          <td>{event.ZoneName}</td>
        </tr>
        <tr>
          <td>{$_('unit')}</td>
          <td>{event.UnitName}</td>
        </tr>
      </tbody>
    </table>
  </ContentBox>
</Card>
