export default {
    "eastern_standard_time": "(UTC-06:00) Heure de l'Est (États-Unis et Canada)",
    "argentina_standard_time": "(UTC-03:00) Ville de Buenos Aires",
    "fle_standard_time": "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    "bougainville_standard_time": "(UTC+11:00) Bougainville",
    "jordan_standard_time": "(UTC+02:00) Amman",
    "myanmar_standard_time": "(UTC+06:30) Yangon (Rangoon)",
    "n_central_asia_standard_time": "(UTC+07:00) Novosibirsk",
    "gtb_standard_time": "(UTC+02:00) Athènes, Bucarest",
    "yakutsk_standard_time": "(UTC+09:00) Yakoutsk",
    "hawaiian_standard_time": "(UTC-10:00) Hawaï",
    "namibia_standard_time": "(UTC+01:00) Windhoek",
    "new_zealand_standard_time": "(UTC+12:00) Auckland, Wellington",
    "georgian_standard_time": "(UTC+04:00) Tbilisi",
    "central_european_standard_time": "(UTC+01:00) Sarajevo, Skopje, Varsovie, Zagreb",
    "cape_verde_standard_time": "(UTC-01:00) Cap-Vert",
    "omsk_standard_time": "(UTC+06:00) Omsk",
    "line_islands_standard_time": "(UTC+14:00) Île Kiritimati",
    "greenwich_standard_time": "(UTC+00:00) Monrovia, Reykjavik",
    "nepal_standard_time": "(UTC+05:45) Katmandou",
    "aus_eastern_standard_time": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "russia_time_zone3": "(UTC+04:00) Izhevsk, Samara",
    "north_asia_standard_time": "(UTC+07:00) Krasnoïarsk",
    "pacific_standard_time_mexico": "(UTC-08:00) Basse Californie",
    "us_mountain_standard_time": "(UTC-07:00) Arizona",
    "north_asia_east_standard_time": "(UTC+08:00) Irkoutsk",
    "pakistan_standard_time": "(UTC+05:00) Islamabad, Karachi",
    "central_standard_time": "(UTC-06:00) Heure du Centre (États-Unis et Canada)",
    "mid_atlantic_standard_time": "(UTC-02:00) Mid-Atlantic - Ancien",
    "west_bank_standard_time": "(UTC+02:00) Gaza, Hébron",
    "pacific_standard_time": "(UTC-06:00) Heure du Pacifique (États-Unis et Canada)",
    "samoa_standard_time": "(UTC+13:00) Samoa",
    "libya_standard_time": "(UTC+02:00) Tripoli",
    "morocco_standard_time": "(UTC+00:00) Casablanca",
    "west_pacific_standard_time": "(UTC+10:00) Guam, Port Moresby",
    "arabic_standard_time": "(UTC+03:00) Bagdad",
    "w_mongolia_standard_time": "(UTC+07:00) Hovd",
    "caucasus_standard_time": "(UTC+04:00) Yerevan",
    "arab_standard_time": "(UTC+03:00) Koweït, Riyad",
    "chatham_islands_standard_time": "(UTC+12:45) Îles Chatham",
    "easter_island_standard_time": "(UTC-06:00) Île de Pâques",
    "haiti_standard_time": "(UTC-05:00) Haïti",
    "alaskan_standard_time": "(UTC-09:00) Alaska",
    "korea_standard_time": "(UTC+09:00) Séoul",
    "astrakhan_standard_time": "(UTC+04:00) Astrakhan, Ulyanovsk",
    "marquesas_standard_time": "(UTC-09:30) Marquises",
    "tocantins_standard_time": "(UTC-03:00) Araguaina",
    "vladivostok_standard_time": "(UTC+10:00) Vladivostok",
    "central_brazilian_standard_time": "(UTC-04:00) Cuiaba",
    "magadan_standard_time": "(UTC+11:00) Magadan",
    "tokyo_standard_time": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "afghanistan_standard_time": "(UTC+04:30) Kaboul",
    "greenland_standard_time": "(UTC-03:00) Groenland",
    "bangladesh_standard_time": "(UTC+06:00) Dacca",
    "central_europe_standard_time": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "ekaterinburg_standard_time": "(UTC+05:00) Ekaterinburg",
    "fiji_standard_time": "(UTC+12:00) Fidji",
    "tomsk_standard_time": "(UTC+07:00) Tomsk",
    "e_africa_standard_time": "(UTC+03:00) Nairobi",
    "e_south_america_standard_time": "(UTC-03:00) Brasilia",
    "tonga_standard_time": "(UTC+13:00) Nuku'alofa",
    "india_standard_time": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "saratov_standard_time": "(UTC+04:00) Saratov",
    "norfolk_standard_time": "(UTC+11:00) Île Norfolk",
    "montevideo_standard_time": "(UTC-03:00) Montevideo",
    "utc": "(UTC) Temps universel coordonné",
    "azerbaijan_standard_time": "(UTC+04:00) Astrakhan",
    "u_t_c_12": "(UTC+12:00) Temps universel coordonné+12",
    "u_t_c_13": "(UTC+13:00) Temps universel coordonné+13",
    "u_t_c_11": "(UTC-11:00) Temps universel coordonné-11",
    "u_t_c_02": "(UTC-02:00) Temps universel coordonné-02",
    "u_t_c_09": "(UTC-09:00) Temps universel coordonné-09",
    "u_t_c_08": "(UTC-08:00) Temps universel coordonné-08",
    "singapore_standard_time": "(UTC+08:00) Kuala Lumpur, Singapour",
    "turks_and_caicos_standard_time": "(UTC-04:00) Îles Turques et Caïques",
    "russia_time_zone10": "(UTC+11:00) Chokurdakh",
    "russia_time_zone11": "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
    "south_africa_standard_time": "(UTC+02:00) Harare, Pretoria",
    "pacific_sa_standard_time": "(UTC-04:00) Santiago",
    "sri_lanka_standard_time": "(UTC+05:30) Sri Jayawardenepura",
    "central_pacific_standard_time": "(UTC+11:00) Îles Salomon, Nouvelle Calédonie",
    "mountain_standard_time": "(UTC-07:00) Heure des Rocheuses (États-Unis et Canada)",
    "syria_standard_time": "(UTC+02:00) Damas",
    "magallanes_standard_time": "(UTC-03:00) Punta Arenas",
    "middle_east_standard_time": "(UTC+02:00) Beyrouth",
    "venezuela_standard_time": "(UTC-04:00) Caracas",
    "sa_eastern_standard_time": "(UTC-03:00) Cayenne, Fortaleza",
    "west_asia_standard_time": "(UTC+05:00) Achgabat, Tachkent",
    "central_america_standard_time": "(UTC-06:00) Amérique centrale",
    "ulaanbaatar_standard_time": "(UTC+08:00) Oulan-Bator",
    "w_europe_standard_time": "(UTC+01:00) Amsterdam, Berlin, Berne, Rome, Stockholm, Vienne",
    "azores_standard_time": "(UTC-01:00) Açores",
    "w_australia_standard_time": "(UTC+08:00) Perth",
    "romance_standard_time": "(UTC+01:00) Bruxelles, Copenhague, Madrid, Paris",
    "gmt_standard_time": "(UTC+00:00) Dublin, Édimbourg, Lisbonne, Londres",
    "canada_central_standard_time": "(UTC-06:00) Saskatchewan",
    "lord_howe_standard_time": "(UTC+10:30) Île Lord Howe",
    "tasmania_standard_time": "(UTC+10:00) Hobart",
    "mountain_standard_time_mexico": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "saint_pierre_standard_time": "(UTC-03:00) Saint-Pierre et Miquelon",
    "aleutian_standard_time": "(UTC-10:00) Îles Aléoutiennes",
    "arabian_standard_time": "(UTC+04:00) Abou Dabi, Muscat",
    "aus_central_w_standard_time": "(UTC+08:45) Eucla",
    "turkey_standard_time": "(UTC+03:00) Istanbul",
    "paraguay_standard_time": "(UTC-04:00) Asuncion",
    "china_standard_time": "(UTC+08:00) Pékin, Chongqing, Hong Kong, Urumqi",
    "aus_central_standard_time": "(UTC+09:30) Darwin",
    "kamchatka_standard_time": "(UTC+12:00) Petropavlovsk-Kamchatsky - Ancien",
    "taipei_standard_time": "(UTC+08:00) Taïpei",
    "cen_australia_standard_time": "(UTC+09:30) Adélaïde",
    "newfoundland_standard_time": "(UTC-03:30) Terre-Neuve",
    "kaliningrad_standard_time": "(UTC+02:00) Kaliningrad",
    "north_korea_standard_time": "(UTC+08:30) Pyongyang",
    "us_eastern_standard_time": "(UTC-05:00) Indiana (Est)",
    "sakhalin_standard_time": "(UTC+11:00) Sakhaline",
    "e_europe_standard_time": "(UTC+02:00) Chisinau",
    "eastern_standard_time_mexico": "(UTC-05:00) Chetumal",
    "israel_standard_time": "(UTC+02:00) Jérusalem",
    "cuba_standard_time": "(UTC-03:00) La Havane",
    "belarus_standard_time": "(UTC+03:00) Minsk",
    "russian_standard_time": "(UTC+03:00) Moscou, Saint-Pétersbourg, Volgograd",
    "e_australia_standard_time": "(UTC+10:00) Brisbane",
    "transbaikal_standard_time": "(UTC+09:00) Chita",
    "mauritius_standard_time": "(UTC+04:00) Port Louis",
    "iran_standard_time": "(UTC+03:30) Téhéran",
    "sa_western_standard_time": "(UTC-04:00) Georgetown, La Paz, Manaos, San Juan",
    "central_standard_time_mexico": "(UTC-06:00) Guadalajara, Mexico, Monterrey",
    "sa_pacific_standard_time": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
    "atlantic_standard_time": "(UTC-04:00) Heure de l'Atlantique (Canada)",
    "bahia_standard_time": "(UTC-03:00) Salvador",
    "w_central_africa_standard_time": "(UTC+01:00) Ouest de l'Afrique centrale",
    "dateline_standard_time": "(UTC-12:00) Ligne de changement de date internationale Ouest",
    "altai_standard_time": "(UTC+07:00) Barnaul, Gorno-Altaysk",
    "egypt_standard_time": "(UTC+02:00) Le Caire",
    "se_asia_standard_time": "(UTC+07:00) Bangkok, Hanoï, Djakarta",
    "central_asia_standard_time": "(UTC+06:00) Astana"
};
