import { readable } from "svelte/store";
export default function Interval(period) {
    return readable(0, (set) => {
        const startTime = new Date();
        const interval = setInterval(() => {
            set(new Date().valueOf() - startTime.valueOf());
        }, period);
        return () => {
            clearInterval(interval);
        };
    });
}
