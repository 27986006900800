import RestStore from "state/RestStore";
export default class RoleClient extends RestStore {
    constructor(restClient) {
        super();
        this.Client = restClient;
    }
    get(orgID, roleID) {
        return this.Client.Get(`/orgs/${orgID}/roles/${roleID}`).Expect(200);
    }
    list(orgID) {
        return this.Client.Get(`/orgs/${orgID}/roles`).Expect(200);
    }
    update(orgID, role) {
        return this.Client.Post(`/orgs/${orgID}/roles/${role.NameKey}`, role).Expect(200);
    }
    add(orgID, role) {
        return this.Client.Post(`/orgs/${orgID}/roles`, role).Expect(200);
    }
    delete(orgID, roleID) {
        return this.Client.Delete(`/orgs/${orgID}/roles/${roleID}`).Expect(204);
    }
    keySelector(item) {
        return item.NameKey;
    }
    AddUser(orgID, roleID, userID) {
        return this.Client.Post(`/orgs/${orgID}/roles/${roleID}/users`, { Key: userID }).Expect(204);
    }
    DeleteUser(orgID, roleID, userID) {
        return this.Client.Delete(`/orgs/${orgID}/roles/${roleID}/users/${userID}`).Expect(204);
    }
    GetActivities(orgID) {
        return this.Client.Get(`/orgs/${orgID}/roles/activities`).Expect(200);
    }
}
