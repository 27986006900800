import { __awaiter } from "tslib";
import RestStore from "state/RestStore";
export default class UnitClient extends RestStore {
    constructor(restClient) {
        super();
        this.RestClient = restClient;
    }
    Sensors(orgID, zoneID, unitID) {
        return __awaiter(this, void 0, void 0, function* () {
            let resp = yield this.RestClient.Get(`/orgs/${orgID}/zones/${zoneID}/units/${unitID}/data`).Expect(200);
            return resp.Sensors;
        });
    }
    Status(orgID, zoneID, unitID) {
        return this.RestClient.Get(`/orgs/${orgID}/zones/${zoneID}/units/${unitID}/data`).Expect(200);
    }
    list(orgID, zoneID) {
        return __awaiter(this, void 0, void 0, function* () {
            let resp = yield this.RestClient.Get(`/orgs/${orgID}/zones/${zoneID}/data`).Expect(200);
            return resp.ReportingDevices;
        });
    }
    get(orgID, zoneID, unitID) {
        return this.RestClient.Get(`/orgs/${orgID}/zones/${zoneID}/units/${unitID}`).Expect(200);
    }
    update(orgID, zoneID, unit) {
        return this.RestClient.Put(`/orgs/${orgID}/zones/${zoneID}/units/${unit.Key}`, unit).Expect(204);
    }
    add(orgID, zoneID, unit) {
        return this.RestClient.Post(`orgs/${orgID}/zones/${zoneID}/units`, unit).Expect(200);
    }
    delete(orgID, zoneID, unitID) {
        return this.RestClient.Delete(`/orgs/${orgID}/zones/${zoneID}/units/${unitID}`).Expect(204);
    }
    keySelector(unit) {
        return unit.Key;
    }
}
