import RestStore from "state/RestStore";
export default class ZoneClient extends RestStore {
    constructor(restClient) {
        super();
        this.RestClient = restClient;
    }
    list(orgID) {
        return this.RestClient.Get(`/orgs/${orgID}/zones/data`).Expect(200);
    }
    get(orgID, zoneID) {
        return this.RestClient.Get(`/orgs/${orgID}/zones/${zoneID}/data`).Expect(200);
    }
    add(orgID, zone) {
        return this.RestClient.Post(`/orgs/${orgID}/zones/`, zone).Expect(200);
    }
    update(orgID, zone) {
        return this.RestClient.Put(`orgs/${orgID}/zones/${zone.Key}`, zone).Expect(204);
    }
    delete(orgID, zoneID) {
        return this.RestClient.Delete(`orgs/${orgID}/zones/${zoneID}`).Expect(204);
    }
    keySelector(zone) {
        return zone.Key;
    }
    Status(orgID, zoneID) {
        return this.RestClient.Get(`/orgs/${orgID}/zones/${zoneID}/data/latest`).Expect(200);
    }
}
