export default {
    unable_to_remove_role_from_user: "Impossible de supprimer le rôle de l'utilisateur.  Réessayez dans un moment",
    new_password_does_not_meet_strength_requirements: "Le nouveau mot de passe ne répond pas aux critères de sécurité",
    reset_password_token_expired: "Le jeton de réinitialisation du mot de passe a expiré.  Essayez à nouveau de réinitialiser votre mot de passe.",
    invite: "Inviter",
    user_removed: "Utilisateur supprimé",
    no_users: "Aucun utilisateur",
    role_added: "Rôle ajouté",
    already_in_organization: "Cet utilisateur fait déjà partie de l'organisation.",
    unable_to_add_role_to_user: "Impossible d'ajouter le rôle à l'utilisateur.  Réessayez dans un moment",
    invalid_phone_number: "Numéro de téléphone non valide",
    user_updated: "Utilisateur mis à jour",
    user_delete_confirmation: "Voulez-vous vraiment supprimer cet utilisateur ?",
    registration_token_expired_please_request_a_new_invitation: "Le jeton d'inscription a expiré. Demandez une nouvelle invitation",
    last_name: "Nom",
    invite_a_user: "Inviter un utilisateur",
    invitation_sent: "Invitation envoyée",
    first_name: "Prénom",
    problem_inviting_a_user: "Un problème est survenu lors de l'invitation de l'utilisateur.  Réessayez plus tard.",
    no_teams_available_for_user: "Aucune équipe disponible pour l'utilisateur",
    role_removed: "Rôle supprimé",
    could_not_update_password_at_this_time_please_try_again_later: "Impossible actuellement de mettre à jour le mot de passe.  Réessayez plus tard.",
    password_does_not_meet_strength_requirements: "Le mot de passe ne répond pas aux critères de sécurité",
    the_entered_current_password_is_incorrect_please_try_again: "Le mot de passe actuel que vous avez saisi est incorrect.",
    no_roles_available_for_user: "Aucun rôle disponible pour l'utilisateur",
    user_could_not_be_removed: "Impossible de supprimer l'utilisateur.  Veuillez réessayer dans un moment.",
};
