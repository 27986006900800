export default {
    notification_update_error_message: "Impossible de mettre à jour la notification",
    sms_sent: "SMS envoyé",
    test_sms: "SMS",
    email_subject: "Objet de l’E-mail",
    notification: "Notification",
    notification_update: "Notification mise à jour",
    sms_failed: "SMS a échoué",
    message: "Message",
};
