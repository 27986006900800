<script lang="typescript">
  import Card from "components/Card.svelte";
  import type SensorInfo from "api/unit/SensorInfo";
  import M from "materialize-css";
  import { onMount } from "svelte";
  import Collapsable from "components/Collapsable.svelte";
  import CollapsableItem from "components/CollapsableItem.svelte";
  import { _ } from "svelte-i18n";
  import { goto } from "@sveltech/routify";
  import { ApiClient } from "setup";
  import type SensorValue from "api/unit/SensorValue";
  import { Translate } from "localization/i18n";
  import ContentBox from "components/ContentBox.svelte";
  import TitleBox from "components/TitleBox.svelte";
  import Icon from "components/Icon.svelte";
  import params from "utils/params";
  import { derived, Readable } from "svelte/store";
  import type { Configuration } from "api/graphs/GraphConfiguration";
  import "utils/array"

  export let system: string;
  export let sensors: SensorInfo[];
  export let values: SensorValue[];

  let configurations: Readable<Configuration[]> = derived(
    params,
    ($params: any, set: (v: Configuration[])=>void) => {
      ApiClient.Graph.List(
        $params.orgID,
        $params.zoneID,
        $params.unitID
      ).then(set);
    },
    []
  );

  let collapsible: HTMLElement;

  let cardWidth: number;

  function sleep(time) {
    return new Promise((res) => {
      setTimeout(res, time);
    });
  }

  onMount(() => {
    M.Collapsible.init(collapsible);
  });

  // @ts-ignore
  $: getValue = (name: string): string => {
    if (values) {
      let value = values.firstOrUndefined((v) => v.Name == name);
      if (value) {
        return parseFloat(value.Value).toFixed(3);
      }
    }
    return Translate("na");
  };
</script>

<style lang="scss">
  td {
    padding: 5px 5px;
  }

  tr {
    border-bottom: none;
  }

  .chart-spinner {
    margin-top: 110px;
  }
</style>

<Card>
  <TitleBox>
    <h5 bind:clientWidth={cardWidth}>{system}</h5>
  </TitleBox>
  <ContentBox>
    <Collapsable>
      {#each sensors.map((s) => s.Category).unique() as category}
        <CollapsableItem>
          <div slot="header" style="width: 100%">
            {#if $configurations.some(c => c.Sensors.some(availableSensor => sensors.some((s) => s.Category == category && s.Name == availableSensor)))}
              <span
                on:click|stopPropagation={() => {
                  let config = $configurations.first(c => c.Sensors.some(availableSensor => sensors.some((s) => s.Category == category && s.Name == availableSensor)))
                  $goto('./', { graph: config.Key, aggregation: config.Aggregations[0] });
                }}
                class="right waves-effect">
                <Icon>insert_chart</Icon>
              </span>
            {/if}
            <h6>{category}</h6>
          </div>

          <table>
            {#each sensors.filter((s) => s.Category == category) as sensor}
              <tr>
                <td>{sensor.ShownName}:</td>
                <td>{getValue(sensor.Name)} {sensor.Unit}</td>
                {#if $configurations.some(c => c.Sensors.some(s => s == sensor.Name))}
                  <td
                    on:click|stopPropagation={() => {
                      let config = $configurations.first(c => c.Sensors.some(s => s == sensor.Name))
                      $goto('./', { graph: config.Key, aggregation: config.Aggregations[0] });
                    }}
                    class="right waves-effect">
                    <Icon>insert_chart</Icon>
                  </td>
                {/if}
              </tr>
            {/each}
          </table>
        </CollapsableItem>
      {/each}
    </Collapsable>
  </ContentBox>
</Card>
