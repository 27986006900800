import { Readable } from "../state/Readable";
export class FormValidator extends Readable {
    constructor() {
        super();
        this.Fields = [];
    }
    Is(...conditions) {
        let validator = new FieldValidator();
        this.Fields.push(validator);
        validator.subscribe((v) => this.SignalUpdate());
        return validator.Is(...conditions);
    }
    MarkClean() {
        for (let field of this.Fields) {
            field.Dirty = false;
        }
        this.SignalUpdate();
    }
    IsDirty() {
        for (let field of this.Fields) {
            if (field.Dirty) {
                return true;
            }
        }
        return false;
    }
    IsValid() {
        for (let field of this.Fields) {
            if (!field.Valid) {
                return false;
            }
        }
        return true;
    }
}
export class FieldValidator extends Readable {
    constructor() {
        super();
        this.Conditions = [];
        this.Dirty = false;
        this.Messages = [];
    }
    get Valid() {
        if (this.Messages && this.Messages.length > 0) {
            return false;
        }
        return true;
    }
    Is(...conditions) {
        this.Conditions.push(...conditions);
        return this;
    }
    Update(value) {
        this.Dirty = true;
        this.Messages = [];
        for (let c of this.Conditions) {
            let m = c(value);
            if (m) {
                this.Messages.push(m);
            }
        }
        this.SignalUpdate();
    }
}
export function Nop(_) {
    return "";
}
export function NotEmpty(message) {
    return (value) => {
        if (!value) {
            return message;
        }
        return "";
    };
}
export function ShorterThan(length, message) {
    return (value) => {
        if (value && value.length > length) {
            return message;
        }
        return "";
    };
}
export function Regexp(regexp, message) {
    return (value) => {
        if (!value.match(regexp)) {
            return message;
        }
        return "";
    };
}
