<script lang="typescript">
  import M from "materialize-css";
  import { onMount } from "svelte";
  import { FieldValidator } from "../utils/validate";
  import Flag from "Flag.svelte";
  import countries from "./Countries";
  import { _ } from "svelte-i18n";
  import { AsYouType, parsePhoneNumber } from "libphonenumber-js/max";
  import type { CountryCode } from "libphonenumber-js";
  import { Translate } from "localization/i18n";

  let trigger: HTMLElement;
  let dropdownControl: any;
  let countrySearch: string = "";
  let countrySearchInput: HTMLInputElement;

  export let validation: FieldValidator<string> = new FieldValidator<string>();
  export let id: string = "_" + Math.random().toString(36).substr(2, 9);
  export let value: string = "";

  let localValue: string = value ? parsePhoneNumber(value).format("NATIONAL") : "";
  let selectedCountry: CountryCode = guessCountry();

  $: {
    $validation.Update(localValue);
    let parser = new AsYouType(selectedCountry);
    localValue = parser.input(localValue);
    if (parser.getNumber() && parser.getNumber().isValid()) {
      value = parser.getNumber().format("INTERNATIONAL");
    } else {
      value = "";
    }
  }

  validation = validation.Is((value: string) => {
    try {
      if (!value) {
        return "";
      }

      if (parsePhoneNumber(value, selectedCountry).isValid()) {
        return "";
      }
    } catch (e) {
      return Translate("phone_error_message");
    }
    return Translate("phone_error_message");
  });

  onMount(() => {
    M.updateTextFields();

    dropdownControl = M.Dropdown.init(trigger, {
      coverTrigger: true,
      alignment: "left",
      autoTrigger: false,
      closeOnClick: false,
      constrainWidth: false,
      container: undefined,
      onOpenEnd: () => {
        countrySearchInput.focus();
      },
      onCloseEnd: () => {
        countrySearch = "";
      },
    });
  });

  function guessCountry(): CountryCode {
    if (value) {
      return parsePhoneNumber(value).country; //Assuming the already set number is valid. Hopefully that doesn't bite me in the ass.
    }

    let code = window.navigator.language.slice(-2);
    if (code in countries) {
      return code as CountryCode;
    }

    return "FR";
  }
</script>

<style lang="scss">
  @import "./theme.scss";
  ul.validation {
    margin: -10px 0px 20px 20px;
    li {
      list-style-type: "- ";
      color: $error-color;
    }
  }

  i.material-icons {
    margin-left: -10px;
    letter-spacing: -10px;
  }

  .dropdown-content {
    max-height: 300px;
  }
</style>

<div class="input-field">
  <span class="prefix dropdown-trigger" bind:this={trigger} data-target="{id}_country_select">
    <Flag country={selectedCountry.toLowerCase()} />
    <i class="material-icons">arrow_drop_down</i>
  </span>
  <ul id="{id}_country_select" class="dropdown-content">
    <li on:click|stopPropagation>
      <span>
        <input
          on:click|stopPropagation
          placeholder={$_('search')}
          bind:this={countrySearchInput}
          bind:value={countrySearch} />
      </span>
    </li>
    {#each Object.keys(countries).filter((c) => countries[c]
          .toLowerCase()
          .includes(
            countrySearch.toLowerCase()
          ) || c.toLowerCase().includes(countrySearch.toLowerCase())) as country}
      <li
        on:click={() => {
          selectedCountry = country;
          dropdownControl.close();
        }}>
        <span>
          <Flag country={country.toLowerCase()} />
          {countries[country]}
        </span>
      </li>
    {/each}
  </ul>
  <input
    {id}
    inputmode="tel"
    class:valid={$validation.Valid && $validation.Dirty}
    class:invalid={!$validation.Valid && $validation.Dirty}
    type="text"
    bind:value={localValue} />
  <label for={id}>
    <slot />
  </label>
  <ul class="validation">
    {#each $validation.Messages as message}
      <li>{message}</li>
    {/each}
  </ul>
</div>
