export default {
    events_delete: "Supprimer des événements",
    users_remove_from_organization: "Supprimer des utilisateurs de l'organisation",
    units_device_configuration_read: "Lire la configuration de l'appareil",
    units_device_configuration_push: "Déployer la configuration de l'appareil",
    users_create: "Inviter des utilisateurs",
    events_configuration_add: "Ajouter configurations des Evènements",
    organization_suspend: "Suspendre l'organisation",
    teams_read: "Lire les équipes",
    there_was_a_problem_updating_the_role: "Un problème est survenu lors de la mise à jour du rôle",
    delete_role: "Supprimer le rôle",
    organization_update_another_org: "Mettre à jour les détails d'une organisation à partir d'une autre organisation",
    notification_config_read: "Configuration de notification de lecture",
    events_configuration_read: "Lire les configurations d'événement",
    roles_read: "Lire les rôles",
    organization_read_all: "Lire toutes les organisations",
    events_read: "Lire les événements",
    new_role: "Nouveau rôle",
    role_deleted: "Rôle supprimé",
    graph_read_csv: "Télécharger les données de graphique",
    units_download_key: "Clé de téléchargement pour les unités",
    events_configuration_delete: "Supprimer la configuration d'événement",
    are_you_sure_you_want_to_delete_this_role: "Voulez-vous vraiment supprimer ce rôle ?",
    roles_delete: "Supprimer les rôles",
    events_resolve: "Résoudre des événements",
    notification_test_sms: "Test SMS",
    organization_read_from_another_org: "Lire les informations sur l'organisation à partir d'une autre organisation",
    no_roles_available: "Aucun rôle disponible",
    teams_manage_users: "Gérer les équipes pour les utilisateurs",
    mlai_config_delete: "Supprimer les configurations ML / AI",
    supported_devices_delete: "Supprimer des appareils pris en charge",
    events_comments_add: "Ajouter des commentaires d'événement",
    there_was_a_problem_creating_the_role: "Un problème est survenu lors de la création du rôle",
    events_configuration_update: "Mettre à jour les configurations d'événement",
    units_delete: "Supprimer les unités",
    units_create: "Créer des unités",
    mlai_config_create: "Créer les Configurations ML / AI",
    roles_manage_users: "Gérer les rôles pour les utilisateurs",
    notification_config_create: "Créer une configuration de notification",
    permissions: "Autorisations",
    organization_update: "Mettre à jour les informations sur l'organisation",
    organization_read: "Lire les informations sur l'organisation",
    notification_config_delete: "Supprimer la configuration de notification",
    supported_devices_add_edit: "Ajouter/modifier des appareils pris en charge",
    units_report_config_read: "Lire la configuration des rapports",
    report_permissions: "Autorisations sur les rapports",
    zones_delete: "Supprimer les zones",
    roles_add: "Ajouter des rôles",
    roles_update: "Mettre à jour les rôles",
    alarm_configuration_permissions: "Autorisations de configuration d'alarme",
    events_update_status: "Mettre à jour l'état de l'événement",
    zones_create: "Créer des zones",
    event_configuration_permissions: "Autorisations de configuration d'événement",
    units_report_config_update: "Mettre à jour la configuration des rapports",
    user_permissions: "Autorisations de l'utilisateur",
    organization_create: "Créer une organisation",
    zones_publish: "Publier des zones",
    units_update_meta_data: "Mettre à jour les données d'unité",
    there_was_an_error_deleting_this_role: "Une erreur s'est produite lors de la suppression de ce rôle",
    mlai_config_update: "Mettre à jour les configurations ML / AI",
    zones_update_meta_data: "Mettre à jour les données de zone",
    remove_users_from_teams: "Supprimer des utilisateurs des équipes",
    notification_config_update: "Mise à jour de la configuration de la notification",
    organization_delete: "Supprimer l'organisation",
    teams_create: "Créer des équipes",
    reports_read: "Lire les rapports",
    zones_read: "Lire les zones",
    role_created: "Rôle créé",
    zone_permissions: "Autorisations de la zone",
    notification_permissions: "Autorisations de notification",
    role_permissions: "Autorisations du rôle",
    units_gateway_information_read: "Lire les informations de la passerelle",
    teams_delete: "Supprimer les équipes",
    supported_device_permissions: "Autorisations des appareils pris en charge",
    note_any_changes_will_take_effect_when_the_user_next_logs_in: "NOTE : Toute modification entrera en vigueur lors de la prochaine connexion de l'utilisateur",
    zones_read_all_in_org: "Lire toutes les zones de l'organisation (contourne les équipes)",
    unit_permissions: "Autorisations de l'unité",
    graph_permissions: "Autorisations sur les graphiques",
    team_permissions: "Autorisations de l'équipe",
    event_permissions: "Autorisations sur les événements",
    organization_permissions: "Autorisations de l'organisation",
    users_read: "Lire les utilisateurs",
    units_read: "Lire les unités",
    add_roles_to_user: "Ajouter des rôles à l'utilisateur",
    graph_read: "Lire les graphiques",
    mlai_config_read: "Lire les configurations ML / AI",
    teams_update: "Mettre à jour les équipes",
    units_device_configuration_update: "Mettre à jour la configuration de l'appareil",
    supported_devices_read: "Lire des appareils pris en charge",
    role_updated: "Rôle mis à jour",
    units_report_run: "Exécuter un rapport à la demande",
    add_a_role: "Ajouter un rôle",
};
