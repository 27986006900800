import { __awaiter } from "tslib";
import { Translate } from "../../localization/i18n";
import AuthState from "../../state/Authentication";
import RestStore from "state/RestStore";
export default class UserClient extends RestStore {
    constructor(restClient) {
        super();
        this.RestClient = restClient;
    }
    Self(orgID) {
        return this.RestClient.Get(`/orgs/${orgID}/users/self`).Expect(200);
    }
    list(orgID) {
        return this.RestClient.Get(`/orgs/${orgID}/users`).Expect(200);
    }
    add(orgID, user) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.RestClient.Post(`/orgs/${orgID}/users`, user).Expect(204);
            let users = yield this.List(orgID);
            for (let user of users) {
                if (user.Email == user.Email) {
                    return user;
                }
            }
            return null;
        });
    }
    update(orgID, user) {
        return this.RestClient.Put(`/orgs/${orgID}/users/${user.Key}`, user).Expect(204, {
            400: Translate,
        });
    }
    delete(orgID, userID) {
        return this.RestClient.Delete(`/orgs/${orgID}/users/${userID}`).Expect(204);
    }
    UpdateSelf(orgID, user) {
        let response = this.RestClient.Put(`/orgs/${orgID}/users/self`, user).Expect(204, {
            400: Translate,
        });
        AuthState.update((value) => {
            value.Timeout = new Date().toUTCString();
            return value;
        });
        return response;
    }
    get(orgID, userID) {
        return this.RestClient.Get(`/orgs/${orgID}/users/${userID}`).Expect(200);
    }
    keySelector(user) {
        return user.Key;
    }
}
