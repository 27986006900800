export default {
    are_you_sure_you_want_to_reactivate: "Voulez-vous vraiment réactiver l'organisation suivante ?",
    enable: "Activer",
    are_you_sure_you_want_to_delete: "Voulez-vous vraiment supprimer l'organisation suivante ?",
    create_organization: "Créer une organisation",
    standard_header: "Organisation",
    organization_created_successfully: "Création de l'organisation réussie",
    organization_deactivated: "Organisation désactivée",
    org_name_too_long: "Le nom de l'organisation ne peut pas dépasser {1} caractères",
    add: "Ajouter",
    users: "Utilisateurs",
    zones: "Zones",
    organization_deleted: "Organisation supprimée",
    updated_successfully: "Mise à jour de l'organisation réussie",
    are_you_sure_you_want_to_deactivate: "Voulez-vous vraiment désactiver l'organisation suivante ?",
    organization_reactivated: "Organisation réactivée",
};
