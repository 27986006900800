import { __awaiter } from "tslib";
import RestStore from "state/RestStore";
export default class TeamClient extends RestStore {
    constructor(client) {
        super();
        this.Client = client;
    }
    get(orgID, teamID) {
        return this.Client.Get(`/orgs/${orgID}/teams/${teamID}`).Expect(200);
    }
    list(orgID) {
        return this.Client.Get(`/orgs/${orgID}/teams`).Expect(200);
    }
    add(orgID, team) {
        return __awaiter(this, void 0, void 0, function* () {
            let zones = team.Zones;
            team = yield this.Client.Post(`/orgs/${orgID}/teams`, {
                Name: team.DisplayName,
                Description: team.Description,
                Emails: [],
            }).Expect(200);
            team.Zones = zones;
            yield this.update(orgID, team);
            return team;
        });
    }
    update(orgID, team) {
        return this.Client.Put(`/orgs/${orgID}/teams/${team.NameKey}`, team).Expect(200);
    }
    delete(orgID, teamID) {
        return this.Client.Delete(`/orgs/${orgID}/teams/${teamID}`).Expect(204);
    }
    keySelector(team) {
        return team.NameKey;
    }
}
