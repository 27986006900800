<script lang="ts">
  import type Unit from "api/unit/Unit";
  import type Zone from "api/zone/Zone";
  import Dropdown from "components/Dropdown.svelte";
  import Button from "components/Button.svelte";
  import FilterList from "components/FilterList.svelte";
  import Icon from "components/Icon.svelte";
  import Select from "components/Select.svelte";
  import { ApiClient } from "setup";
  import { _ } from "svelte-i18n";
  import { fade } from "svelte/transition";
  import { ToReadableDateTime } from "utils/date";
  import params from "utils/params";
  import DropdownItem from "components/DropdownItem.svelte";
  import { ReportStatus } from "api/report/Report";

  if ($params.startTime == undefined) {
    $params.startTime = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString();
  }

  let allReports = ApiClient.Report.List($params.orgID);

  //Avoid the reload since we have to get all reports. Once query
  //support is added to the reports endpoitn fix this up.
  //@ts-ignore
  $: reports = allReports.then((rs) => {
    rs.sort((a, b) => {
      if (new Date(a.StartDate) < new Date(b.StartDate)) {
        return 1;
      }
      return -1;
    });
    return rs
      .filter((r) => !$params.zoneID || r.ZoneId == $params.zoneID)
      .filter((r) => !$params.unitID || r.UnitId == $params.unitID)
      .filter((r) => !$params.startTime || new Date(r.StartDate) > new Date($params.startTime));
  });
  let zones: Promise<Zone[]> = ApiClient.Zone.List($params.orgID);
  // @ts-ignore
  $: units = (async () => {
    if ($params.zoneID) {
      return await ApiClient.Unit.List($params.orgID, $params.zoneID);
    }
    let _zones = await zones;
    let _units = await Promise.all(_zones.map((z) => ApiClient.Unit.List($params.orgID, z.Key)));
    return _units.mapMany<Unit>();
  })();

  let filterModalOpen: boolean = false;
</script>

<style lang="scss">
  h5 {
    opacity: 0.6;
  }

  a {
    color: rgba(0, 0, 0, 0.87);
  }

  .status {
    position: absolute;
    width: 42px;
    height: 62px;
    overflow: hidden;
    left: 15px;
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
  }
</style>

<h5>{$_('reports')}</h5>
<FilterList items={reports} let:item={report}>
  <div class="row" slot="controls">
    <div class="col s12 m6 l4">
      {#await zones then _zones}
        <Select bind:value={$params.zoneID} label={$_('zone')}>
          <option value={undefined}>{$_('all')}</option>
          {#each _zones as zone}
            <option value={zone.Key}>{zone.Name}</option>
          {/each}
        </Select>
      {/await}
    </div>
    <div class="col s12 m6 l4">
      {#await units then _units}
        <Select bind:value={$params.unitID} label={$_('unit')}>
          <option value={undefined}>{$_('all')}</option>
          {#each _units as unit}
            <option value={unit.Key}>{unit.Name}</option>
          {/each}
        </Select>
      {/await}
    </div>
    <div class="col s12 m6 l4">
      <Select bind:value={$params.startTime} label={$_('time')}>
        <option value={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()}>
          {$_('last30_days')}
        </option>
        <option value={new Date(new Date().setDate(new Date().getDate() - 120)).toISOString()}>
          {$_('last120_days')}
        </option>
        <option value={new Date(new Date().setDate(new Date().getDate() - 365)).toISOString()}>
          {$_('last_year')}
        </option>
        <option value={new Date(0).toISOString()}>{$_('all_reports')}</option>
      </Select>
    </div>
  </div>
  <span class="status">
    {#if report.Status == ReportStatus.Failed}
      <Icon color="red" size="3rem">error</Icon>
    {:else if report.Status == ReportStatus.Pending}
      <Icon color="blue" size="3rem">cached</Icon>
    {:else if report.Status == ReportStatus.Running}
      <Icon color="blue" size="3rem">cached</Icon>
    {:else}
      <Icon color="green" size="3rem">check_circle</Icon>
    {/if}
  </span>
  <Dropdown>
    <span slot="label">
      <h5>{report.Title}</h5>
    </span>

    {#if report.Reports && Object.keys(report.Reports).length > 0}
      {#each Object.keys(report.Reports) as key}
        <DropdownItem>
          <a href={report.Reports[key]}>{$_(key)}</a>
        </DropdownItem>
      {/each}
    {/if}
  </Dropdown>
  <p>
    {$_('zone')}: {report.ZoneName} {$_('unit')}: {report.UnitName}
    <br />
    {ToReadableDateTime(report.StartDate)}
    {#if report.EndDate}- {ToReadableDateTime(report.EndDate)}{/if}
  </p>
  <div in:fade class="center" slot="no-items">{$_('no_reports')}</div>
</FilterList>
