export default {
    notification_update_error_message: "Unable to update notification",
    sms_sent: "SMS Sent",
    test_sms: "SMS",
    email_subject: "E-mail Subject",
    notification: "Notification",
    notification_update: "Notification Updated",
    sms_failed: "SMS Failed",
    message: "Message",
};
