export const KATO_ONLY_OFFSET = 10000;
export var Activity;
(function (Activity) {
    Activity[Activity["RolesRead"] = 0] = "RolesRead";
    Activity[Activity["RolesUpdate"] = 1] = "RolesUpdate";
    Activity[Activity["RolesAdd"] = 2] = "RolesAdd";
    Activity[Activity["RolesDelete"] = 5] = "RolesDelete";
    Activity[Activity["RolesManageUsers"] = 6] = "RolesManageUsers";
    Activity[Activity["OrganizationUpdate"] = 100] = "OrganizationUpdate";
    Activity[Activity["OrganizationRead"] = 101] = "OrganizationRead";
    Activity[Activity["OrganizationSuspend"] = KATO_ONLY_OFFSET + 103] = "OrganizationSuspend";
    Activity[Activity["OrganizationCreate"] = KATO_ONLY_OFFSET + 104] = "OrganizationCreate";
    Activity[Activity["OrganizationDelete"] = KATO_ONLY_OFFSET + 105] = "OrganizationDelete";
    Activity[Activity["OrganizationReadAll"] = KATO_ONLY_OFFSET + 106] = "OrganizationReadAll";
    Activity[Activity["OrganizationReadFromAnotherOrg"] = KATO_ONLY_OFFSET + 107] = "OrganizationReadFromAnotherOrg";
    Activity[Activity["OrganizationUpdateAnotherOrg"] = KATO_ONLY_OFFSET + 108] = "OrganizationUpdateAnotherOrg";
    Activity[Activity["ZonesRead"] = 200] = "ZonesRead";
    Activity[Activity["ZonesUpdateMetaData"] = 201] = "ZonesUpdateMetaData";
    Activity[Activity["ZonesCreate"] = KATO_ONLY_OFFSET + 202] = "ZonesCreate";
    Activity[Activity["ZonesDelete"] = KATO_ONLY_OFFSET + 203] = "ZonesDelete";
    Activity[Activity["ZonesReadAllInOrg"] = 204] = "ZonesReadAllInOrg";
    Activity[Activity["ZonesPublish"] = KATO_ONLY_OFFSET + 205] = "ZonesPublish";
    Activity[Activity["TeamsCreate"] = 300] = "TeamsCreate";
    Activity[Activity["TeamsUpdate"] = 303] = "TeamsUpdate";
    Activity[Activity["TeamsDelete"] = 304] = "TeamsDelete";
    Activity[Activity["TeamsRead"] = 305] = "TeamsRead";
    Activity[Activity["TeamsManageUsers"] = 306] = "TeamsManageUsers";
    Activity[Activity["UsersCreate"] = 401] = "UsersCreate";
    Activity[Activity["UsersRemoveFromOrganization"] = 404] = "UsersRemoveFromOrganization";
    Activity[Activity["UsersRead"] = 405] = "UsersRead";
    Activity[Activity["UsersUpdate"] = KATO_ONLY_OFFSET + 406] = "UsersUpdate";
    Activity[Activity["UnitsUpdateMetaData"] = 500] = "UnitsUpdateMetaData";
    Activity[Activity["UnitsRead"] = 502] = "UnitsRead";
    Activity[Activity["UnitsCreate"] = KATO_ONLY_OFFSET + 503] = "UnitsCreate";
    Activity[Activity["UnitsDelete"] = KATO_ONLY_OFFSET + 504] = "UnitsDelete";
    Activity[Activity["UnitsDownloadKey"] = KATO_ONLY_OFFSET + 505] = "UnitsDownloadKey";
    Activity[Activity["UnitsDeviceConfigurationUpdate"] = KATO_ONLY_OFFSET + 506] = "UnitsDeviceConfigurationUpdate";
    Activity[Activity["UnitsDeviceConfigurationRead"] = 507] = "UnitsDeviceConfigurationRead";
    Activity[Activity["UnitsDeviceConfigurationPush"] = KATO_ONLY_OFFSET + 508] = "UnitsDeviceConfigurationPush";
    Activity[Activity["UnitsReportConfigRead"] = 509] = "UnitsReportConfigRead";
    Activity[Activity["UnitsReportConfigUpdate"] = 510] = "UnitsReportConfigUpdate";
    Activity[Activity["UnitsReportRun"] = 511] = "UnitsReportRun";
    Activity[Activity["UnitsGatewayInformationRead"] = 512] = "UnitsGatewayInformationRead";
    Activity[Activity["EventsConfigurationRead"] = 700] = "EventsConfigurationRead";
    Activity[Activity["EventsConfigurationAdd"] = 701] = "EventsConfigurationAdd";
    Activity[Activity["EventsConfigurationUpdate"] = 702] = "EventsConfigurationUpdate";
    Activity[Activity["EventsConfigurationDelete"] = 703] = "EventsConfigurationDelete";
    Activity[Activity["GraphRead"] = 800] = "GraphRead";
    Activity[Activity["GraphReadCsv"] = 802] = "GraphReadCsv";
    Activity[Activity["EventsCommentsAdd"] = 900] = "EventsCommentsAdd";
    Activity[Activity["EventsRead"] = 902] = "EventsRead";
    Activity[Activity["EventsResolve"] = 903] = "EventsResolve";
    Activity[Activity["EventsDelete"] = 905] = "EventsDelete";
    Activity[Activity["EventsUpdateStatus"] = 906] = "EventsUpdateStatus";
    Activity[Activity["SupportedDevicesAddEdit"] = KATO_ONLY_OFFSET + 1200] = "SupportedDevicesAddEdit";
    Activity[Activity["SupportedDevicesDelete"] = KATO_ONLY_OFFSET + 1201] = "SupportedDevicesDelete";
    Activity[Activity["SupportedDevicesRead"] = 1202] = "SupportedDevicesRead";
    Activity[Activity["ReportsRead"] = 1300] = "ReportsRead";
    Activity[Activity["NotificationConfigCreate"] = 1400] = "NotificationConfigCreate";
    Activity[Activity["NotificationConfigRead"] = 1401] = "NotificationConfigRead";
    Activity[Activity["NotificationConfigUpdate"] = 1402] = "NotificationConfigUpdate";
    Activity[Activity["NotificationConfigDelete"] = 1403] = "NotificationConfigDelete";
    Activity[Activity["NotificationTestSms"] = 1500] = "NotificationTestSms";
    Activity[Activity["MLAIConfigRead"] = 1600] = "MLAIConfigRead";
    Activity[Activity["MLAIConfigCreate"] = 1601] = "MLAIConfigCreate";
    Activity[Activity["MLAIConfigDelete"] = 1602] = "MLAIConfigDelete";
    Activity[Activity["MLAIConfigUpdate"] = 1603] = "MLAIConfigUpdate";
    Activity[Activity["MLAIModelGenerate"] = KATO_ONLY_OFFSET + 1604] = "MLAIModelGenerate";
})(Activity || (Activity = {}));
