export default class ReportClient {
    constructor(restClient) {
        this.Client = restClient;
    }
    List(orgID) {
        return this.Client.Get(`/orgs/${orgID}/reports`).Expect(200);
    }
    Get(orgID, reportID) {
        return this.Client.Get(`orgs/${orgID}/reports/${reportID}`).Expect(200);
    }
}
