export default {
    there_was_a_problem_updating_the_role: "There was a problem updating the role",
    delete_role: "Delete Role",
    new_role: "New Role",
    role_deleted: "Role Deleted",
    are_you_sure_you_want_to_delete_this_role: "Are you sure you want to delete this role?",
    no_roles_available: "No Roles Available",
    there_was_a_problem_creating_the_role: "There was a problem creating the role",
    permissions: "Permissions",
    there_was_an_error_deleting_this_role: "There was an error deleting this role",
    remove_users_from_teams: "Remove Users From Teams",
    role_created: "Role Created",
    note_any_changes_will_take_effect_when_the_user_next_logs_in: "NOTE: Any changes will take effect when the user next logs in",
    add_roles_to_user: "Add Roles To User",
    role_updated: "Role updated",
    add_a_role: "Add A Role",
};
