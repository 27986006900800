<script lang="ts">
  import { params } from "@sveltech/routify";
  import { ConnectivityStatus } from "api/shared/ConnectivityStatus";
  import type Zone from "api/zone/Zone";
  import type ZoneStatus from "api/zone/ZoneStatus";
  import { Status } from "api/zone/ZoneStatus";
  import Card from "components/Card.svelte";
  import ContentBox from "components/ContentBox.svelte";
  import SubTitleBox from "components/SubTitleBox.svelte";
  import TitleBox from "components/TitleBox.svelte";
  import { ApiClient } from "setup";
  import { _ } from "svelte-i18n";
  import { derived, Readable } from "svelte/store";
  import Interval from "utils/interval";

  export let zone: Zone;

  let status: Readable<ZoneStatus> = derived<Readable<number>, ZoneStatus>(
    Interval(15000),
    ($time, set) => {
      ApiClient.Zone.Status($params.orgID, zone.Key).then((s) => {
        set(s);
      });
    },
    {
      TimeStamp: new Date(),
      Status: Status[Status.NotReporting],
      ConnectivityStatus: ConnectivityStatus[ConnectivityStatus.NotReporting],
    }
  );
</script>

<style lang="scss">
  @import "theme";

  a {
    color: rgba(0, 0, 0, 0.87);
  }

  td {
    padding: 5px;
  }
</style>

<Card>
  <TitleBox>
    <h5>
      <a href={`/orgs/${$params.orgID}/zones/${zone.Key}`}>{zone.Name}</a>
    </h5>
  </TitleBox>
  <SubTitleBox>
    <table>
      <tr>
        <td>{$_('location')}:</td>
        <td>{zone.Location}</td>
      </tr>
      <tr>
        <td>{$_('status')}:</td>
        <td>{$status.Status}</td>
      </tr>
      <tr>
        <td>{$_('connectivity')}</td>
        <td>{$status.ConnectivityStatus}</td>
      </tr>
    </table>
  </SubTitleBox>
  <ContentBox />
</Card>
