import { writable } from "./SessionStore";
import { Activity } from "../api/shared/Activity";
import { derived } from "svelte/store";
import { Settings } from "../setup";
let auth = writable("Authentication", {
    AuthHeader: null,
    RenewToken: null,
    Timeout: null,
    Activities: null,
});
export default auth;
export let HasPermission = derived(auth, (_auth) => {
    let f = (activity, orgID) => {
        if (!_auth || !_auth.Activities) {
            return false;
        }
        if (orgID) {
            if (f(Activity.OrganizationReadAll)) {
                return f(activity);
            }
            if (orgID in _auth.Activities) {
                return _auth.Activities[orgID].indexOf(activity) !== -1;
            }
            return false;
        }
        for (let key in _auth.Activities) {
            if (_auth.Activities[key].indexOf(activity) !== -1) {
                return true;
            }
        }
        return false;
    };
    return f;
});
export let HasAnyPermission = derived(HasPermission, (_HasPermission) => {
    return (activities, orgID) => {
        for (let activity of activities) {
            if (_HasPermission(activity, orgID)) {
                return true;
            }
            return false;
        }
    };
});
export let IsGenosysAdmin = derived(auth, ($auth) => {
    if ($auth && $auth.Activities) {
        return Settings.ADMIN_ORGANIZATION in $auth.Activities;
    }
    return false;
});
export let FirstOrg = derived(auth, ($auth) => {
    if ($auth && $auth.Activities) {
        return Object.keys($auth.Activities)[0];
    }
    return undefined;
});
