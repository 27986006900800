export default function DeepEqual(a, b) {
    if (a === b) {
        return true;
    }
    if (isObject(a) && isObject(b)) {
        if (Object.keys(a).length !== Object.keys(b).length) {
            return false;
        }
        for (var prop in a) {
            if (!DeepEqual(a[prop], b[prop])) {
                return false;
            }
        }
        return true;
    }
}
function isObject(obj) {
    if (typeof obj === "object" && obj != null) {
        return true;
    }
    else {
        return false;
    }
}
