import { __awaiter } from "tslib";
import App from "./App.svelte";
import Setup from "./setup";
import "./localization/i18n";
function main() {
    return __awaiter(this, void 0, void 0, function* () {
        yield setupServiceWorker();
        yield Setup();
        new App({
            target: document.body,
        });
    });
}
function setupServiceWorker() {
    return __awaiter(this, void 0, void 0, function* () {
        if ("serviceWorker" in navigator) {
            try {
                yield navigator.serviceWorker.register("/sw.js", { scope: "/" });
            }
            catch (e) {
                console.log("Service worker installation failed: ", e);
            }
        }
    });
}
main();
