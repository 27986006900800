Date.prototype.toReadableDateTime = function () {
    const dateDisplayOptions = {
        hour: "numeric",
        minute: "numeric",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour12: true,
    };
    return this.toLocaleDateString(undefined, dateDisplayOptions);
};
export function ToReadableDateTime(dateString) {
    return new Date(dateString).toReadableDateTime();
}
