import { __awaiter } from "tslib";
import ListStore from "utils/ListStore";
export default class EventClient {
    constructor(restClient) {
        this.RestClient = restClient;
        this.ListStore = new ListStore((e) => e.Key);
    }
    List(orgID, zoneID, unitID, after, before) {
        this.ListStore.Update((v) => __awaiter(this, void 0, void 0, function* () {
            let eventList = yield this.RestClient.Get(`/orgs/${orgID}/events`, {
                ZoneID: zoneID,
                UnitID: unitID,
                After: after,
                Before: before,
            }).Expect(200);
            return yield Promise.all(eventList.map((e) => this.RestClient.Get(`/orgs/${orgID}/events/${e.Key}`).Expect(200)));
        }));
        return this.ListStore;
    }
    Get(orgID, eventID, useCache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (useCache) {
                let event = yield this.ListStore.GetItem(eventID);
                if (event) {
                    return event;
                }
            }
            let event = yield this.RestClient.Get(`/orgs/${orgID}/events/${eventID}`).Expect(200);
            yield this.ListStore.UpdateItem(event);
            return event;
        });
    }
    Update(orgID, event) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.RestClient.Put(`/orgs/${orgID}/events/${event.Key}`, event).Expect(204);
            yield this.ListStore.UpdateItem(event);
        });
    }
    AddNote(orgID, eventID, text) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.RestClient.Post(`/orgs/${orgID}/events/${eventID}/notes`, {
                CommentText: text,
            }).Expect(204);
            let event = yield this.Get(orgID, eventID, false);
            let note = event.Notes.firstOrUndefined((n) => n.Text == text);
            if (!note) {
                note = {
                    UserWhoCreated: "You",
                    DateCreated: new Date().toISOString(),
                    Text: text,
                    IsAutoGenerated: false,
                };
            }
            return note;
        });
    }
}
