export default {
    read_holding_registers: "Read Holding Registers (FC3)",
    operation: "Operation",
    create_device: "Create Device",
    genosys_http_api_connection_options: "GenOSys HTTP Api",
    there_was_a_problem_creating_the_device: "There was a problem creating the device. Please try again later.",
    divide: "Divide",
    modbus: "Modbus",
    there_was_a_problem_publishing_the_device: "There was a problem publishing the device.  Try again later.",
    maximum_register_grouping_distance: "Maximum Register Grouping Distance",
    device_checked_out: "Device Checked Out",
    connection_options: "Connection Options",
    baud_rate: "Baud Rate",
    it_will_not_be_visible_to_users_until_published: "It will not be visible to users until published.",
    slave_id: "Slave ID",
    publish: "Publish",
    is_published: "Is Published",
    number_of_transforms: "# of Transforms",
    data_bits: "Data Bits",
    swap16: "Swap 16",
    swap32: "Swap 32",
    are_you_sure_you_want_to_publish_this_device: "Are you sure you want to publish this device?",
    timeout: "Timeout",
    parity: "Parity",
    there_was_a_problem_updating_the_device: "There was a problem updating the device.  Please try again later.",
    add_transform: "Add Transform",
    there_was_a_problem_deleting_the_device: "There was a problem deleting the device.  Please try again later.",
    you_will_no_longer_be_able_to_make_changes_without_creating_a_new_version: "You will no longer be able to make changes without creating a new version.",
    modbus_request_options: "Modbus Request Options",
    stop_bits: "Stop Bits",
    device_created: "Device Created",
    multiply: "Multiply",
    are_you_sure_you_want_to_delete_this_device: "Are you sure you want to delete this device?",
    device_published: "Device Published",
    modbus_rtu: "Modbus RTU",
    minimum_request_period: "Minimum Request Period",
    shift_right: "Shift Right",
    there_was_a_problem_with_your_inputs: "There was a problem with your inputs. Please check them and try again.",
    requests: "Requests",
    or: "Or",
    request_removed_but_unable_to_save_device: "Request removed, but unable to save device.",
    no_devices: "No Devices",
    add: "Add",
    and: "And",
    the_device_name_must_be_unique: "The Device name must be unique.",
    order: "Order",
    value: "Value",
    swap4: "Swap 4",
    swap8: "Swap 8",
    address: "Address",
    read_input_registers: "Read Input Registers (FC4)",
    shift_left: "Shift Left",
    device_deleted: "Device Deleted",
    there_was_a_problem_checking_out_the_device: "There was a problem checking out the device.  Try again later.",
    read_coil: "Read Coil (FC1)",
    read_discrete_input: "Read Discrete Input (FC2)",
    this_will_create_a_copy_of_this_product: "This will create a copy of this product and update the version.",
    are_you_sure_you_want_to_check_out_this_device: "Are you sure you want to check out this device?",
    request_added_unable_to_save_device: "Request added, but unable to save device.",
    modbus_tcp_ip: "Modbus TCP/IP",
    checkout: "Checkout",
    device_saved: "Device Saved",
    modbus_function_code: "Modbus Function Code",
    subtract: "Subtract",
    transforms: "Transforms",
    device_information_invalid: "Device information invalid.  Check your inputs and try again.",
    port: "Port",
};
