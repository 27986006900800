<script lang="ts">
  import type { Note } from "api/event/Event";
  import Button from "components/Button.svelte";
  import Card from "components/Card.svelte";
  import Icon from "components/Icon.svelte";
  import TextBox from "components/Textbox.svelte";
  import { Translate } from "localization/i18n";
  import { ApiClient } from "setup";
  import { onDestroy } from "svelte";
  import { _ } from "svelte-i18n";
  import { ToReadableDateTime } from "utils/date";
  import Interval from "utils/interval";
  import params from "utils/params";

  export let notes: Note[];
  let noteText: string = "";

  onDestroy(
    Interval(15000).subscribe(async (t) => {
      let event = await ApiClient.Event.Get($params.orgID, $params.eventID);
      notes = event.Notes;
    })
  );

  $: {
    notes.sort((a, b) => {
      if (new Date(a.DateCreated) < new Date(b.DateCreated)) {
        return -1;
      }
      return 1;
    });
  }

  async function saveNote(): Promise<void> {
    let note = await ApiClient.Event.AddNote($params.orgID, $params.eventID, noteText);
    notes.push(note);
    //Trigger an update.
    notes = notes;
    noteText = "";
  }
</script>

<style lang="scss">
  div.note-label {
    font-size: 0.7rem;
    opacity: 0.6;
    text-align: right;
  }
</style>

<Card>
  <ul class="collection with-header">
    <li class="collection-header">
      <h5>{$_('notes')}</h5>
    </li>
    {#each notes as note}
      <li class="collection-item">
        <div class="note-text">
          {#if note.IsAutoGenerated}{Translate(note.Text)}{:else}{note.Text}{/if}
        </div>
        <div class="note-label">
          {note.UserWhoCreated.split('@')[0]} @ {ToReadableDateTime(note.DateCreated)}
        </div>
      </li>
    {/each}
    <li class="collection-item">
      <TextBox bind:value={noteText}>{$_('add_a_note')}</TextBox>
      <span class="secondary-content">
        <Button on:click={saveNote}>
          {$_('add')}
          <Icon float="right">send</Icon>
        </Button>
      </span>
    </li>
  </ul>
</Card>
