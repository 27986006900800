export default {
    are_you_sure_you_want_to_reactivate: "Are you sure you want to reactivate the following organization?",
    enable: "Enable",
    are_you_sure_you_want_to_delete: "Are you sure you want to delete the following organization?",
    create_organization: "Create Organization",
    standard_header: "Organization",
    organization_created_successfully: "Organization Created Successfully",
    organization_deactivated: "Organization Deactivated",
    org_name_too_long: "The organization name cannot exceed {1} characters",
    add: "Add",
    users: "Users",
    zones: "Zones",
    organization_deleted: "Organization Deleted",
    updated_successfully: "Organization Updated Successfully",
    are_you_sure_you_want_to_deactivate: "Are you sure you want to deactivate the following organization?",
    organization_reactivated: "Organization Reactivated",
};
