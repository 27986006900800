<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import generateID from "../utils/id";
  import M from "materialize-css";

  interface modalControl {
    open(): void;
    close(): void;
    destroy(): void;
  }

  export let id = generateID();
  export let bottomModalWidth = 1000;
  export let open: boolean = true;

  const dispatch = createEventDispatcher();
  let modal: HTMLElement;
  let control: modalControl;
  let destroying: boolean = false;

  onMount(() => {
    control = M.Modal.init(modal, {
      onCloseStart: () => {
        if (!destroying) {
          dispatch("close:start");
        }
      },
      onCloseEnd: () => {
        open = false;
        if (destroying) {
          control.destroy();
        } else {
          dispatch("close:end");
        }
      },
      onOpenEnd: () => dispatch("open:end"),
      onOpenStart: () => dispatch("open:start"),
    });

    control.open();
  });

  onDestroy(() => {
    if (control) {
      destroying = true;
      control.close();
    }
  });

  $: {
    if (control) {
      if (open) {
        control.open();
      } else {
        control.close();
      }
    }
  }
</script>

<style lang="scss">
  .modal-footer:empty {
    display: none;
  }

  .modal.bottom-sheet {
    max-height: 80%;
  }
</style>

<div bind:this={modal} {id} class="modal" class:bottom-sheet={window.innerWidth < bottomModalWidth}>
  <slot />
</div>
