<script lang="ts">
  import { onMount } from "svelte";
  import type SensorValue from "api/unit/SensorValue";
  import Interval from "utils/interval";
  import SystemPartial from "./_System.svelte";
  import { params } from "@sveltech/routify";
  import { fade } from "svelte/transition";
  import { ApiClient } from "setup";
  import { derived, Readable } from "svelte/store";
  import type Unit from "api/unit/Unit";
  import UnitPartial from "../../_Unit.svelte";
  import Spinner from "components/Spinner.svelte";
  import { _ } from "svelte-i18n";
  import GraphModal from "./_GraphModal.svelte";

  let unit: Promise<Unit> = ApiClient.Unit.Get($params.orgID, $params.zoneID, $params.unitID);
  let windowWidth;

  let values: Readable<SensorValue[]> = derived([Interval(15000)], ([$time], set) => {
    ApiClient.Unit.Sensors($params.orgID, $params.zoneID, $params.unitID).then((values) => {
      set(values);
    });
  });
</script>

<style lang="scss">
  h5 {
    opacity: 0.6;
  }
</style>

<!-- svelte-ignore missing-declaration -->
{#await unit}
  <Spinner size="large" />
{:then _unit}
  <h5>{$_('unit')}</h5>
  <UnitPartial unit={_unit} />
  <h5>{$_('system')}</h5>
  <div in:fade class="row">
    {#each _unit.Sensors.map((s) => s.System).unique() as system}
      <div class="col s12 m6 l4">
        <SystemPartial
          {system}
          sensors={_unit.Sensors.filter((s) => s.System == system)}
          values={$values} />
      </div>
    {/each}
  </div>
  <GraphModal unit={_unit} />
{:catch err}
  <div in:fade class="center">{err}</div>
{/await}
