export default {
    parameters: "Parameters",
    serial_number: "Serial Number",
    no_requests_configured: "No Requests Configured",
    connected: "Connected",
    no_data_to_display_for_unit: "There is no data to display for this unit.",
    phone_number: "Phone Number",
    device_configuration_saved: "Device configuration saved",
    failed_to_create_unit: "Failed to create unit.  Please try again later",
    windows: "Windows",
    copy_device_settings: "Copy Device Settings",
    interval_units: "(ms)",
    unit_could_not_be_deleted_error: "Unit could not be deleted.  Please wait a moment and try again.",
    delete_request_message: "Are you sure you want to delete this request configuration?",
    pdp_type: "PDP Type",
    graphs: "Graphs",
    memory: "Memory",
    unable_to_update_event_list: "Unable to update event list.  You may wish to refresh the page",
    application_version: "Application Version",
    there_was_a_problem_updating_the_unit: "There was a problem updating the unit.  Please try again in a moment.",
    phoenix_ipc: "Phoenix IPC",
    network_interfaces: "Network Interfaces",
    gateway: "Gateway",
    legato: "Legato",
    uptime: "Uptime",
    products: "Products",
    kernel: "Kernel",
    runtime_hours: "Runtime Hours",
    os_version: "OS Version",
    connection: "Connection",
    show_in_data_tab: "Show In Data Tab",
    system: "System",
    add_device: "Add Device",
    unable_to_push: "Unable to push device configuration. Please wait a moment and try again.",
    unable_to_update_device_configuration: "Unable to update device configurations.  You may wish to refresh the page before continuing.",
    category: "Category",
    unknown: "Unknown",
    connection_type_required: "Connection type is required",
    serial_pound: "Serial #",
    unable_to_update_dashboard_data: "Unable to update dashboard data.  You may wish to refresh the page",
    select_a_unit: "Select A Unit",
    are_you_sure_you_want_to_delete_this_unit: "Are you sure you want to delete this unit?",
    device_id: "DeviceID",
    apn_connected: "APN Connected",
    hostname: "Hostname",
    host_info: "Host Info",
    copy_device_settings_description: "This will copy the devices and all of their connection data and associated requests from another unit.",
    memory_used_percent: "Memory Used Percent",
    manufacturer: "Manufacturer",
    devices: "Devices",
    details: "Details",
    modbus_rtu: "Modbus RTU",
    modbus_tcp: "Modbus TCP/IP",
    average_voltage: "Average Voltage",
    unit_created: "Unit Created",
    interval: "Interval",
    os: "Operating System",
    memory_available: "Memory Available",
    connection_address: "Connection Address",
    show_on_zone_dashboard: "Show on Zone Dashboard",
    there_are_no_units_for_this_zone_yet: "There are no units for this zone yet",
    edit_request: "Edit Request",
    apn: "APN",
    day: "Day",
    cpu: "CPU",
    average_current: "Average Current",
    model: "Model",
    month: "Month",
    cores: "Cores",
    usage: "Usage",
    memory_total: "Memory Total",
    speed: "Speed",
    no_devices_configured: "No Devices Configured",
    radio_power: "Radio Power",
    address: "Address",
    add_a_row: "Add A Row",
    device_settings_copied: "Device Settings Copied",
    unit_updated: "Unit Updated",
    copy_device_settings_overwrite_warning: "Any Existing Settings For This Unit Will Be Overwritten",
    unit_deleted: "Unit Deleted",
    power_amplifier_temperature: "Power Amplifier Temperature",
    unit_details: "Unit Details",
    show_on_graphs: "Show On Graphs",
    name_to_show: "Name to show",
    connection_type: "Connection Type",
    output_power: "Output Power",
    power_controller_temperature: "Power Controller Temperature",
    unable_to_copy_device_settings: "Unable to copy device settings.  Try again later.",
    failed_to_download_csv: "Failed to download CSV.  Please try again later",
    unit_not_online: "The requested unit is not online.",
    invalid_device_type: "Invalid device type.  You may wish to refresh the page",
    there_was_a_problem_downloading_the_data: "There was a problem downloading the data.  Try again later.",
    platform: "Platform",
    add_request: "Add Request",
    device_name_required: "Device name is required",
    connection_address_required: "Connection address is required",
    edit_device: "Edit Device",
    config_pushed: "The configuration was sent to the device.",
    gen_o_sys_http_api: "GenOSys Http Api",
    model_version: "Model Version",
    not_connected: "Not Connected",
    memory_used: "Memory Used",
    application_uptime: "Application Uptime",
    copy_device_configuration_settings: "Copy Device Configuration Settings",
    duplicate_unit_name_error: "Another unit with this name already exists.  Please try again with a different name.",
    delete_device_message: "Are you sure you want to delete this device configuration?",
    bootloader: "Bootloader",
    cellular_signal: "Cellular Signal",
    current_network_operator: "Current Network Operator",
    add_a_unit: "Add A Unit",
    firmware: "Firmware",
    unable_to_access_that_unit: "Unable to access that unit",
    data: "Data",
    imei: "IMEI",
    week: "Week",
    push: "Push",
    problem_occurred_loading_the: "Problem occurred loading the",
    comprehensive: "Comprehensive"
};
