<script lang="typescript">
  import M from "materialize-css";
  import { onMount } from "svelte";
  import { FieldValidator } from "../utils/validate";

  export let id: string = "_" + Math.random().toString(36).substr(2, 9);
  export let type: string = "text";
  export let value: string = "";

  onMount(() => {
    M.updateTextFields();
  });

  export let validation: FieldValidator<string> = new FieldValidator<string>();
</script>

<style lang="scss">
  @import "./theme.scss";
  ul.validation {
    margin: -10px 0px 20px 20px;
    li {
      list-style-type: "- ";
      color: $error-color;
    }
  }
</style>

<div class="input-field">
  {#if type == 'password'}
    <input
      {id}
      class:valid={$validation.Valid && $validation.Dirty}
      class:invalid={!$validation.Valid && $validation.Dirty}
      type="password"
      bind:value
      on:input={() => $validation.Update(value)} />
  {:else}
    <input
      {id}
      class:valid={$validation.Valid && $validation.Dirty}
      class:invalid={!$validation.Valid && $validation.Dirty}
      type="text"
      bind:value
      on:input={() => $validation.Update(value)} />
  {/if}
  <label for={id}>
    <slot />
  </label>
  <ul class="validation">
    {#each $validation.Messages as message}
      <li>{message}</li>
    {/each}
  </ul>
</div>
