export default {
    delete_zone: "Delete Zone",
    view_unresolved_events: "View Unresolved Events",
    zone_could_not_deleted_error: "Zone could not be deleted.  Please try again in a moment.",
    are_you_sure_you_want_to_publish_this_zone: "Are you really sure you want to publish this zone?",
    view_full_report: "View Full Report",
    new_zone: "New Zone",
    the_zone_name_is_not_unique: "The zone name is not unique.  Please select another name.",
    zone_deleted: "Zone Deleted",
    publish_zone: "Publish Zone",
    are_you_sure_you_want_to_delete_this_zone: "Are you sure you want to delete this zone?",
    publish_zone_warning_cannot_be_undone: "WARNING: Publishing a zone cannot be undone",
    zone_published: "Zone Published",
    publish_zone_warning: "You are about to publish this zone.  This will allow live users to see and interact with it.  Please make sure that the zone is completely ready before publishing.",
    number_of_units: "Number Of Units",
    zones_per_page: "Zones Per Page:",
    no_zones_available: "No Zones Available",
    zone_created: "Zone Created",
    add_a_zone: "Add a Zone",
    zone_updated: "Zone Updated",
};
