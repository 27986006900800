<script lang="ts">
  import { afterUpdate, getContext } from "svelte";

  export let href: string = "";
  export let active: boolean = false;

  let update = getContext("tabs") as () => void;

  afterUpdate(update);
</script>

<li class="tab">
  <a on:click {href} class:active>
    <slot />
  </a>
</li>
