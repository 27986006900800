<script lang="ts">
  import { derived, Readable } from "svelte/store";
  import type User from "api/user/User";
  import { ApiClient } from "../setup";
  import AuthState from "state/Authentication";
  import EditUser from "./_EditSelf.svelte";

  let orgID: Readable<string> = derived([AuthState], ([_AuthState]) => {
    if (_AuthState && _AuthState.Activities) {
      return Object.keys(_AuthState.Activities)[0];
    }
    return "";
  });

  let user: Readable<User> = derived([AuthState, orgID], ([_AuthState, _orgID], set) => {
    if (_AuthState && _AuthState.AuthHeader) {
      ApiClient.User.Self(_orgID).then(set);
    }
    return null;
  });
</script>

{#if $user}
  <EditUser user={$user} orgID={$orgID} />
{/if}
