<script lang="ts">
  import { onDestroy, onMount, setContext } from "svelte";
  import M from "materialize-css";
  import { afterPageLoad, params } from "@sveltech/routify";

  let controls;

  export let transparent: boolean = false;

  export function Update() {
    if (controls) {
      controls._setupActiveTabLink();
      controls.updateTabIndicator();
    }
  }

  //Just use a string because the tooling is a PITA.
  setContext("tabs", Update);

  let tabs: HTMLElement;
  onMount(() => {
    controls = M.Tabs.init(tabs);
  });

  onDestroy(() => {
    controls.destroy();
  });
</script>

<style lang="scss">
  @import "theme";
  ul {
    background-color: $secondary-color;
    z-index: 10;
  }
</style>

<ul bind:this={tabs} class:transparent class="tabs tabs-fixed-width {$$props.class}">
  <slot />
</ul>
