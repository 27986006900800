export default {
    parameters: "Paramètres",
    serial_number: "Numéro de série",
    no_requests_configured: "Aucune demande configurée",
    connected: "Connecté",
    no_data_to_display_for_unit: "Il n'y a aucune donnée à afficher pour cette unité.",
    phone_number: "Numéro de téléphone",
    device_configuration_saved: "Configuration de l'appareil enregistrée",
    failed_to_create_unit: "Impossible de créer l'unité.  Réessayez plus tard.",
    copy_device_settings: "Copier les paramètres de l'appareil",
    interval_units: "(ms)",
    unit_could_not_be_deleted_error: "Impossible de supprimer l'unité.  Attendez un moment et réessayez.",
    delete_request_message: "Voulez-vous vraiment supprimer cette configuration de demande ?",
    pdp_type: "Type de PDP",
    graphs: "Graphiques",
    memory: "Mémoire",
    unable_to_update_event_list: "Impossible de mettre à jour la liste d'événements.  Vous souhaiterez peut-être actualiser la page",
    application_version: "Version de l'application",
    there_was_a_problem_updating_the_unit: "Un problème est survenu lors de la mise à jour de l'unité.  Veuillez réessayer dans un moment.",
    network_interfaces: "Interfaces réseau",
    gateway: "Passerelle",
    uptime: "La disponibilité",
    products: "Des produits",
    runtime_hours: "Heures d'exécution",
    os_version: "Version du système d'exploitation",
    connection: "Connexion",
    show_in_data_tab: "Afficher dans l'onglet de données",
    system: "Système",
    add_device: "Ajouter un appareil",
    unable_to_push: "Impossible de transférer la configuration de l'appareil. Attendez un moment et réessayez.",
    unable_to_update_device_configuration: "Impossible de mettre à jour les configuration des appareils.  Vous souhaiterez peut-être actualiser la page avant de continuer.",
    category: "Catégorie",
    unknown: "Inconnu",
    connection_type_required: "Le type de connexion est obligatoire",
    serial_pound: "N° de série",
    unable_to_update_dashboard_data: "Impossible de mettre à jour les données du tableau de bord.  Vous souhaiterez peut-être actualiser la page",
    select_a_unit: "Sélectionnez une unité",
    are_you_sure_you_want_to_delete_this_unit: "Voulez-vous vraiment supprimer cette unité ?",
    device_id: "Reference de l'appareil",
    apn_connected: "APN connecté",
    hostname: "Nom d'hôte",
    host_info: "Informations sur l'hôte",
    copy_device_settings_description: "Ceci copiera les appareils ainsi que toutes leurs données de connexion et demandes associées à partir d'une autre unité.",
    memory_used_percent: "Mémoire utilisée pour cent",
    manufacturer: "Fabricant",
    devices: "Dispositifs",
    details: "Détails",
    modbus_rtu: "Modbus RTU",
    modbus_tcp: "Modbus TCP/IP",
    average_voltage: "Tension moyenne",
    unit_created: "Unité créée",
    interval: "Intervalle",
    os: "Système d'exploitation",
    memory_available: "Mémoire disponible",
    connection_address: "Adresse de connexion",
    show_on_zone_dashboard: "Afficher sur le tableau de bord de la zone",
    there_are_no_units_for_this_zone_yet: "Il n'y a pas encore d'unité pour cette zone",
    edit_request: "Modifier la demande",
    day: "Jour",
    average_current: "Courant moyen",
    model: "Modèle",
    month: "Mois",
    cores: "Cœurs de processeur",
    usage: "Utilisation",
    memory_total: "Mémoire totale",
    speed: "Vitesse",
    no_devices_configured: "Aucun appareil configuré",
    radio_power: "Puissance radio",
    address: "Adresse",
    add_a_row: "Ajouter une ligne",
    device_settings_copied: "Paramètres de l'appareil copiés",
    unit_updated: "Unité mise à jour",
    copy_device_settings_overwrite_warning: "Tout paramètre existant pour cette unité sera remplacé",
    unit_deleted: "Unité supprimée",
    power_amplifier_temperature: "Température de l'amplificateur de puissance",
    unit_details: "Détails de l'unité",
    show_on_graphs: "Afficher sur les graphiques",
    name_to_show: "Nom à afficher",
    connection_type: "Type de connexion",
    output_power: "Puissance de sortie",
    power_controller_temperature: "Régulateur de puissance température",
    unable_to_copy_device_settings: "Impossible de copier les paramètres de l'appareil.  Réessayez plus tard.",
    failed_to_download_csv: "Impossible de télécharger le fichier CSV.  Réessayez plus tard.",
    unit_not_online: "L'unité demandée n'est pas en ligne.",
    invalid_device_type: "Type d'appareil non valide.  Vous souhaiterez peut-être actualiser la page",
    there_was_a_problem_downloading_the_data: "Un problème est survenu lors du téléchargement des données.  Réessayez plus tard.",
    platform: "Plate-forme",
    add_request: "Ajouter une demande",
    device_name_required: "Le nom de l'appareil est obligatoire",
    connection_address_required: "L'adresse de connexion est obligatoire",
    edit_device: "Modifier l'appareil",
    config_pushed: "La configuration a été envoyée à l'appareil.",
    gen_o_sys_http_api: "GenOSys Http Api",
    model_version: "Version du modèle",
    not_connected: "Pas connecté",
    memory_used: "Mémoire utilisée",
    application_uptime: "Temps de disponibilité d'une application",
    copy_device_configuration_settings: "Copier les paramètres de configuration de l'appareil",
    duplicate_unit_name_error: "Il existe déjà une autre unité portant le même nom.  Réessayez avec un autre nom.",
    delete_device_message: "Voulez-vous vraiment supprimer cette configuration d'appareil ?",
    cellular_signal: "Signal cellulaire",
    current_network_operator: "Opérateur de réseau actuel",
    add_a_unit: "Ajouter une unité",
    unable_to_access_that_unit: "Impossible d'accéder à cette unité",
    data: "Données",
    week: "Semaine",
    push: "Envoyer",
    problem_occurred_loading_the: "Un problème est survenu lors du chargement du",
};
