<script lang="ts">
  import { FieldValidator } from "../utils/validate";

  export let group: any[];
  export let groupValue: any;
  export let value: boolean = false;
  export let validation: FieldValidator<boolean> = new FieldValidator<boolean>();

  $: updateCheckbox(group);
  $: updateGroup(value);

  function updateCheckbox(group) {
    if (group) {
      value = group.indexOf(groupValue) >= 0;
    }
  }

  function updateGroup(value) {
    if (group) {
      const index = group.indexOf(groupValue);
      if (value) {
        if (index < 0) {
          group.push(groupValue);
          //Trigger change.
          group = group;
        }
      } else {
        if (index >= 0) {
          group.splice(index, 1);
          //Trigger change.
          group = group;
        }
      }
    }
  }
</script>

<style lang="scss">
  label {
    display: block;
    margin: 5px;
  }
</style>

<label>
  <input
    type="checkbox"
    bind:checked={value}
    value={groupValue}
    on:change={() => $validation.Update(value)} />
  <span>
    <slot />
  </span>
</label>
