export default {
    hysteresis: "Hystérésis (s)",
    operator: "Operateur",
    work_in_progress: "Travail en cours",
    copy_settings_from_another_zone_title_case: "Copier les paramètres depuis une autre zone",
    changed_status_to_work_in_progress: "État modifié en Travail en cours",
    mlai_update_error_message: "Impossible de mettre à jour la configuration de machine learning",
    pow_correlation: "Corrélation exponentielle",
    level_set_to_clear: "Disparition de l'alarme.",
    settings_copied: "Paramètres copiés",
    custom: "Personnalisé",
    mlai_updated: "Machine learning mise a jour",
    alert_updated: "Alerte mise à jour",
    kva_datapoint: "kVA ",
    changed_status_to_resolved: "État modifié en Résolu",
    mark_as_resolved: "Marquer comme résolu",
    mark_as_work_in_progress: "Marquer comme travail en cours",
    mark_as_open: "Marquer comme ouvert",
    changed_status_to_open: "État modifié en Ouvert",
    events_per_page: "Événements par page :",
    alert_threshold: "Seuil d'alerte",
    general: "Généralités",
    update_event_status_error_message: "Un problème est survenu lors de la mise à jour de l'état.  Veuillez réessayer plus tard.",
    calculation: "Calcul",
    three_d_correlation: "Corrélation Trimensionnelle",
    pf_data_point: "Facteur de puissance ",
    all_events: "Tous les événements",
    comparison: "Comparaison",
    deviation: "Déviation",
    note_added: "Remarque ajoutée",
    activate_event_configuration_error_message: "Un problème est survenu lors de l'activation de cette alerte.  Veuillez réessayer plus tard.",
    alarm_threshold_percent: "Seuil d'alerte (%)",
    prediction: "Prediction",
    last30_days: "30 derniers jours",
    alert_enabled: "Alerte activée",
    no_zones_for_alert_configs: "Aucune zone à configurer pour les alertes n'est actuellement disponible",
    sensor_reading: "Lecture du capteur",
    alert_disabled: "Alerte désactivée",
    copy_settings_description: "Ceci copiera l'état activé/désactivé et les valeurs d'alerte de toutes les alertes d'une autre zone. ",
    select_a_zone: "Sélectionnez une zone",
    add_config: "Ajouter une configuration",
    copy_settings_from_another_zone: "Copier les paramètres depuis une autre zone",
    rotor_health_prediction: "Prédiction de l'état de santé du rotor",
    start: "Début",
    caution: "Attention",
    get_events_for_timespan_error_message: "Un problème est survenu lors de l'obtention d'événements pour la période spécifiée.  Veuillez réessayer dans un moment.",
    failed_to_delete_alert_config: "Échec de la suppression de la configuration des alertes.",
    details: "Détails",
    alert_update_error_message: "Impossible de mettre à jour l'alerte.",
    no_events: "Aucun événement",
    status_updated: "État mis à jour",
    there_was_a_problem_updating_the_status: "Un problème est survenu lors de la mise à jour de l'état.  Veuillez réessayer dans un moment.",
    phase_c_third_harmonic_datapoint: "Paramètre Phase C ",
    is_resolved: "Est résolu",
    datapoint: "Paramètres",
    exponent: "Exposant",
    open_events: "Événements ouverts",
    no_open_events_for_unit: "Il n'y a aucun événement ouvert pour cette unité.",
    no_open_events_for_zone: "Il n'y a aucun événement non résolu pour cette zone.",
    copy_settings_overwrite_warning: "Tout paramètre existant pour cette zone sera écrasé",
    end: "Fin",
    one_or_more_parameters_was_invalid_error_message: "Une ou plusieurs entrées sont invalides. L'alerte n'a pas été enregistrée.",
    a: "A",
    b: "B",
    a_1: "A1",
    a_2: "A2",
    b_2: "B2",
    b_1: "B1",
    resolved: "Résolu",
    alarm: "Alarme",
    notes: "Remarques",
    level: "Niveau",
    title: "Titre",
    note_could_not_be_added: "Impossible d'ajouter la remarque. Veuillez réessayer dans une minute.",
    last_year: "L'année dernière",
    level_changed_from_alarm_to_critical: "Le niveau d'alarme est devenu critique.",
    critical: "Critique",
    error_percentage: "Pourcentage d'erreur",
    view_alert_history: "Afficher l'historique des alertes",
    linear_correlation: "Corrélation linéaire",
    phase_a_third_harmonic_datapoint: "Paramètre Phase A ",
    last120_days: "120 derniers jours",
    level_changed_to_clear: "Niveau changé pour effacer.",
    level_changed_to_alarm: "Niveau changé en alarme.",
    choose_operator: "Choisissez un opérateur",
    failed_to_add_alert_config: "Impossible d'ajouter une configuration d'alerte",
    datapoint1: "Paramètre 1",
    datapoint3: "Paramètre 3",
    datapoint2: "Paramètre 2",
    choose_datapoint: "Choisir le paramètre",
    phase_b_third_harmonic_datapoint: "Paramètre Phase B",
    average: "Moyenne",
    choose_rule: "Choisir une règle",
    stator_health_prediction: "Prédiction de l'état de santé du stator",
    field_must_be_a_number: "Le champ doit être un nombre",
    critical_threshold_percent: "Seuil critique (%)",
    maximum: "Maximum",
    sensor_readings: "Lectures du capteur",
    failed_to_add_mlai_config: "Impossible d'ajouter une configuration de Machine learning",
    no_load_efi: "Courant d'excitation à vide",
    level_changed_from_critical_to_alarm: "Changement d'état de critique à alarme",
    are_you_sure_you_want_to_delete_this_mlai_configuration: "Êtes-vous sûr de vouloir supprimer cette configuration de Machine learning? ",
    are_you_sure_you_want_to_delete_this_alert_configuration: "Êtes-vous sûr de vouloir supprimer cette configuration d'alerte?",
    critical_threshold: "Seuil critique",
    rule_type: "Type de règle",
    add_a_note: "Ajouter une remarque...",
    efi_datapoint: "Courant d'excitation",
    could_not_change_date_range: "Impossible de modifier la plage de dates. Veuillez réessayer dans une minute.",
    days: "Journées",
    none: "Aucun",
    open: "Ouvert",
    unit: "Appareil",
    deactivate_event_configuration_error_message: "Un problème est survenu lors de la désactivation de cette alerte.  Veuillez réessayer plus tard.",
};
