import { __awaiter } from "tslib";
import AuthState, { HasPermission } from "../../state/Authentication";
import { get } from "svelte/store";
import { Activity } from "../shared/Activity";
import RestStore from "state/RestStore";
export default class OrgClient extends RestStore {
    constructor(restClient) {
        super();
        this.RestClient = restClient;
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            let auth = get(AuthState);
            let hasPermission = get(HasPermission);
            if (!hasPermission(Activity.OrganizationReadAll)) {
                let orgs = [];
                for (let key in auth.Activities) {
                    if (hasPermission(Activity.OrganizationRead, key)) {
                        orgs.push(yield this.Get(key));
                    }
                }
                if (orgs.length < 1) {
                    throw "No organizations available.";
                }
                return orgs;
            }
            return yield this.RestClient.Get("/orgs").Expect(200);
        });
    }
    keySelector(item) {
        return item.NameKey;
    }
    get(key) {
        return this.RestClient.Get(`/orgs/${key}`).Expect(200);
    }
    update(organization) {
        return this.RestClient.Put(`/orgs/${organization.NameKey}`, organization).Expect(200);
    }
    add(item) {
        return this.RestClient.Post(`/orgs/`, item).Expect(200);
    }
    delete(key) {
        return this.RestClient.Delete(`/orgs/${key}`).Expect(200);
    }
}
