<script lang="ts">
  import { goto, params, url } from "@sveltech/routify";
  import type Organization from "api/org/Organization";
  import { Activity } from "api/shared/Activity";
  import Button from "components/Button.svelte";
  import Card from "components/Card.svelte";
  import ContentBox from "components/ContentBox.svelte";
  import CornerButton from "components/CornerButton.svelte";
  import EditList from "components/EditList.svelte";
  import FooterBox from "components/FooterBox.svelte";
  import Icon from "components/Icon.svelte";
  import Modal from "components/Modal.svelte";
  import SubTitleBox from "components/SubTitleBox.svelte";
  import Tab from "components/Tab.svelte";
  import Tabs from "components/Tabs.svelte";
  import TextBox from "components/Textbox.svelte";
  import TitleBox from "components/TitleBox.svelte";
  import { Translate } from "localization/i18n";
  import { ApiClient } from "setup";
  import { HasPermission } from "state/Authentication";
  import { _ } from "svelte-i18n";
  import { ErrorToast, Toast } from "utils/toast";
  import { FormValidator, NotEmpty, ShorterThan } from "utils/validate";
  import EditRole from "./_EditRole.svelte";
  import EditTeam from "./_EditTeam.svelte";
  import UserPartial from "./_EditUser.svelte";

  export let organization: Organization;
  let tabs: Tabs;
  let loading = false;
  let formValidator = new FormValidator();

  async function saveDetails() {
    try {
      loading = true;
      await ApiClient.Org.Update(organization);
      Toast(Translate("saved"));
      formValidator.MarkClean();
    } catch (e) {
      ErrorToast(e);
    } finally {
      loading = false;
    }
  }

  // @ts-ignore
  $: availableTabs = (() => {
    let result = [];
    if ($HasPermission(Activity.OrganizationUpdate, organization.NameKey)) {
      result.push("details");
    }
    if ($HasPermission(Activity.UsersUpdate, organization.NameKey)) {
      result.push("users");
    }
    if ($HasPermission(Activity.RolesUpdate, organization.NameKey)) {
      result.push("roles");
    }
    if ($HasPermission(Activity.TeamsUpdate, organization.NameKey)) {
      result.push("teams");
    }
    return result;
  })();

  let roles = ApiClient.Role;
  let users = ApiClient.User;
  let teams = ApiClient.Team;

  let addRole = false;
</script>

<style lang="scss">
  a {
    color: rgba(0, 0, 0, 0.87);
  }

  td {
    padding: 5px;
  }
  .modal-loading-spinner {
    margin-top: 100px;
  }

  h5 {
    margin: 0.493333rem 0 0.456rem 0;
  }
</style>

<Card>
  {#if availableTabs.length}
    <CornerButton
      on:click={() => {
        $goto(`/orgs`, { organization: organization.NameKey, tab: availableTabs[0] });
      }}>
      <Icon>edit</Icon>
    </CornerButton>
  {/if}
  <TitleBox>
    <h5>
      <a href={$url(`/orgs/${organization.NameKey}/zones`)}>{organization.Name}</a>
    </h5>
  </TitleBox>
  <SubTitleBox>{organization.Description}</SubTitleBox>
  <ContentBox>
    <table>
      <tr>
        <td>{$_('users')}:</td>
        <td>{organization.NumberOfUsers}</td>
      </tr>
      <tr>
        <td>{$_('zones')}:</td>
        <td>{organization.NumberOfZones}</td>
      </tr>
    </table>
  </ContentBox>
</Card>

<Modal
  open={$params.organization == organization.NameKey}
  on:close:end={() => $goto('/orgs')}
  on:open:end={() => tabs.Update()}>
  {#if organization.NameKey == $params.organization}
    <Tabs bind:this={tabs}>
      {#each availableTabs as tab}
        <Tab
          on:click={$goto('/orgs', { organization: organization.NameKey, tab: tab })}
          href={$url('/orgs', { organization: organization.NameKey, tab: tab })}
          active={$params.tab == tab}>
          {$_(tab)}
        </Tab>
      {/each}
    </Tabs>
    {#if $params.tab == 'details'}
      <ContentBox>
        <TextBox
          bind:value={organization.Name}
          validation={formValidator
            .Is(NotEmpty($_('name_is_required')))
            .Is(ShorterThan(64, $_('name_length_error')))}>
          {$_('name')}
        </TextBox>
        <TextBox
          bind:value={organization.Description}
          validation={formValidator
            .Is(NotEmpty($_('description_is_required')))
            .Is(ShorterThan(256, $_('description_length_error')))}>
          {$_('description')}
        </TextBox>
      </ContentBox>
      <FooterBox>
        <Button
          {loading}
          disabled={!$formValidator.IsDirty() || !$formValidator.IsValid()}
          on:click={saveDetails}>
          {$_('save')}
        </Button>
      </FooterBox>
    {:else if $params.tab == 'users'}
      <EditList
        items={$users.List(organization.NameKey)}
        deleteItem={(user) => $users.Delete(organization.NameKey, user.Key)}
        let:item={user}>
        <span slot="list-title">{$_('users')}</span>
        <span slot="add-item-title">{$_('invite_a_user')}</span>
        <span slot="item-header">{user.FirstName}</span>
        <UserPartial {user} orgID={$params.organization} />
        <span slot="no-items">{$_('no_users')}</span>
        <span slot="are-you-sure">{$_('user_delete_confirmation')}</span>
      </EditList>
    {:else if $params.tab == 'roles'}
      <EditList
        items={$roles.List(organization.NameKey)}
        deleteItem={(role) => $roles.Delete(organization.NameKey, role.NameKey)}
        let:item={role}>
        <span slot="list-title">{$_('roles')}</span>
        <span slot="add-item-title">{$_('new_role')}</span>
        <span slot="item-header">{role.DisplayName}</span>
        <EditRole {role} />
        <span slot="no-items">{$_('no_roles_available')}</span>
        <span slot="are-you-sure">{$_('are_you_sure_you_want_to_delete_this_role')}</span>
      </EditList>
    {:else if $params.tab == 'teams'}
      <EditList
        items={$teams.List(organization.NameKey)}
        deleteItem={(team) => $teams.Delete(organization.NameKey, team.NameKey)}
        let:item={team}>
        <span slot="list-title">{$_('teams')}</span>
        <span slot="add-item-title">{$_('create_team')}</span>
        <span slot="item-header">{team.DisplayName}</span>
        <EditTeam {team} />
        <span slot="no-items">{$_('no_teams')}</span>
        <span slot="are-you-sure">{$_('are_you_sure_you_want_to_delete_this_team')}</span>
      </EditList>
    {/if}
  {/if}
</Modal>
