<script lang="ts">
  import type Event from "api/event/Event";
  import type Unit from "api/unit/Unit";
  import type Zone from "api/zone/Zone";
  import FilterList from "components/FilterList.svelte";
  import Icon from "components/Icon.svelte";
  import Select from "components/Select.svelte";
  import { Translate } from "localization/i18n";
  import { ApiClient } from "setup";
  import { _ } from "svelte-i18n";
  import { fade } from "svelte/transition";
  import { ToReadableDateTime } from "utils/date";
  import params from "utils/params";
  import { ErrorToast, Toast } from "utils/toast";

  if ($params.startTime == undefined) {
    $params.startTime = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString();
  }

  let groupActions: Record<string, (selected: Event[]) => void> = {};
  groupActions[Translate("mark_as_open")] = (selected) => {
    setStatus("Open", selected);
  };
  groupActions[Translate("mark_as_resolved")] = (selected) => {
    setStatus("Resolved", selected);
  };
  groupActions[Translate("mark_as_work_in_progress")] = (selected) => {
    setStatus("WorkInProgress", selected);
  };

  async function setStatus(status: string, selected: Event[]) {
    let promises: Promise<void>[] = [];
    try {
      for (let event of selected) {
        event.Status = status;
        promises.push(ApiClient.Event.Update($params.orgID, event));
      }
      await Promise.all(promises);
      Toast(Translate("saved"));
    } catch (err) {
      ErrorToast(err);
    }
  }

  let eventClient = ApiClient.Event;

  //@ts-ignore
  $: events = ApiClient.Event.List(
    $params.orgID,
    $params.zoneID,
    $params.unitID,
    $params.startTime
  );
  let zones: Promise<Zone[]> = ApiClient.Zone.List($params.orgID);
  // @ts-ignore
  $: units = (async () => {
    if ($params.zoneID) {
      return await ApiClient.Unit.List($params.orgID, $params.zoneID);
    }
    let _zones = await zones;
    let _units = await Promise.all(_zones.map((z) => ApiClient.Unit.List($params.orgID, z.Key)));
    return _units.mapMany<Unit>();
  })();

  let filterModalOpen: boolean = false;
</script>

<style lang="scss">
  h5 {
    opacity: 0.6;
  }

  a {
    color: inherit;
    font-weight: 500;
  }

  span.status {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 12px;
    opacity: 0.6;
  }

  .level {
    position: absolute;
    width: 42px;
    height: 62px;
    overflow: hidden;
    left: 15px;
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
  }
</style>

<h5>{$_('events')}</h5>
<FilterList {groupActions} items={$events} let:item={event}>
  <div class="row" slot="controls">
    <div class="col s12 m6 l4">
      {#await zones then _zones}
        <Select bind:value={$params.zoneID} label={$_('zone')}>
          <option value={undefined}>{$_('all')}</option>
          {#each _zones as zone}
            <option value={zone.Key}>{zone.Name}</option>
          {/each}
        </Select>
      {/await}
    </div>
    <div class="col s12 m6 l4">
      {#await units then _units}
        <Select bind:value={$params.unitID} label={$_('unit')}>
          <option value={undefined}>{$_('all')}</option>
          {#each _units as unit}
            <option value={unit.Key}>{unit.Name}</option>
          {/each}
        </Select>
      {/await}
    </div>
    <div class="col s12 m6 l4">
      <Select bind:value={$params.startTime} label={$_('time')}>
        <option value={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()}>
          {$_('last30_days')}
        </option>
        <option value={new Date(new Date().setDate(new Date().getDate() - 120)).toISOString()}>
          {$_('last120_days')}
        </option>
        <option value={new Date(new Date().setDate(new Date().getDate() - 365)).toISOString()}>
          {$_('last_year')}
        </option>
        <option value={new Date(0).toISOString()}>{$_('all_events')}</option>
      </Select>
    </div>
  </div>
  <span class="level">
    {#if event.Level == 'Critical'}
      <Icon color="red" size="3rem">error</Icon>
    {:else if event.Level == 'Alarm'}
      <Icon color="orange" size="3rem">error_outline</Icon>
    {:else}
      <Icon color="green" size="3rem">check_circle</Icon>
    {/if}
  </span>
  <a class="title" href={`/orgs/${$params.orgID}/events/${event.Key}`}>{event.Title}</a>
  <span class="status">{$_(event.Status)}</span>
  <p>
    {$_('zone')}: {event.ZoneName} {$_('unit')}: {event.UnitName}
    <br />
    {ToReadableDateTime(event.StartTime)}
    {#if event.EndTime}- {ToReadableDateTime(event.EndTime)}{/if}
  </p>
  <div in:fade class="center" slot="no-items">{$_('no_events')}</div>
</FilterList>
