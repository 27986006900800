export default {
    team_name_length_error: "Le nom de l'équipe doit comporter moins de 64 caractères.",
    unable_to_add_user: "Impossible d'ajouter l'utilisateur.  Réessayez dans un moment.",
    there_was_a_problem_saving_the_zones: "Un problème est survenu lors de l'enregistrement des zones.",
    problem_deleting_team: "Un problème est survenu lors de la suppression de l'équipe. Réessayez plus tard.",
    team_description_required: "La description de l'équipe est obligatoire.",
    alert_subscriptions_saved: "Abonnements aux alertes enregistrés",
    alert_subscriptions: "Abonnements aux alertes",
    delete_team: "Supprimer une équipe",
    user_removed_from_team: "Utilisateur supprimé de l'équipe",
    no_teams: "Aucune équipe",
    team_created: "Équipe créée",
    unable_to_update_zones_for_team: "Impossible de mettre à jour les zones.  Réessayez dans un moment.",
    are_you_sure_you_want_to_delete_this_team: "Voulez-vous vraiment supprimer cette équipe ?",
    team_deleted: "Équipe supprimée",
    team_updated: "Équipe mise à jour",
    no_alerts_available_to_subscribe_to: "Aucune alerte à laquelle s'abonner n'est disponible.",
    unable_to_create_team: "Impossible de créer une équipe.",
    team_name_required: "Le nom de l'équipe est obligatoire.",
    create_team: "Créer une équipe",
    team_duplicate_name_error: "Il existe déjà une équipe portant ce nom. Choisissez un autre nom.",
    team_name_format_error: "Le nom de l'équipe ne peut pas contenir de caractères spéciaux.",
    unable_to_update_team: "Impossible de mettre à jour l'équipe.",
    zones_saved: "Zones enregistrées",
    there_was_a_problem_saving_alert_subscriptions: "Un problème est survenu lors de l'enregistrement des abonnements aux alertes. Réessayez plus tard.",
    unable_to_remove_user: "Impossible de supprimer l'utilisateur.  Réessayez dans un moment.",
    team_members: "Membres de l'équipe",
    there_was_a_problem_fetching_alert_subscription: "Un problème est survenu lors de la récupération des abonnements aux alertes.  Réessayez plus tard.",
    team_created_with_issues: "Équipe créée, mais un problème est survenu lors de l'ajout de certains utilisateurs à l'équipe",
    click_here_to_setup_alerts: "Cliquez ici pour configurer les alertes.",
    user_added_to_team: "Utilisateur ajouté à l'équipe",
    number_of_team_members: "Nombre de membres de l'équipe",
};
