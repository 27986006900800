import { __awaiter } from "tslib";
import AuthClient from "./auth/AuthClient";
import AuthState from "../state/Authentication";
import UserClient from "./user/UserClient";
import { get } from "svelte/store";
import OrgClient from "./org/OrgClient";
import ZoneClient from "./zone/ZoneClient";
import UnitClient from "./unit/UnitClient";
import RoleClient from "./roles/RoleClient";
import TeamClient from "./team/TeamClient";
import EventClient from "./event/EventClient";
import ReportClient from "./report/ReportClient";
import AlertConfigClient from "./alertConfig/AlertConfigClient";
import GraphClient from "./graphs/GraphClient";
class Client {
    constructor(restClient) {
        this.RestClient = restClient;
        this.RestClient.GetAuthHeader = () => __awaiter(this, void 0, void 0, function* () {
            if (get(AuthState).AuthHeader && new Date(get(AuthState).Timeout) > new Date(Date.now())) {
                return get(AuthState).AuthHeader;
            }
            let refreshToken = get(AuthState).RenewToken;
            if (refreshToken) {
                AuthState.update((value) => {
                    value.RenewToken = null;
                    return value;
                });
                let resp = yield this.Auth.Refresh(refreshToken);
                AuthState.set(resp);
                return get(AuthState).AuthHeader;
            }
            return null;
        });
        this.Auth = new AuthClient(this.RestClient);
        this.User = new UserClient(this.RestClient);
        this.Org = new OrgClient(this.RestClient);
        this.Zone = new ZoneClient(this.RestClient);
        this.Unit = new UnitClient(this.RestClient);
        this.Role = new RoleClient(this.RestClient);
        this.Team = new TeamClient(this.RestClient);
        this.Event = new EventClient(this.RestClient);
        this.Report = new ReportClient(this.RestClient);
        this.AlertConfig = new AlertConfigClient(this.RestClient);
        this.Graph = new GraphClient(this.RestClient);
    }
    Login(username, password) {
        return __awaiter(this, void 0, void 0, function* () {
            let resp = yield this.Auth.Login(username, password);
            AuthState.set(resp);
            return resp;
        });
    }
}
export default Client;
