<script lang="ts">
  import { params } from "@sveltech/routify";
  import { EventLevel } from "api/shared/EventLevel";
  import type SensorValue from "api/unit/SensorValue";
  import type Unit from "api/unit/Unit";
  import type UnitStatus from "api/unit/UnitStatus";
  import Card from "components/Card.svelte";
  import ContentBox from "components/ContentBox.svelte";
  import SubTitleBox from "components/SubTitleBox.svelte";
  import TitleBox from "components/TitleBox.svelte";
  import { Translate } from "localization/i18n";
  import { ApiClient } from "setup";
  import { _ } from "svelte-i18n";
  import { derived, Readable } from "svelte/store";
  import Interval from "utils/interval";

  export let unit: Unit;
  export let showParameters: boolean = false;

  let status: Readable<UnitStatus> = derived(Interval(15000), ($time, set) => {
    ApiClient.Unit.Status($params.orgID, $params.zoneID, unit.Key).then((status) => {
      set(status);
    });
  });

  let sensors: Readable<SensorValue[]> = derived(Interval(15000), ($time, set) => {
    if (showParameters) {
      ApiClient.Unit.Sensors($params.orgID, $params.zoneID, unit.Key).then((sensors) => {
        set(
          sensors.filter((s) => {
            return unit.Sensors.first((i) => i.Name == s.Name).IsZoneDashboard;
          })
        );
      });
    }
  });

  function statusValue(eventLevel: EventLevel): string {
    switch (eventLevel) {
      case EventLevel.Alarm:
        return Translate("alarm");
      case EventLevel.Critical:
        return Translate("critical");
      case EventLevel.General:
        return Translate("general");
      case EventLevel.None:
        return Translate("ok");
    }
  }

  // @ts-ignore
  $: getValue = (name: string): string => {
    if ($sensors) {
      let value = $sensors.firstOrUndefined((v) => v.Name == name);
      if (value) {
        return parseFloat(value.Value).toFixed(3);
      }
    }
    return Translate("na");
  };
</script>

<style lang="scss">
  a {
    color: rgba(0, 0, 0, 0.87);
  }
  td {
    padding: 5px;
  }
</style>

<Card>
  <TitleBox>
    <h5>
      <a href={`/orgs/${$params.orgID}/zones/${$params.zoneID}/units/${unit.Key}`}>{unit.Name}</a>
    </h5>
  </TitleBox>
  <SubTitleBox>
    <table>
      {#if $status}
        <tr>
          <td>{$_('status')}:</td>
          <td>{statusValue(EventLevel[$status.ActiveEventLevel])}</td>
        </tr>
        <tr>
          <td>{$_('connectivity')}:</td>
          <td>{$status.ReportingStatus}</td>
        </tr>
      {/if}
    </table>
  </SubTitleBox>
  <ContentBox>
    <table>
      {#if !showParameters && unit.UnitData && unit.UnitData.length > 0}
        <tr>
          <th>{$_('unit_details')}</th>
          <th />
        </tr>
        {#each unit.UnitData as info}
          <tr>
            <td>{info.Name}</td>
            <td>{info.Value}</td>
          </tr>
        {/each}
      {/if}

      {#if showParameters}
        <tr>
          <th>{$_('parameters')}</th>
          <th />
        </tr>
        {#each unit.Sensors.filter((s) => s.IsZoneDashboard) as sensor}
          <tr>
            <td>{sensor.ShownName}</td>
            <td>{getValue(sensor.Name)} {sensor.Unit}</td>
          </tr>
        {/each}
      {/if}
    </table>
  </ContentBox>
</Card>
