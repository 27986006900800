import { __awaiter } from "tslib";
import RestResponse from "./RestResponse";
export default class RestClient {
    constructor(baseURL) {
        this.BaseURL = baseURL;
    }
    Get(path, query) {
        return this.do("GET", path + this.buildQuery(query));
    }
    Put(path, body) {
        return this.do("PUT", path, body);
    }
    Post(path, body) {
        return this.do("POST", path, body);
    }
    Delete(path) {
        return this.do("DELETE", path);
    }
    do(method, path, body) {
        return new RestResponse(this.fetch(method, path, body));
    }
    fetch(method, path, body) {
        return __awaiter(this, void 0, void 0, function* () {
            let headers = {};
            headers["Content-Type"] = "application/json";
            headers["Content-Encoding"] = "identity";
            if (this.GetAuthHeader) {
                let auth = yield this.GetAuthHeader();
                if (auth) {
                    headers["Authorization"] = auth;
                }
            }
            let request = {
                headers: headers,
                method: method,
                body: body ? JSON.stringify(body) : null,
            };
            return yield fetch(this.BaseURL + path, request);
        });
    }
    buildQuery(query) {
        if (!query) {
            return "";
        }
        let keyValuePairs = [];
        for (let key in query) {
            if (!query[key]) {
                continue;
            }
            if (Array.isArray(query[key])) {
                for (let value of query[key]) {
                    keyValuePairs.push(`${key}=${value}`);
                }
            }
            else {
                keyValuePairs.push(`${key}=${query[key]}`);
            }
        }
        return "?" + keyValuePairs.join("&");
    }
}
