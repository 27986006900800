<script lang="ts">
  import { ApiClient } from "../../../../setup";
  import { fade } from "svelte/transition";
  import type Zone from "../../../../api/zone/Zone";
  import Spinner from "../../../../components/Spinner.svelte";
  import ZonePartial from "./_Zone.svelte";
  import { goto, url, params } from "@sveltech/routify";
  import { derived, Readable } from "svelte/store";
  import { _ } from "svelte-i18n";

  // @ts-ignore
  $: zones = ApiClient.Zone.List($params.orgID);
</script>

<style lang="scss">
  h5 {
    opacity: 0.6;
  }
</style>

<h5>{$_('zones')}</h5>
{#await zones}
  <Spinner size="large" />
{:then _zones}
  {#if _zones.length === 0}
    <div in:fade class="center">{$_('no_zones_available')}</div>
  {:else}
    <div in:fade class="row">
      {#each _zones as zone}
        <div class="col s12 m6 l4">
          <ZonePartial {zone} />
        </div>
      {/each}
    </div>
  {/if}
{:catch err}
  <div in:fade class="center">{err}</div>
{/await}
