<script lang="ts">
  export let size: string = undefined;
  export let color: string = "inherit";
  export let float: string = "none";

  let styleOptions: string[] = [];

  styleOptions.push(`color: ${color}`);

  if (size && size != "tiny" && size != "small" && size != "medium" && size != "large") {
    styleOptions.push(`font-size: ${size}`);
  }
</script>

<i
  style={styleOptions.join(';')}
  on:click
  class="material-icons"
  class:right={float == 'right'}
  class:left={float == 'left'}
  class:tiny={size == 'tiny'}
  class:small={size == 'small'}
  class:medium={size == 'medium'}
  class:large={size == 'large'}>
  <slot />
</i>
