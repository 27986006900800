export default {
    delete_report_config_message: "Etes-vous sûr de vouloir supprimer cette configuration de rapport?",
    information: "Informations",
    no_sections_configured: "Pas de sections configurées",
    failed: "Echoué",
    cover_page: "Page de couverture",
    no_interval_configured: "Aucun intervalle configuré",
    interval_in_days: "Intervalle (jours)",
    no_reports: "Pas de Rapports",
    last30_days: "30 derniers jours",
    delete_interval: "Supprimer l'intervalle",
    duration_with_example: "Durée (journées:heures)",
    start_date: "date de début",
    start_time: "Heure de début",
    pending: "Pause",
    details: "Détails",
    interval: "Intervalle",
    unable_to_save_report_configurations: "Impossible d'enregistrer les configurations du rapport",
    end_date: "Date de fin",
    run_on_interval: "Courir sur intervalle",
    next_run_time: "Heure d'exécution suivante",
    unable_to_get_report_configurations: "Impossible d'obtenir les configurations du rapport",
    run: "Exécuter",
    title: "Titre",
    last_year: "L'année dernière",
    complete: "Terminé",
    copy_report_config: "Copier la configuration de rapport",
    last120_days: "120 derniers jours",
    run_settings: "Paramètres d'exécution",
    run_date: "Date de génération",
    running: "En cours",
    report_configurations_saved: "Configurations de rapports enregistrées",
    sections: "Sections",
    delete_section_message: "Etes-vous sûr de vouloir supprimer cette section?",
    add_report_config: "Configuration de nouveau rapport",
    unable_to_run_report: "Impossible d'exécuter le rapport.",
    description: "Description",
    duration: "Durée",
    no_reports_configured: "Pas de rapports configurés",
    all_reports: "Tous les rapports",
    view: "Visualisation",
};
