<script lang="ts">
  import Spinner from "./Spinner.svelte";

  export let loading: boolean = false;
  export let disabled: boolean = false;
  export let style: string = "btn";

  if (style == "flat") {
    style = "btn-flat";
  }
</script>

<style>
  button {
    width: 100%;
    margin-bottom: 20px;
  }
</style>

<button class="waves-effect waves-light {style}" class:disabled={loading || disabled} on:click>
  {#if loading}
    <Spinner size="small" />
  {/if}
  <slot />
</button>
