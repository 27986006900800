export default {
    delete_zone: "Supprimer la zone",
    view_unresolved_events: "Afficher les événements non résolus",
    zone_could_not_deleted_error: "Impossible de supprimer la zone.  Veuillez réessayer dans un moment.",
    are_you_sure_you_want_to_publish_this_zone: "Voulez-vous vraiment publier cette zone ?",
    view_full_report: "Afficher le rapport complet",
    new_zone: "Nouvelle zone",
    the_zone_name_is_not_unique: "Le nom de la zone n'est pas unique.  Sélectionnez un autre nom.",
    zone_deleted: "Zone supprimée",
    publish_zone: "Publier la zone",
    are_you_sure_you_want_to_delete_this_zone: "Voulez-vous vraiment supprimer cette zone ?",
    publish_zone_warning_cannot_be_undone: "AVERTISSEMENT : Il est impossible d'annuler la publication d'une zone",
    zone_published: "Zone publiée",
    publish_zone_warning: "Vous êtes sur le point de publier cette zone.  Cela permettra aux utilisateurs en ligne de la voir et d'interagir avec elle.  Vérifiez que la zone est totalement prête avant de la publier.",
    number_of_units: "Nombre d'appareils",
    zones_per_page: "Zones par page :",
    no_zones_available: "Aucune zone disponible",
    zone_created: "Zone créée",
    add_a_zone: "Ajouter une zone",
    zone_updated: "Zone mise à jour",
};
