import { writable } from "svelte/store";
export class Readable {
    constructor() {
        const { subscribe, set } = writable(this);
        this.subscribe = subscribe;
        this.set = set;
    }
    SignalUpdate() {
        this.set(this);
    }
}
