<script lang="ts">
  import Icon from "./Icon.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  let dispatch = createEventDispatcher();

  let block: HTMLElement;
  let content: HTMLElement;
  let controls: HTMLElement;

  let isOpen: boolean = false;

  let contentHeight: number = 500;
  let controlsHeight: number = 0;

  $: {
    if (block && content && controls) {
      contentHeight = content.offsetHeight;
      controlsHeight = controls.offsetHeight;
      if (isOpen) {
        block.style.height = controlsHeight + "px";
        controls.style.bottom = 0 + "px";
        content.style.bottom = -contentHeight + "px";
      } else {
        block.style.height = contentHeight + "px";
        content.style.bottom = 0 + "px";
        controls.style.bottom = contentHeight + "px";
      }
    }
  }

  function open() {
    isOpen = true;
    //Reset the height because bind doesn't work perfectly.
    controlsHeight = controls.offsetHeight;
    contentHeight = content.offsetHeight;
    dispatch("open");
  }

  function close() {
    isOpen = false;
    //Reset the height because bind doesn't work perfectly.
    controlsHeight = controls.offsetHeight;
    contentHeight = content.offsetHeight;
    dispatch("close");
  }

  onMount(() => {
    //Reset the height because bind doesn't work perfectly.
    controlsHeight = controls.offsetHeight;
    contentHeight = content.offsetHeight;
  });
</script>

<style lang="scss">
  .control-bar {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2000;
    cursor: pointer;
  }

  .block {
    position: relative;
    overflow: hidden;
    transition: height 200ms;
  }

  .controls {
    position: absolute;
    bottom: 100%;
    transition: bottom 200ms;
    width: 100%;
  }

  .content {
    padding-top: 10px;
    position: absolute;
    transition: bottom 200ms;
    width: 100%;
  }
</style>

<div class="window">
  <div class="block" bind:this={block}>
    <div class="controls" bind:this={controls} bind:offsetHeight={controlsHeight}>
      <slot name="controls" {close} />
    </div>
    <div class="content" bind:this={content} bind:offsetHeight={contentHeight}>
      <div class="control-bar" on:click={open}>
        <slot name="open">
          <Icon>more_vert</Icon>
        </slot>
      </div>
      <slot />
    </div>
  </div>
</div>
