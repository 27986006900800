export default {
    hysteresis: "Hysteresis (s)",
    operator: "Operator",
    work_in_progress: "Work In Progress",
    copy_settings_from_another_zone_title_case: "Copy Settings From Another Zone",
    changed_status_to_work_in_progress: "Status changed to Work In Progress",
    mlai_update_error_message: "Unable to update Machine learning configuration.",
    level_set_to_clear: "Level set to clear.",
    settings_copied: "Settings Copied",
    custom: "Custom",
    mlai_updated: "ML/AI Updated",
    alert_updated: "Alert Updated",
    kva_datapoint: "kVA Datapoint",
    changed_status_to_resolved: "Status changed to Resolved",
    mark_as_resolved: "Mark as resolved",
    mark_as_work_in_progress: "Mark as work in progress",
    mark_as_open: "Mark as open",
    changed_status_to_open: "Status changed to Open",
    events_per_page: "Events Per Page:",
    alert_threshold: "Alert Threshold",
    general: "General",
    update_event_status_error_message: "There was a problem updating the status.  Please try again in a moment.",
    calculation: "Calculation",
    pf_datapoint: "PF Datapoint",
    three_d_correlation: "Three Dimensional Correlation",
    all_events: "All Events",
    comparison: "Comparison",
    deviation: "Deviation",
    note_added: "Note Added",
    alarm_threshold_percent: "Alarm Threshold (%)",
    prediction: "Prediction",
    last30_days: "Last 30 Days",
    no_zones_for_alert_configs: "There are currently no zones available to be configured for alerts",
    copy_settings_description: "This will copy the enabled/disabled status and alert values for all alerts from another zone. ",
    select_a_zone: "Select a Zone",
    add_config: "New Event Configuration",
    copy_settings_from_another_zone: "Copy settings from another zone",
    rotor_health_prediction: "Rotor Health Prediction",
    start: "Start",
    caution: "Caution",
    get_events_for_timespan_error_message: "There was a problem getting events for the specified time.  Please try again in a moment.",
    failed_to_delete_alert_config: "Failed to delete alert configuration.",
    details: "Details",
    alert_update_error_message: "Unable to update alert.",
    no_events: "No Events",
    status_updated: "Status Updated",
    there_was_a_problem_updating_the_status: "There was a problem updating the status.  Please try again in a moment.",
    phase_c_third_harmonic_datapoint: "Phase C Third Harmonic Datapoint",
    is_resolved: "Is Resolved",
    datapoint: "Datapoint",
    exponent: "Exponent",
    open_events: "Open Events",
    no_open_events_for_unit: "There are no open events for this unit.",
    no_open_events_for_zone: "There are no unresolved events for this zone.",
    copy_settings_overwrite_warning: "Any Existing Settings For This Zone Will Be Overwritten",
    end: "Stop",
    one_or_more_parameters_was_invalid_error_message: "One or more inputs are invalid. Alert was not saved.",
    a: "A",
    b: "B",
    a_1: "A1",
    a_2: "A2",
    b_2: "B2",
    b_1: "B1",
    resolved: "Resolved",
    alarm: "Alarm",
    notes: "Notes",
    level: "Level",
    title: "Title",
    note_could_not_be_added: "Note could not be added. Please try again in a minute.",
    last_year: "Last Year",
    level_changed_from_alarm_to_critical: "Level changed from alarm to critical.",
    critical: "Critical",
    error_percentage: "Error Percentage",
    view_alert_history: "View Alert History",
    linear_correlation: "Linear Correlation",
    phase_a_third_harmonic_datapoint: "Phase A Third Harmonic Datapoint",
    last120_days: "Last 120 Days",
    level_changed_to_clear: "Level changed to clear.",
    level_changed_to_alarm: "Level changed to alarm.",
    choose_operator: "Choose Operator",
    failed_to_add_alert_config: "Failed to add alert configuration.",
    datapoint1: "Datapoint 1",
    datapoint3: "Datapoint 3",
    datapoint2: "Datapoint 2",
    choose_datapoint: "Choose Datapoint",
    phase_b_third_harmonic_datapoint: "Phase B Third Harmonic Datapoint",
    average: "Average",
    choose_rule: "Choose a rule",
    stator_health_prediction: "Stator Health Prediction",
    field_must_be_a_number: "Field must be a number",
    critical_threshold_percent: "Critical Threshold (%)",
    maximum: "Maximum",
    failed_to_add_mlai_config: "Failed to add Machine learning configuration.",
    no_load_efi: "No Load EFI",
    level_changed_from_critical_to_alarm: "Level changed from critical to alarm.",
    are_you_sure_you_want_to_delete_this_mlai_configuration: "Are You Sure You Want To Delete This Machine learning Configuration?",
    are_you_sure_you_want_to_delete_this_alert_configuration: "Are You Sure You Want To Delete This Alert Configuration?",
    exponential_correlation: "Exponential Correlation",
    critical_threshold: "Critical Threshold",
    rule_type: "Rule Type",
    add_a_note: "Add a Note...",
    efi_datapoint: "EFI Datapoint",
    could_not_change_date_range: "Could not change date range. Please try again in a minute.",
    days: "Days",
    none: "None",
    open: "Open",
    unit: "Unit",
};
