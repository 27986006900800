Array.prototype.groupBy = function (selector) {
    const val = this.reduce((groups, value) => {
        for (let group of groups) {
            if (selector(group[0]) == selector(value)) {
                group.push(value);
                return groups;
            }
        }
        groups.push([value]);
        return groups;
    }, []);
    return val;
};
Array.prototype.first = function (selector) {
    return this.filter(selector)[0];
};
Array.prototype.firstOrUndefined = function (selector) {
    let results = this.filter(selector);
    if (results.length > 0) {
        return results[0];
    }
    return undefined;
};
Array.prototype.unique = function () {
    return this.filter((value, index, self) => {
        return self.indexOf(value) == index;
    });
};
Array.prototype.mapMany = function (selector) {
    if (!selector) {
        return [].concat(...this);
    }
    return [].concat(...this.map(selector));
};
Array.prototype.max = function (comparator) {
    return this.reduce((acc, val) => {
        if (acc == undefined || comparator(acc, val)) {
            acc = val;
        }
        return acc;
    }, undefined);
};
export {};
