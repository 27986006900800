<script lang="ts">
  import Textbox from "../../components/Textbox.svelte";
  import Button from "../../components/Button.svelte";
  import type Team from "api/team/Team";
  import { _ } from "svelte-i18n";
  import { ShorterThan, FormValidator, NotEmpty, Regexp, Nop } from "../../utils/validate";
  import { ApiClient } from "../../setup";
  import { Toast, ErrorToast } from "../../utils/toast";
  import { Translate } from "../../localization/i18n";
  import type { Activity } from "api/shared/Activity";
  import { derived, Readable } from "svelte/store";
  import { params } from "@sveltech/routify";
  import type ActivityDescription from "api/roles/ActivityDescription";
  import Spinner from "components/Spinner.svelte";
  import CheckBox from "components/Checkbox.svelte";
  import Collapsable from "components/Collapsable.svelte";
  import CollapsableItem from "components/CollapsableItem.svelte";
  import { fade } from "svelte/transition";
  import "utils/array";
  import Select from "components/Select.svelte";
  import type Zone from "api/zone/Zone";
  import ContentBox from "components/ContentBox.svelte";
  import FooterBox from "components/FooterBox.svelte";

  let formValidator = new FormValidator();
  let loading = false;

  async function save(): Promise<void> {
    try {
      loading = true;
      if (team.NameKey) {
        await ApiClient.Team.Update($params.organization, team);
      } else {
        await ApiClient.Team.Add($params.organization, team);
      }
      Toast(Translate("saved"));
      formValidator.MarkClean();
    } catch (e) {
      ErrorToast(e);
    } finally {
      loading = false;
    }
  }

  let zones: Readable<Promise<Zone[]>> = derived(params, ($params) => {
    return ApiClient.Zone.List($params.organization);
  });

  export let team: Team;
  if(!team.Zones){
    team.Zones = [];
  }
</script>

<style lang="scss">
  h6 {
    opacity: 0.6;
    margin-bottom: 1.093rem;
  }
</style>

<ContentBox>
<Textbox
  bind:value={team.DisplayName}
  validation={formValidator
    .Is(NotEmpty($_('name_is_required')))
    .Is(ShorterThan(64, 'name_length_error'))}>
  {$_('name')}
</Textbox>
<Textbox
  bind:value={team.Description}
  validation={formValidator
    .Is(NotEmpty($_('description_is_required')))
    .Is(ShorterThan(64, 'description_length_error'))}>
  {$_('description')}
</Textbox>
{#await $zones}
  <Spinner />
{:then _zones}
  {#if _zones.length > 0}
    <h6>{$_('zones')}</h6>
    {#each _zones as zone}
      <CheckBox bind:group={team.Zones} groupValue={zone.Key} validation={formValidator.Is(Nop)}>
        {zone.Name}
      </CheckBox>
    {/each}
  {/if}
{:catch err}
  <div in:fade class="center">{err}</div>
{/await}
    </ContentBox>
    <FooterBox>
<Button {loading} disabled={!$formValidator.IsDirty() || !$formValidator.IsValid()} on:click={save}>
  {$_('save')}
</Button>
</FooterBox>
