import RestStore from "state/RestStore";
export default class GraphClient extends RestStore {
    constructor(restClient) {
        super();
        this.RestClient = restClient;
    }
    Data(orgID, zoneID, unitID, graphType, sensors) {
        return this.RestClient.Post(`/${orgID}/zones/${zoneID}/units/${unitID}/graphs`, {
            Aggregation: graphType,
            Sensors: sensors,
        }).Expect(200);
    }
    list(orgID, zoneID, unitID) {
        return this.RestClient.Get(`/${orgID}/zones/${zoneID}/units/${unitID}/graphs/configurations`).Expect(200);
    }
    get(orgID, zoneID, unitID, title) {
        return this.RestClient.Get(`/${orgID}/zones/${zoneID}/units/${unitID}/graphs/configurations/${title}`).Expect(200);
    }
    update(orgID, zoneID, unitID, config) {
        return this.RestClient.Put(`/${orgID}/zones/${zoneID}/units/${unitID}/graphs/configurations/${config.Key}`, config).Expect(204);
    }
    add(orgID, zoneID, unitID, config) {
        return this.RestClient.Post(`/${orgID}/zones/${zoneID}/units/${unitID}/graphs/configurations`, config).Expect(200);
    }
    delete(orgID, zoneID, unitID, key) {
        return this.RestClient.Delete(`/${orgID}/zones/${zoneID}/units/${unitID}/graphs/configurations/${key}`).Expect(204);
    }
    keySelector(item) {
        return item.Key;
    }
}
