export default {
    unable_to_remove_role_from_user: "Unable to remove role from user.  Try again in a moment",
    new_password_does_not_meet_strength_requirements: "New Password does not meet strength requirements",
    reset_password_token_expired: "The reset password token has expired.  Please try resetting your password again.",
    invite: "Invite",
    user_removed: "User Removed",
    no_users: "No Users",
    role_added: "Role Added",
    already_in_organization: "That user is already a part of the organization.",
    unable_to_add_role_to_user: "Unable to add role to user.  Try again in a moment",
    invalid_phone_number: "Phone number is invalid",
    user_updated: "User Updated",
    user_delete_confirmation: "Are you sure you want to delete this user?",
    registration_token_expired_please_request_a_new_invitation: "Registration token expired. Please request a new invitation",
    last_name: "Last Name",
    invite_a_user: "Invite A User",
    invitation_sent: "Invitation Sent",
    first_name: "First Name",
    problem_inviting_a_user: "There was a problem inviting the user.  Please try again later.",
    no_teams_available_for_user: "No Teams Available For User",
    role_removed: "Role Removed",
    could_not_update_password_at_this_time_please_try_again_later: "Could not update password at this time.  Please try again later.",
    password_does_not_meet_strength_requirements: "Password does not meet strength requirements",
    the_entered_current_password_is_incorrect_please_try_again: "The entered current password is incorrect.",
    no_roles_available_for_user: "No Roles Available For User",
    user_could_not_be_removed: "User could not be removed.  Please try again in a moment.",
};
