<script lang="ts">
  import type Organization from "../api/org/Organization";
  import type User from "../api/user/User";
  import AuthState, { HasPermission, HasAnyPermission } from "../state/Authentication";
  import { Activity } from "../api/shared/Activity";
  import { params, goto, url, isActive, route } from "@sveltech/routify";
  import { derived, Readable } from "svelte/store";
  import { ApiClient } from "../setup";
  import { afterUpdate } from "svelte";
  import { _ } from "svelte-i18n";
  import Dropdown from "./Dropdown.svelte";
  import DropdownItem from "./DropdownItem.svelte";
  import M from "materialize-css";
  import { fade } from "svelte/transition";
  import Tabs from "./Tabs.svelte";
  import Tab from "./Tab.svelte";
  import Icon from "components/Icon.svelte";

  export let logoURL = "/logo.png";

  let height: number;

  interface TabItem {
    Name: string;
    HRef: string;
  }

  let orgID: Readable<string> = derived([params, AuthState], ([_params, _AuthState]) => {
    if (_params.orgID) {
      return _params.orgID;
    } else if (_AuthState && _AuthState.Activities) {
      return Object.keys(_AuthState.Activities)[0];
    }
    return "";
  });

  let tabs: Readable<TabItem[]> = derived(
    [HasPermission, HasAnyPermission, _, orgID],
    ([$HasPermission, $HasAnyPermission, $_, $orgID]) => {
      let _tabs: TabItem[] = [];
      if ($HasPermission(Activity.ZonesRead, $orgID)) {
        _tabs.push({ Name: "zones", HRef: `/orgs/${$orgID}/zones` });
      }
      if ($HasPermission(Activity.EventsRead, $orgID)) {
        _tabs.push({ Name: $_("events"), HRef: `/orgs/${$orgID}/events` });
      }
      if ($HasPermission(Activity.ReportsRead, $orgID)) {
        _tabs.push({ Name: $_("reports"), HRef: `/orgs/${$orgID}/reports` });
      }
      if ($HasAnyPermission([Activity.SupportedDevicesRead], $orgID)) {
        _tabs.push({ Name: $_("devices"), HRef: `/devices` });
      }

      return _tabs;
    }
  );

  let user: Readable<User> = derived([AuthState, orgID], ([_AuthState, _orgID], set) => {
    if (_AuthState && _AuthState.AuthHeader) {
      ApiClient.User.Self(_orgID).then(set);
    }
    return null;
  });

  let orgs: Readable<Organization[]> = derived(
    [HasPermission, orgID],
    ([_HasPermission, _orgID], set) => {
      if (_HasPermission(Activity.OrganizationRead) && _orgID) {
        ApiClient.Org.List().then(set);
      }
    }
  );

  let currentOrg: Readable<Organization> = derived([orgs, orgID], ([_orgs, _orgID]) => {
    if (_orgs) {
      for (let org of _orgs) {
        if (org.NameKey == _orgID) {
          return org;
        }
      }
    }
  });

  function logout() {
    AuthState.set({
      AuthHeader: null,
      Activities: null,
      RenewToken: null,
      Timeout: null,
    });
    $goto("/");
  }
</script>

<style lang="scss">
  @import "theme";
  img {
    margin: 15px 15px;
    max-height: 30px;
  }
  nav {
    background-color: $secondary-color;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
  }
  span[slot="label"] {
    i {
      margin: 0;
    }
    span {
      margin: 0 5px;
    }
  }
</style>

<!-- This pushes the rest of the content down by the same height as the navbar. -->
<div style="height: {height}px;" />

<nav class="nav-extended" bind:clientHeight={height}>
  <div class="nav-wrapper">
    <a href="/" class="brand-logo">
      <img src={logoURL} alt="GenOSys" />
    </a>
    <ul class="right">
      {#if $orgs && $params.orgID}
        <li>
          <Dropdown>
            <span slot="label">
              <i class="material-icons left">business</i>
              <i class="material-icons right">arrow_drop_down</i>
              <span class="hide-on-med-and-down">{$currentOrg.Name}</span>
            </span>
            {#each $orgs as org}
              <DropdownItem>
                <a href={`/orgs/${org.NameKey}/zones`}>{org.Name}</a>
              </DropdownItem>
            {/each}
            <DropdownItem>
              <a href="/orgs">{$_('all')}</a>
            </DropdownItem>
          </Dropdown>
        </li>
      {/if}
      {#if $user}
        <li>
          <Dropdown>
            <span slot="label">
              <i class="material-icons left">account_circle</i>
              <i class="material-icons right">arrow_drop_down</i>
              <span class="hide-on-med-and-down">{$user.FirstName} {$user.LastName}</span>
            </span>
            <DropdownItem>
              <a href="/account">{$_('my_account')}</a>
            </DropdownItem>
            <DropdownItem>
              <span on:click={logout}>{$_('logout')}</span>
            </DropdownItem>
          </Dropdown>
        </li>
      {/if}
    </ul>
  </div>
  {#if $params.orgID && $tabs.length !== 0}
    <div class="nav-content">
      <Tabs transparent>
        {#each $tabs as tab}
          <Tab on:click={$goto(tab.HRef)} href={$url(tab.HRef)} active={$isActive(tab.HRef)}>
            {tab.Name}
          </Tab>
        {/each}
      </Tabs>
    </div>
  {/if}
</nav>
