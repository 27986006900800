<script lang="ts">
  import { _ } from "svelte-i18n";
</script>

<style lang="scss">
  @import "theme";
  @import "materialize-css/sass/materialize.scss";
  .page-footer {
    background-color: $secondary-color;
    text-align: center;
    @media #{$small-and-down} {
      a {
        display: block;
      }
    }
    a {
      cursor: pointer;
      color: $primary-color;
    }
    font-size: small;
  }
</style>

<footer class="page-footer">
  <div class="container">
    <a href="http://www.nidec-motor.com/Terms">{$_('terms_and_conditions_of_use')}</a>
    <span class="hide-on-small-only">|</span>
    <a href="https://www.motorboss.com/about-nidec/privacy-policy">{$_('privacy_policy')}</a>
    <span class="hide-on-small-only">|</span>
    <a href="http://acim.nidec.com/motors/leroy-somer/legal">{$_('legal_data_protection')}</a>
  </div>
  <div class="footer-copyright">
    <div class="container">© 2020 Copyright</div>
  </div>
</footer>
