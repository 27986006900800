export default {
    name_length_error: "Name must be less than 64 characters.",
    data_bits_is_required: "Data bits is required.",
    port_must_be_an_integer: "Port must be an integer.",
    order_must_be_greater_than0: "Order must be greater than zero.",
    please_enter_a_message: "Please enter a message.",
    order_must_be_an_integer: "Order must be an integer.",
    slave_id_must_be_an_integer: "Slave ID must be an integer.",
    at_least_one_connection_type_is_required: "At least one connection type is required.",
    baud_rate_must_be_between50_and460800: "Baud rate must be between 50 and 460,800.",
    location_is_required: "Location is required.",
    type_is_required: "Type is required.",
    legal_consent_is_required: "Consent is required.",
    slave_id_is_required: "Slave ID is required.",
    baud_rate_is_required: "Baud rate is required.",
    operation_is_required: "Operation is required.",
    units_is_required: "Units is required.",
    value_must_be_an_integer: "Value must be an integer.",
    order_must_be_unique: "Order must be unique.",
    timeout_is_required: "Timeout is required.",
    last_name_is_required: "Last Name is required.",
    timeout_must_be_an_integer: "Timeout must be an integer.",
    value_must_be_between_values: "Value must be between {0} and {1}",
    maximum_register_grouping_distance_must_be_an_integer: "Maximum Register Grouping Distance must be an integer.",
    confirm_password_is_required: "Confirm Password is required.",
    address_is_required: "Address is required.",
    request_name_contains_illegal_characters: "Name cannot contain characters /, #, ?, or \\",
    address_must_be_an_integer: "Address must be an integer.",
    confirm_new_password_is_required: "Confirm New Password is required.",
    minimum_request_period_must_be_greater_than_zero: "Minimum Request Period must be greater than zero.",
    minimum_request_period_is_required: "Minimum Request Period is required.",
    stop_bits_is_required: "Stop bits is required.",
    modbus_function_code_required: "Modbus Function Code is Required",
    value_is_required: "Value is required.",
    minimum_request_period_must_be_an_integer: "Minimum Request Period must be an integer.",
    maximum_register_grouping_distance_is_required: "Maximum Register Grouping Distance is required.",
    type_is_invalid: "Type is invalid.",
    maximum_register_grouping_distance_must_be_between0_and125: "Maximum Register Grouping Distance must be between 0 and 125.",
    description_is_required: "Description is required.",
    new_password_is_required: "New Password is required.",
    time_zone_is_required: "Time Zone is required.",
    parity_is_required: "Parity is required.",
    slave_id_must_be_between1_and247: "Slave ID must be between 1 and 247.",
    name_is_required: "Name is required.",
    address_must_be_between1_and65535: "Address must be beween 1 and 65535.",
    first_name_is_required: "First Name is required.",
    current_password_is_required: "Current Password is required.",
    timeout_must_be_greater_than_zero: "Timeout must be greater than 0.",
    port_must_be_between1_and65535: "Port must be between 1 and 65535.",
    baud_rate_must_be_an_integer: "Baud rate must be an integer.",
    please_enter_an_email_address: "Please enter an email address.",
    port_is_required: "Port is required.",
    request_name_must_be_unique: "Request name must be unique.",
    password_is_required: "Password is required.",
    email_is_required: "Email is required.",
    order_is_required: "Order is required.",
    must_be_shorter_than_n: "Must be less than {Length}",
};
