<script lang="ts">
  import M from "materialize-css";
  import { onMount } from "svelte";

  let collapsible: HTMLElement;
  export let accordion: boolean = false;

  onMount(() => {
    M.Collapsible.init(collapsible, { accordion: accordion });
  });
</script>

<style lang="scss">
  ul.collapsible {
    box-shadow: none;
  }
</style>

<ul bind:this={collapsible} class="collapsible">
  <slot />
</ul>
