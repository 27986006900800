import { __awaiter } from "tslib";
export default class RestResponse {
    constructor(resp) {
        this.Response = resp;
    }
    Value() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.Body) {
                return Promise.resolve(this.Body);
            }
            const resp = yield this.Response;
            if (resp.headers.has("content-type") &&
                resp.headers.get("content-type").includes("application/json")) {
                return resp.json();
            }
            else {
                return Promise.resolve(undefined);
            }
        });
    }
    Status() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.Response).status;
        });
    }
    Expect(expectedStatus, errorMessages) {
        return __awaiter(this, void 0, void 0, function* () {
            const status = yield this.Status();
            if (status == expectedStatus) {
                return yield this.Value();
            }
            else if (errorMessages && status in errorMessages) {
                if (typeof errorMessages[status] === "string") {
                    throw errorMessages[status];
                }
                else {
                    const body = yield (yield this.Response).text();
                    throw errorMessages[status](body);
                }
            }
            else {
                throw "Encountered and unexpected error.";
            }
        });
    }
}
