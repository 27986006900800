export default function DeepCopy(obj) {
    if (typeof obj !== 'object' || obj === null || obj instanceof RegExp || obj instanceof Date) {
        return obj;
    }
    if (Array.isArray(obj) || obj instanceof Int8Array || obj instanceof Uint8Array || obj instanceof Uint8ClampedArray || obj instanceof Int16Array || obj instanceof Uint16Array || obj instanceof Int32Array || obj instanceof Uint32Array || obj instanceof Float32Array || obj instanceof Float64Array || obj instanceof BigInt64Array || obj instanceof BigUint64Array) {
        return obj.map(e => DeepCopy(e));
    }
    const finalObject = {};
    for (let k in obj) {
        if (obj.hasOwnProperty(k)) {
            finalObject[k] = DeepCopy(obj[k]);
        }
    }
    return finalObject;
}
