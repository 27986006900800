export default function (node, threshold = 500) {
    const handle_mousedown = (e) => {
        const preventClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            node.parentNode.removeEventListener("click", preventClick, true);
        };
        const timeout = setTimeout(() => {
            node.dispatchEvent(new CustomEvent("longpress"));
            node.parentNode.addEventListener("click", preventClick, true);
        }, threshold);
        const cancel = () => {
            clearTimeout(timeout);
            node.removeEventListener("mouseout", cancel);
            node.removeEventListener("mouseup", cancel);
            node.removeEventListener("touchend", cancel);
        };
        node.addEventListener("mouseout", cancel);
        node.addEventListener("mouseup", cancel);
        node.addEventListener("touchend", cancel);
    };
    node.addEventListener("mousedown", handle_mousedown);
    node.addEventListener("touchstart", handle_mousedown);
    return {
        destroy() {
            node.removeEventListener("mousedown", handle_mousedown);
            node.removeEventListener("touchstart", handle_mousedown);
        },
    };
}
