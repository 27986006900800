<script lang="ts">
  import { ApiClient } from "setup";
  import { fade } from "svelte/transition";
  import type Zone from "api/zone/Zone";
  import type Unit from "api/unit/Unit";
  import UnitPartial from "./_Unit.svelte";
  import Spinner from "components/Spinner.svelte";
  import ZonePartial from "../_Zone.svelte";
  import { goto, url, params, route } from "@sveltech/routify";
  import { derived, Readable } from "svelte/store";
  import { _ } from "svelte-i18n";

  let zone: Promise<Zone> = ApiClient.Zone.Get($params.orgID, $params.zoneID);

  let units: Promise<Unit[]> = (async () => {
    let units = await ApiClient.Unit.List($params.orgID, $params.zoneID);
    units.sort((a: Unit, b: Unit): number => {
      if (a.Name < b.Name) {
        return -1;
      } else if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
    return units;
  })();
</script>

<style lang="scss">
  h5 {
    opacity: 0.6;
  }
</style>

<h5>{$_('zone')}</h5>
{#await zone}
  <Spinner size="large" />
{:then _zone}
  <div in:fade class="row">
    <div class="col s12">
      <ZonePartial zone={_zone} />
    </div>
  </div>
{:catch err}
  <div in:fade class="center">{err}</div>
{/await}

<h5>{$_('units')}</h5>
{#await units}
  <Spinner size="large" />
{:then _units}
  {#if _units.length === 0}
    <div in:fade class="center">{$_('no_zones_available')}</div>
  {:else}
    <div in:fade class="row">
      {#each _units as unit}
        <div class="col s12 m6 l4">
          <UnitPartial showParameters {unit} />
        </div>
      {/each}
    </div>
  {/if}
{:catch err}
  <div in:fade class="center">{err}</div>
{/await}
