import { __awaiter } from "tslib";
import Client from "./api/Client";
import RestClient from "./api/rest/RestClient";
import "./utils/array";
import "./utils/date";
export let ApiRestClient;
export let ApiClient;
export let Settings;
export default function Setup() {
    return __awaiter(this, void 0, void 0, function* () {
        let getSettings = yield fetch("/settings.json");
        if (getSettings.status != 200) {
            throw "Unable to get application settings.";
        }
        Settings = yield getSettings.json();
        ApiRestClient = new RestClient(Settings.API_URL);
        ApiClient = new Client(ApiRestClient);
    });
}
