import { addMessages, init, getLocaleFromNavigator, _ } from "svelte-i18n";
import { get } from "svelte/store";
import shared_en from "./shared_en";
import shared_fr from "./shared_fr";
import device_management_en from "./device_management_en";
import device_management_fr from "./device_management_en";
import events_en from "./events_en";
import events_fr from "./events_fr";
import mlai_en from "./mlai_en";
import mlai_fr from "./mlai_fr";
import notifications_en from "./notifications_en";
import notifications_fr from "./notifications_fr";
import organizations_en from "./organizations_en";
import organizations_fr from "./organizations_fr";
import reports_en from "./reports_en";
import reports_fr from "./reports_fr";
import roles_and_permissions_en from "./roles_and_permissions_en";
import roles_and_permissions_fr from "./roles_and_permissions_fr";
import teams_en from "./teams_en";
import teams_fr from "./teams_fr";
import time_zones_en from "./time_zones_en";
import time_zones_fr from "./time_zones_fr";
import units_en from "./units_en";
import units_fr from "./units_fr";
import users_en from "./users_en";
import users_fr from "./users_fr";
import validation_en from "./validation_en";
import validation_fr from "./validation_fr";
import zones_en from "./zones_en";
import zones_fr from "./zones_fr";
addMessages("en", shared_en);
addMessages("fr", shared_fr);
addMessages("en", device_management_en);
addMessages("fr", device_management_fr);
addMessages("en", events_en);
addMessages("fr", events_fr);
addMessages("en", mlai_en);
addMessages("fr", mlai_fr);
addMessages("en", notifications_en);
addMessages("fr", notifications_fr);
addMessages("en", organizations_en);
addMessages("fr", organizations_fr);
addMessages("en", reports_en);
addMessages("fr", reports_fr);
addMessages("en", roles_and_permissions_en);
addMessages("fr", roles_and_permissions_fr);
addMessages("en", teams_en);
addMessages("fr", teams_fr);
addMessages("en", time_zones_en);
addMessages("fr", time_zones_fr);
addMessages("en", units_en);
addMessages("fr", units_fr);
addMessages("en", users_en);
addMessages("fr", users_fr);
addMessages("en", validation_en);
addMessages("fr", validation_fr);
addMessages("en", zones_en);
addMessages("fr", zones_fr);
init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
});
export function Translate(code, options) {
    code = toSnakeCase(code);
    return get(_)(code, options);
}
function toSnakeCase(str) {
    return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => x.toLowerCase())
        .join("_");
}
