<script>
  import { goto, isActive, params, url } from "@sveltech/routify";
  import Footer from "components/Footer.svelte";
  import Navbar from "components/Navbar.svelte";
  import AuthState from "state/Authentication";

  if (!$AuthState.AuthHeader && !$isActive("/login")) {
    $goto("/login", { return_url: $url(window.location.pathname, $params) });
  }
</script>

<Navbar />

<main>
  <slot />
</main>

<Footer />
