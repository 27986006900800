<script lang="ts">
  import Spinner from "components/Spinner.svelte";
import { ApiClient } from "setup";
import { fade } from "svelte/transition";
import params from "utils/params";
import EventPartial from "./_Event.svelte";
import NotesPartial from "./_Notes.svelte";


  let event = ApiClient.Event.Get($params.orgID, $params.eventID);
</script>

<style lang="scss">
  .level {
    vertical-align: middle;
  }

  span.status-selector {
    float: right;
    margin: 12px 0 12px 0;
  }
  span.note-label {
    font-size: 0.7rem;
    margin-top: -0.8rem;
    opacity: 0.6;
    min-width: 8rem;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }
</style>

{#await event}
  <Spinner size="large" />
{:then _event}
<EventPartial event={_event}></EventPartial>
<NotesPartial notes={_event.Notes}></NotesPartial>

{:catch err}
  <div in:fade class="center">{err}</div>
{/await}
