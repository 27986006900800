export default {
    status: "Etat",
    no_zones_for_mlai_configs: "Pas de zone disponible pour configurer un Auto-apprentissage",
    prediction: "Prédiction",
    anomaly_detection: "Détection d'une anomalie",
    are_you_sure_you_want_to_train_this_model: "Êtes-vous sûr de vouloir faire l’apprentissage de ce modèle?",
    model_data_points: "Données du modèle",
    training: "Apprentissage en cours",
    error: "Erreur",
    add_mlai: "Ajouter un Auto-apprentissage (Machine Learning)",
    complete: "Terminer",
    preparing: "Préparation en cours",
    train_model: "Modèle appris",
    not_trained: "Modèle non Appris",
    type: "Type",
};
