export default {
    saved: "Enregistrée",
    registration_complete: "Inscription terminée !",
    connected: "Connecté",
    basic_information: "Informations de base",
    mlai_configuration: "Configuration des Machines learning",
    phone_number: "Numéro de téléphone",
    password_number_requirement: "Au moins 1 chiffre",
    password_successfully_changed: "Le mot de passe a été modifié",
    events: "Événements",
    serial_numbers: "Numéros de série",
    reset_password: "Réinitialiser le mot de passe",
    organization: "Organisation",
    email_notification: "Notification par Email",
    alerts: "Alertes",
    create_an_account: "Créer un compte",
    filter: "Filtre",
    legal_data_protection: "Protection des données personnelles",
    page_not_found_message: "Vous vous attendez à quelque chose ici ? Dans ce cas, contactez l'administrateur de votre site.",
    invalid_username_or_password: "Nom d'utilisateur ou mot de passe non valide",
    delete: "Supprimer",
    privacy_policy: "Politique de confidentialité",
    cookie_consent_button_text: "Accepter",
    password_uppercase_requirement: "Au moins 1 lettre majuscule",
    email_address: "Adresse e-mail",
    cancel: "Annuler",
    internal_server_error_message: "Veuillez patienter quelques minutes et réessayer. Si cela ne fonctionne toujours pas, contactez l'administrateur de votre site.",
    return_to_login: "RETOUR À LA CONNEXION",
    client_error_generic_message_for_request: "Une erreur inattendue s'est produite.  Vérifiez votre saisie et réessayez.",
    logout: "Déconnexion",
    current_password: "Mot de passe actuel",
    organizations: "Organisations",
    time_zone: "Fuseau horaire",
    general: "Généralités",
    forgot_password: "MOT DE PASSE OUBLIÉ ?",
    publish: "Publier",
    text_notification: "Notification de texte",
    create_organization: "Créer une organisation",
    phone_error_message: "Veuillez indiquer un numéro de téléphone valide",
    passwords_do_not_match_error: "Le mot de passe de confirmation et le nouveau mot de passe ne correspondent pas",
    password_strength_tooltip: "Les mots de passe doivent contenir au moins 8\r\ncaractères, 1 lettre minuscule, 1 lettre majuscule, 1 chiffre et 1 caractère spécial",
    date_time: "Date/heure",
    create_account: "Créer un compte",
    status: "État",
    search: "Rechercher",
    sign_in: "Se connecter",
    password_reset_email_sent: "Vous recevrez bientôt un e-mail contenant les instructions pour réinitialiser votre mot de passe.",
    are_you_sure: "En êtes-vous sûr ?",
    special_characters_are_not_allowed: "Les caractères spéciaux ne sont pas autorisés",
    password: "Mot de passe",
    caution: "Attention",
    contact_us_validation_failed: "Renseignez tous les champs pour continuer.",
    confirm_new_password: "Confirmer le nouveau mot de passe",
    password_lowercase_requirement: "Au moins 1 lettre minuscule",
    password_special_character_requirement: "Au moins 1 caractère spécial",
    please_look_over_your_inputs_and_try_again: "Vérifiez votre saisie et réessayez",
    reset_your_password: "Réinitialiser votre mot de passe",
    reactivate: "Réactiver",
    it_looks_like_your_account_hasnt_been_given_any_permissions_yet: "Il semble que votre compte ne dispose pas encore d'autorisations.",
    roles_and_permissions: "Rôles et autorisations",
    your_organization_is_disabled: "Votre organisation est désactivée.  Demandez de l'aide à l'administrateur de votre organisation ou à un représentant commercial GenOSys.",
    open_events: "Événements ouverts",
    change_password: "Modifier le mot de passe",
    na: "S.O.",
    ok: "Ok",
    of: "sur",
    kato_engineering_inc: "Kato Engineering Inc.",
    white_paper_validation_failed: "Saisissez une adresse e-mail valide.",
    add: "Ajouter",
    email: "E-mail",
    admin: "Admin",
    alarm: "Alarme",
    login: "Connexion",
    level: "Niveau",
    users: "Utilisateurs",
    units: "Unités",
    teams: "Équipes",
    roles: "Rôles",
    zones: "Zones",
    there_was_an_error_processing_your_request_please_try_again: "Une erreur s'est produite lors du traitement de votre demande.  Veuillez réessayer dans une minute.",
    select_all: "Tout sélectionner",
    last_name: "Nom",
    add_a_row: "Ajouter une ligne",
    critical: "Critique",
    partially_connected: "Partiellement connecté",
    view_event_history: "Afficher l'historique des événements",
    not_reporting: "Sans rapports",
    create_zone: "Créer une zone",
    contact_us_failed: "Votre message n'a pas été envoyé. Réessayez plus tard.",
    internal_server_error: "Nous sommes désolés, mais il semble qu'un problème se soit produit",
    please_contact_your_organization_administrator: "Veuillez contacter l'administrateur de votre organisation.",
    cookie_consent_link_text: "En savoir plus",
    last_updated: "Dernière mise à jour",
    version: "Version",
    page_not_found: "La page que vous recherchez est introuvable",
    first_name: "Prénom",
    my_account: "Mon compte",
    device_management: "Gestion des appareils",
    connectivity: "Connectivité",
    password_length_requirement: "Au moins 8 caractères",
    reports: "Rapports",
    unauthorized: "Non autorisé",
    message: "Message",
    organization_disabled: "Organisation désactivée",
    alert_level: "Niveau d'alerte",
    description: "Description",
    new_password: "Nouveau mot de passe",
    terms_and_conditions_of_use: "Conditions d'utilisation",
    all_rights_reserved: "Tous droits réservés.",
    cookie_consent_message: "Ce site utilise des cookies destinés à vous assurer la meilleure expérience de navigation",
    remember_me: "Se souvenir de moi",
    passwords_must_have: "Les mots de passe doivent contenir :",
    deactivate: "Désactiver",
    confirm_password: "Confirmer le mot de passe",
    alerts_configuration: "Configuration des alertes",
    location: "Lieu",
    view_entire_list: "Afficher la liste entière",
    reset_password_instructions: "Saisissez votre e-mail ci-dessous, et nous vous enverrons les instructions pour réinitialiser votre mot de passe.",
    data: "données",
    mlai: "Machine Learning",
    copy: "Copier",
    none: "Aucun",
    name: "Nom",
    unit: "Unités",
    home: "Accueil",
    type: "Type",
    time: "Heure",
    role: "Rôle",
    send: "Envoyer",
    save: "Enregistrer",
    zone: "Zone",
    success: "Réussite !",
    all: "Tout",
};
