<script lang="ts">
  import { afterUpdate, onDestroy, onMount } from "svelte";
  import M from "materialize-css";
  import { createEventDispatcher } from "svelte";
  import DeepEqual from "utils/deep_equal";

  const dispatch = createEventDispatcher();

  export let label: string = "";
  export let value: any;
  export let multiple: boolean = false;

  let oldValue: any = value;

  let select: HTMLElement;
  let control: any;

  function onCloseEnd(): void {
    if (!multiple) {
      if (!DeepEqual(oldValue, value)) {
        oldValue = value;
        dispatch("change", value);
      }
    }
  }

  onMount(() => {
    control = M.FormSelect.init(select, {
      dropdownOptions: {
        container: window.document.body,
        onCloseEnd: onCloseEnd,
      },
    });
  });

  afterUpdate(() => {
    if (!multiple) {
      control.destroy();
      control = M.FormSelect.init(select, {
        dropdownOptions: {
          container: window.document.body,
          onCloseEnd: onCloseEnd,
        },
      });
    }
  });

  onDestroy(() => {
    if (control) {
      control.destroy();
    }
  });
</script>

<div class="input-field">
  {#if multiple}
    <select bind:this={select} multiple bind:value on:change class={$$props.class}>
      <slot />
    </select>
  {:else}
    <select bind:this={select} on:blur bind:value on:change class={$$props.class}>
      <slot />
    </select>
  {/if}
  <label>{label}</label>
</div>
