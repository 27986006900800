import { __awaiter } from "tslib";
import { ReadablePromise } from "./readable_promise";
export default class ListStore extends ReadablePromise {
    constructor(keySelector) {
        super(Promise.resolve([]));
        this.KeySelector = keySelector;
        this.Sort();
    }
    Clear() {
        return this.Set(Promise.resolve([]));
    }
    AddItem(...items) {
        for (let item of items) {
            return this.UpdateValue((v) => {
                let i = this.binarySearch(v, this.KeySelector(item));
                if (i < 0) {
                    i = ~i;
                }
                v.splice(i, 0, item);
                return v;
            });
        }
    }
    UpdateItem(item) {
        return this.UpdateValue((v) => {
            let i = this.binarySearch(v, this.KeySelector(item));
            console.log(v);
            if (i < 0) {
                i = ~i;
                v.splice(i, 0, item);
            }
            v[i] = item;
            return v;
        });
    }
    DeleteItem(key) {
        return this.UpdateValue((v) => {
            let i = this.binarySearch(v, key);
            if (i > 0) {
                v.splice(i, 1);
                return v;
            }
        });
    }
    GetItem(key) {
        return __awaiter(this, void 0, void 0, function* () {
            let v = yield this;
            let i = this.binarySearch(v, key);
            if (i < 0) {
                return undefined;
            }
            return v[i];
        });
    }
    Sort() {
        this.UpdateValue((v) => {
            v.sort((a, b) => this.Compare(this.KeySelector(a), this.KeySelector(b)));
            return v;
        });
    }
    binarySearch(v, key) {
        let start = 0;
        let end = v.length - 1;
        while (start <= end) {
            let mid = (start + end) >>> 1;
            let compareResult = this.Compare(this.KeySelector(v[mid]), key);
            switch (compareResult) {
                case 0:
                    return mid;
                case 1:
                    end = mid - 1;
                    break;
                case -1:
                    start = mid + 1;
                    break;
            }
        }
        return ~start;
    }
    Compare(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }
}
