export default {
    delete_report_config_message: "Are you sure you want to delete this report configuration?",
    information: "Information",
    table_component: "Table",
    no_sections_configured: "No Sections Configured",
    entire_report_period: "All",
    unit_meta_data: "Unit Information",
    paragraph_component: "Paragraph",
    add_row: "Add Row",
    failed: "Failed",
    manual: "Manual",
    column: "Column",
    cover_page: "Cover Page",
    start_point: "Start Point (days:hours)",
    section: "Section",
    no_interval_configured: "No Interval Configured",
    linear: "Linear",
    choose_column_type: "Choose Column Type",
    legend: "Legend",
    paragraph: "Paragraph",
    interval_in_days: "Interval (days)",
    no_reports: "No Reports",
    traces: "Traces",
    new_section: "New Section",
    std_dev: "Standard Deviation",
    components: "Components",
    last30_days: "Last 30 Days",
    time_series_chart: "Time Series Chart",
    delete_interval: "Delete Interval",
    no_components_configured: "No Components Configured",
    section_component: "Section",
    columns: "Columns",
    duration_with_example: "Duration (days:hours)",
    add_column: "Add A Column",
    start_date: "Start Date",
    start_time: "Start Time",
    pending: "Pending",
    choose_component_type: "Choose Component Type",
    details: "Details",
    display: "Display",
    datapoint: "Datapoint",
    interval: "Interval",
    unable_to_save_report_configurations: "Unable to save report configurations",
    end_date: "End Date",
    run_on_interval: "Run On Interval",
    next_run_time: "Next Run Time",
    unable_to_get_report_configurations: "Unable to get report configurations",
    min: "Minimum",
    max: "Maximum",
    log: "Log",
    run: "Run",
    table: "Table",
    title: "Title",
    range: "Range",
    right: "Right",
    scale: "Scale",
    y_axis: "Y-Axis",
    x_axis: "X-Axis",
    last_year: "Last Year",
    complete: "Complete",
    copy_report_config: "Copy Report Configuration",
    appearance: "Appearance",
    last120_days: "Last 120 Days",
    run_settings: "Run Settings",
    run_date: "Run Date",
    chart_details: "Chart Details",
    running: "Running",
    report_configurations_saved: "Report configurations saved",
    x_axis_label: "X-Axis Label",
    auto_range: "Auto Range",
    choose_datapoint: "Choose Datapoint",
    average: "Average",
    sections: "Sections",
    delete_section_message: "Are you sure you want to delete this section?",
    delete_component_message: "Are you sure you want to delete this component?",
    add_report_config: "New Report Configuration",
    start_point_with_example: "Start Point (hours)",
    add_component: "Add Commponent",
    unable_to_run_report: "Unable to run the report.",
    description: "Description",
    no_columns_configured: "No Columns Configured",
    duration: "Duration",
    no_reports_configured: "No Reports Configured",
    add_trace: "Add New Trace",
    no_rows_configured: "No Rows Configured",
    all_reports: "All Reports",
    axis: "Axis",
    left: "Left",
    view: "View",
    type: "Type",
    rows: "Rows",
    html: "HTML",
    json: "JSON",
};
