<script lang="ts">
  import ContentBox from "components/ContentBox.svelte";
  import FooterBox from "components/FooterBox.svelte";
  import { ApiClient } from "setup";
  import { _ } from "svelte-i18n";
  import type User from "api/user/User";
  import Button from "components/Button.svelte";
  import Checkbox from "components/Checkbox.svelte";
  import Textbox from "components/Textbox.svelte";
  import { Translate } from "localization/i18n";
  import { ErrorToast, Toast } from "utils/toast";
  import PhoneNumberInput from "components/PhoneNumberInput.svelte";
  import { FormValidator, Nop, NotEmpty, Regexp, ShorterThan } from "utils/validate";

  export let user: User;
  export let orgID: string;

  let loading = false;

  async function save(): Promise<void> {
    try {
      loading = true;
      if (user.Key) {
        await ApiClient.User.Update(orgID, user);
      } else {
        await ApiClient.User.Add(orgID, user);
      }
      Toast(Translate("saved"));
      formValidator.MarkClean();
    } catch (e) {
      ErrorToast(e);
    } finally {
      loading = false;
    }
  }

  let formValidator = new FormValidator();
</script>

<ContentBox>
  <Textbox
    bind:value={user.FirstName}
    validation={formValidator
      .Is(NotEmpty($_('first_name_is_required')))
      .Is(ShorterThan(64, 'first_name_length_error'))}>
    {$_('first_name')}
  </Textbox>
  <Textbox
    bind:value={user.LastName}
    validation={formValidator
      .Is(NotEmpty($_('last_name_is_required')))
      .Is(ShorterThan(64, 'last_name_length_error'))}>
    {$_('last_name')}
  </Textbox>
  <Textbox
    bind:value={user.Email}
    validation={formValidator
      .Is(NotEmpty($_('email_is_required')))
      .Is(Regexp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, $_('please_enter_an_email_address')))}>
    {$_('email')}
  </Textbox>
  <PhoneNumberInput bind:value={user.PhoneNumber} validation={formValidator.Is()}>
    {$_('phone_number')}
  </PhoneNumberInput>
  <Checkbox bind:value={user.EmailNotification} validation={formValidator.Is(Nop)}>
    {$_('email_notification')}
  </Checkbox>
  <Checkbox bind:value={user.TextNotification} validation={formValidator.Is(Nop)}>
    {$_('text_notification')}
  </Checkbox>
</ContentBox>
<FooterBox>
  <Button
    {loading}
    disabled={!$formValidator.IsDirty() || !$formValidator.IsValid()}
    on:click={save}>
    {$_('save')}
  </Button>
</FooterBox>
