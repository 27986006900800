<script lang="ts">
  import { ShorterThan, FormValidator, NotEmpty, Regexp, Nop } from "utils/validate";
  import type User from "api/user/User";
  import Card from "components/Card.svelte";
  import TitleBox from "components/TitleBox.svelte";
  import ContentBox from "components/ContentBox.svelte";
  import FooterBox from "components/FooterBox.svelte";
  import { _ } from "svelte-i18n";
  import Textbox from "components/Textbox.svelte";
  import Checkbox from "components/Checkbox.svelte";
  import PhoneNumberInput from "components/PhoneNumberInput.svelte";
  import { ApiClient } from "setup";
  import Select from "components/Select.svelte";
  import Button from "components/Button.svelte";
  import M from "materialize-css";
  import { Translate } from "localization/i18n";
  import { Toast, ErrorToast } from "utils/toast";

  export let user: User;
  export let orgID: string;

  let loading = false;

  async function save(): Promise<void> {
    try {
      loading = true;
      await ApiClient.User.UpdateSelf(orgID, user);
      Toast(Translate("saved"));
      formValidator.MarkClean();
    } catch (e) {
      ErrorToast(e);
    } finally {
      loading = false;
    }
  }

  let formValidator = new FormValidator();
</script>

<Card>
  <TitleBox>
    <h5>{$_('basic_information')}</h5>
  </TitleBox>
  <ContentBox>
    <Textbox
      bind:value={user.FirstName}
      validation={formValidator
        .Is(NotEmpty($_('first_name_is_required')))
        .Is(ShorterThan(64, 'first_name_length_error'))}>
      {$_('first_name')}
    </Textbox>
    <Textbox
      bind:value={user.LastName}
      validation={formValidator
        .Is(NotEmpty($_('last_name_is_required')))
        .Is(ShorterThan(64, 'last_name_length_error'))}>
      {$_('last_name')}
    </Textbox>
    <Textbox
      bind:value={user.Email}
      validation={formValidator
        .Is(NotEmpty($_('email_is_required')))
        .Is(Regexp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, $_('please_enter_an_email_address')))}>
      {$_('email')}
    </Textbox>
    <PhoneNumberInput bind:value={user.PhoneNumber} validation={formValidator}>
      {$_('phone_number')}
    </PhoneNumberInput>
    <Checkbox bind:value={user.EmailNotification} validation={formValidator.Is(Nop)}>
      {$_('email_notification')}
    </Checkbox>
    <Checkbox bind:value={user.TextNotification} validation={formValidator.Is(Nop)}>
      {$_('text_notification')}
    </Checkbox>
  </ContentBox>
  <FooterBox>
    <Button
      {loading}
      disabled={!$formValidator.IsDirty() || !$formValidator.IsValid()}
      on:click={save}>
      {$_('save')}
    </Button>
  </FooterBox>
</Card>
