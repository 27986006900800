<script lang="ts">
    import { ChartOptions, Draw } from "chart";

    let chart: HTMLCanvasElement;

    export let width: number;
    export let height: number;

    export let data: ChartOptions = null;

    // @ts-ignore
    $: if (data && chart) {
        Draw(chart, data);
    }
</script>

<canvas bind:this={chart} {width} {height} style="height: {height}" />
