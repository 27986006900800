<script>
  import { Router } from "@sveltech/routify";
  import { routes } from "@sveltech/routify/tmp/routes";
  import "cookieconsent";

  window.cookieconsent.initialise({
    container: document.getElementById("content"),
    palette: {
      popup: {
        background: "#fafafa",
        text: "#000000",
      },
      button: {
        background: "#252525",
        text: "#ffffff",
      },
    },
    law: {
      regionalLaw: false,
    },
  });
</script>

<style lang="scss" global>
  @import "cookieconsent/build/cookieconsent.min";
  @import "./theme.scss";
  @import "materialize-css/sass/materialize.scss";
</style>

<Router {routes} />
