<script lang="ts">
  import ContentBox from "components/ContentBox.svelte";
  import Modal from "components/Modal.svelte";
  import Spinner from "components/Spinner.svelte";
  import Longpress from "utils/longpress";
  import Dropdown from "components/Dropdown.svelte";
  import DropdownItem from "components/DropdownItem.svelte";
  import { fade } from "svelte/transition";

  export let items: Promise<any[]>;
  export let groupActions: Record<string, (selected: any[]) => void | any[]> = {};
  let filterModalOpen: boolean = false;
  let _items: FilterItem[];

  $: {
    items.then((r) => (_items = r.map((i) => new FilterItem(i))));
  }

  function doAction(action: (selected: any[]) => void | any[]) {
    let result = action(_items.filter((i) => i.Selected).map((i) => i.Item));
    if (result) {
      _items = result.map((i) => new FilterItem(i));
    }
  }

  class FilterItem {
    constructor(item: any) {
      this.Item = item;
    }
    Item: any;
    Selected: boolean;
  }
</script>

<style lang="scss">
  .filter-button {
    position: absolute;
    top: -32px;
    right: 0;
    z-index: 100;
    cursor: pointer;
  }

  span.filter-button {
    width: 100%;
    text-align: right;
  }

  ul {
    overflow: visible;
  }
</style>

<ul in:fade class="collection with-header">
  {#if _items && !_items.some((fi) => fi.Selected)}
    <i
      on:click={() => (filterModalOpen = !filterModalOpen)}
      class="material-icons hide-on-large-only filter-button">
      filter_list
    </i>
  {/if}
  {#if _items && _items.some((fi) => fi.Selected)}
    <span class="filter-button">
      <Dropdown>
        <i slot="label" class="material-icons">more_vert</i>
        {#each Object.keys(groupActions) as actionName}
          <DropdownItem>
            <span on:click={() => doAction(groupActions[actionName])}>{actionName}</span>
          </DropdownItem>
        {/each}
      </Dropdown>
    </span>
  {/if}
  <li class="collection-header hide-on-med-and-down">
    <slot name="controls" />
  </li>

  {#await items}
    <li class="collection-item">
      <Spinner size="medium" />
    </li>
  {:then _}
    {#if _items.length > 0}
      {#each _items as item}
        <li
          use:Longpress
          on:longpress={() => {
            if (Object.keys(groupActions).length > 0) {
              item.Selected = !item.Selected;
            }
          }}
          on:click={() => {
            item.Selected = false;
          }}
          class="collection-item avatar"
          class:active={item.Selected}>

          <slot item={item ? item.Item : undefined} />
        </li>
      {/each}
    {:else}
      <li class="collection-item">
        <slot name="no-items" />
      </li>
    {/if}
  {:catch err}
    <li class="collection-item">
      <div in:fade class="center">{err}</div>
    </li>
  {/await}
</ul>
<Modal bind:open={filterModalOpen}>
  <ContentBox>
    <slot name="controls" />
  </ContentBox>
</Modal>
