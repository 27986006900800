<script lang="ts">
  import { onMount } from "svelte";
  import GenerateID from "../utils/id";
  import M from "materialize-css";

  let trigger: HTMLElement;

  //Assign a random id if not id is specified.
  export let id: string = GenerateID();

  onMount(() => {
    let control = M.Dropdown.init(trigger, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenEnd: () => {
        control.recalculateDimensions();
      },
    });
  });
</script>

<span bind:this={trigger} class="dropdown-trigger" data-target={id}>
  <slot name="label" />
</span>
<ul {id} class="dropdown-content">
  <slot />
</ul>
