export default {
    "eastern_standard_time": "(UTC-05:00) Eastern Time (US & Canada)",
    "argentina_standard_time": "(UTC-03:00) City of Buenos Aires",
    "fle_standard_time": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "bougainville_standard_time": "(UTC+11:00) Bougainville Island",
    "jordan_standard_time": "(UTC+02:00) Amman",
    "myanmar_standard_time": "(UTC+06:30) Yangon (Rangoon)",
    "n_central_asia_standard_time": "(UTC+07:00) Novosibirsk",
    "gtb_standard_time": "(UTC+02:00) Athens, Bucharest",
    "yakutsk_standard_time": "(UTC+09:00) Yakutsk",
    "hawaiian_standard_time": "(UTC-10:00) Hawaii",
    "namibia_standard_time": "(UTC+01:00) Windhoek",
    "new_zealand_standard_time": "(UTC+12:00) Auckland, Wellington",
    "georgian_standard_time": "(UTC+04:00) Tbilisi",
    "central_european_standard_time": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "cape_verde_standard_time": "(UTC-01:00) Cabo Verde Is.",
    "omsk_standard_time": "(UTC+06:00) Omsk",
    "line_islands_standard_time": "(UTC+14:00) Kiritimati Island",
    "greenwich_standard_time": "(UTC+00:00) Monrovia, Reykjavik",
    "nepal_standard_time": "(UTC+05:45) Kathmandu",
    "aus_eastern_standard_time": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "russia_time_zone3": "(UTC+04:00) Izhevsk, Samara",
    "north_asia_standard_time": "(UTC+07:00) Krasnoyarsk",
    "pacific_standard_time_mexico": "(UTC-08:00) Baja California",
    "us_mountain_standard_time": "(UTC-07:00) Arizona",
    "north_asia_east_standard_time": "(UTC+08:00) Irkutsk",
    "pakistan_standard_time": "(UTC+05:00) Islamabad, Karachi",
    "central_standard_time": "(UTC-06:00) Central Time (US & Canada)",
    "mid_atlantic_standard_time": "(UTC-02:00) Mid-Atlantic - Old",
    "west_bank_standard_time": "(UTC+02:00) Gaza, Hebron",
    "pacific_standard_time": "(UTC-08:00) Pacific Time (US & Canada)",
    "samoa_standard_time": "(UTC+13:00) Samoa",
    "libya_standard_time": "(UTC+02:00) Tripoli",
    "morocco_standard_time": "(UTC+00:00) Casablanca",
    "west_pacific_standard_time": "(UTC+10:00) Guam, Port Moresby",
    "arabic_standard_time": "(UTC+03:00) Baghdad",
    "w_mongolia_standard_time": "(UTC+07:00) Hovd",
    "caucasus_standard_time": "(UTC+04:00) Yerevan",
    "arab_standard_time": "(UTC+03:00) Kuwait, Riyadh",
    "chatham_islands_standard_time": "(UTC+12:45) Chatham Islands",
    "easter_island_standard_time": "(UTC-06:00) Easter Island",
    "haiti_standard_time": "(UTC-05:00) Haiti",
    "alaskan_standard_time": "(UTC-09:00) Alaska",
    "korea_standard_time": "(UTC+09:00) Seoul",
    "astrakhan_standard_time": "(UTC+04:00) Astrakhan, Ulyanovsk",
    "marquesas_standard_time": "(UTC-09:30) Marquesas Islands",
    "tocantins_standard_time": "(UTC-03:00) Araguaina",
    "vladivostok_standard_time": "(UTC+10:00) Vladivostok",
    "central_brazilian_standard_time": "(UTC-04:00) Cuiaba",
    "magadan_standard_time": "(UTC+11:00) Magadan",
    "tokyo_standard_time": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "afghanistan_standard_time": "(UTC+04:30) Kabul",
    "greenland_standard_time": "(UTC-03:00) Greenland",
    "bangladesh_standard_time": "(UTC+06:00) Dhaka",
    "central_europe_standard_time": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "ekaterinburg_standard_time": "(UTC+05:00) Ekaterinburg",
    "fiji_standard_time": "(UTC+12:00) Fiji",
    "tomsk_standard_time": "(UTC+07:00) Tomsk",
    "e_africa_standard_time": "(UTC+03:00) Nairobi",
    "e_south_america_standard_time": "(UTC-03:00) Brasilia",
    "tonga_standard_time": "(UTC+13:00) Nuku'alofa",
    "india_standard_time": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "saratov_standard_time": "(UTC+04:00) Saratov",
    "norfolk_standard_time": "(UTC+11:00) Norfolk Island",
    "montevideo_standard_time": "(UTC-03:00) Montevideo",
    "utc": "(UTC) Coordinated Universal Time",
    "azerbaijan_standard_time": "(UTC+04:00) Baku",
    "u_t_c_12": "(UTC+12:00) Coordinated Universal Time+12",
    "u_t_c_13": "(UTC+13:00) Coordinated Universal Time+13",
    "u_t_c_11": "(UTC-11:00) Coordinated Universal Time-11",
    "u_t_c_02": "(UTC-02:00) Coordinated Universal Time-02",
    "u_t_c_09": "(UTC-09:00) Coordinated Universal Time-09",
    "u_t_c_08": "(UTC-08:00) Coordinated Universal Time-08",
    "singapore_standard_time": "(UTC+08:00) Kuala Lumpur, Singapore",
    "turks_and_caicos_standard_time": "(UTC-04:00) Turks and Caicos",
    "russia_time_zone10": "(UTC+11:00) Chokurdakh",
    "russia_time_zone11": "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
    "south_africa_standard_time": "(UTC+02:00) Harare, Pretoria",
    "pacific_sa_standard_time": "(UTC-04:00) Santiago",
    "sri_lanka_standard_time": "(UTC+05:30) Sri Jayawardenepura",
    "central_pacific_standard_time": "(UTC+11:00) Solomon Is., New Caledonia",
    "mountain_standard_time": "(UTC-07:00) Mountain Time (US & Canada)",
    "syria_standard_time": "(UTC+02:00) Damascus",
    "magallanes_standard_time": "(UTC-03:00) Punta Arenas",
    "middle_east_standard_time": "(UTC+02:00) Beirut",
    "venezuela_standard_time": "(UTC-04:00) Caracas",
    "sa_eastern_standard_time": "(UTC-03:00) Cayenne, Fortaleza",
    "west_asia_standard_time": "(UTC+05:00) Ashgabat, Tashkent",
    "central_america_standard_time": "(UTC-06:00) Central America",
    "ulaanbaatar_standard_time": "(UTC+08:00) Ulaanbaatar",
    "w_europe_standard_time": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "azores_standard_time": "(UTC-01:00) Azores",
    "w_australia_standard_time": "(UTC+08:00) Perth",
    "romance_standard_time": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "gmt_standard_time": "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    "canada_central_standard_time": "(UTC-06:00) Saskatchewan",
    "lord_howe_standard_time": "(UTC+10:30) Lord Howe Island",
    "tasmania_standard_time": "(UTC+10:00) Hobart",
    "mountain_standard_time_mexico": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "saint_pierre_standard_time": "(UTC-03:00) Saint Pierre and Miquelon",
    "aleutian_standard_time": "(UTC-10:00) Aleutian Islands",
    "arabian_standard_time": "(UTC+04:00) Abu Dhabi, Muscat",
    "aus_central_w_standard_time": "(UTC+08:45) Eucla",
    "turkey_standard_time": "(UTC+03:00) Istanbul",
    "paraguay_standard_time": "(UTC-04:00) Asuncion",
    "china_standard_time": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "aus_central_standard_time": "(UTC+09:30) Darwin",
    "kamchatka_standard_time": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "taipei_standard_time": "(UTC+08:00) Taipei",
    "cen_australia_standard_time": "(UTC+09:30) Adelaide",
    "newfoundland_standard_time": "(UTC-03:30) Newfoundland",
    "kaliningrad_standard_time": "(UTC+02:00) Kaliningrad",
    "north_korea_standard_time": "(UTC+08:30) Pyongyang",
    "us_eastern_standard_time": "(UTC-05:00) Indiana (East)",
    "sakhalin_standard_time": "(UTC+11:00) Sakhalin",
    "e_europe_standard_time": "(UTC+02:00) Chisinau",
    "eastern_standard_time_mexico": "(UTC-05:00) Chetumal",
    "israel_standard_time": "(UTC+02:00) Jerusalem",
    "cuba_standard_time": "(UTC-05:00) Havana",
    "belarus_standard_time": "(UTC+03:00) Minsk",
    "russian_standard_time": "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
    "e_australia_standard_time": "(UTC+10:00) Brisbane",
    "transbaikal_standard_time": "(UTC+09:00) Chita",
    "mauritius_standard_time": "(UTC+04:00) Port Louis",
    "iran_standard_time": "(UTC+03:30) Tehran",
    "sa_western_standard_time": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "central_standard_time_mexico": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "sa_pacific_standard_time": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
    "atlantic_standard_time": "(UTC-04:00) Atlantic Time (Canada)",
    "bahia_standard_time": "(UTC-03:00) Salvador",
    "w_central_africa_standard_time": "(UTC+01:00) West Central Africa",
    "dateline_standard_time": "(UTC-12:00) International Date Line West",
    "altai_standard_time": "(UTC+07:00) Barnaul, Gorno-Altaysk",
    "egypt_standard_time": "(UTC+02:00) Cairo",
    "se_asia_standard_time": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "central_asia_standard_time": "(UTC+06:00) Astana"
};
