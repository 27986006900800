export default {
    team_name_length_error: "Team Name must be less than 64 characters.",
    unable_to_add_user: "Unable to add user.  Try again in a moment.",
    there_was_a_problem_saving_the_zones: "There was a problem saving the zones",
    problem_deleting_team: "There was a problem deleting the team. Please try again later.",
    team_description_required: "Team description is required.",
    alert_subscriptions_saved: "Alert Subscriptions Saved",
    alert_subscriptions: "Alert Subscriptions",
    delete_team: "Delete Team",
    user_removed_from_team: "User Removed From Team",
    no_teams: "No Teams",
    team_created: "Team Created",
    unable_to_update_zones_for_team: "Unable to update zones.  Try again in a moment.",
    are_you_sure_you_want_to_delete_this_team: "Are you sure you want to delete this team?",
    team_deleted: "Team Deleted",
    team_updated: "Team Updated",
    no_alerts_available_to_subscribe_to: "No alerts available to subscribe to.",
    unable_to_create_team: "Unable to create team.",
    team_name_required: "Team name is required.",
    create_team: "Create Team",
    team_duplicate_name_error: "A team with that name already exists. Choose another name.",
    team_name_format_error: "Team Name may not contain any special characters.",
    unable_to_update_team: "Unable to update team.",
    zones_saved: "Zones Saved",
    there_was_a_problem_saving_alert_subscriptions: "There was a problem saving alert subscriptions. Please try again later",
    unable_to_remove_user: "Unable to remove user.  Try again in a moment.",
    team_members: "Team Members",
    there_was_a_problem_fetching_alert_subscription: "There was a problem fetching alert subscriptions.  Please try again later.",
    team_created_with_issues: "Team created, but there was a problem adding some users to team",
    click_here_to_setup_alerts: "Click here to setup alerts.",
    user_added_to_team: "User Added To Team",
    number_of_team_members: "# of Team Members",
};
