<script lang="ts">
  import Textbox from "../../components/Textbox.svelte";
  import Button from "../../components/Button.svelte";
  import type Role from "../../api/roles/Role";
  import { _ } from "svelte-i18n";
  import { ShorterThan, FormValidator, NotEmpty, Regexp, Nop } from "../../utils/validate";
  import { ApiClient } from "../../setup";
  import { Toast, ErrorToast } from "../../utils/toast";
  import { Translate } from "../../localization/i18n";
  import type { Activity } from "api/shared/Activity";
  import { derived, Readable } from "svelte/store";
  import { params, goto } from "@sveltech/routify";
  import type ActivityDescription from "api/roles/ActivityDescription";
  import Spinner from "components/Spinner.svelte";
  import CheckBox from "components/Checkbox.svelte";
  import Collapsable from "components/Collapsable.svelte";
  import CollapsableItem from "components/CollapsableItem.svelte";
  import Select from "components/Select.svelte";
  import { fade } from "svelte/transition";
  import { IsGenosysAdmin } from "state/Authentication";
  import ContentBox from "components/ContentBox.svelte";
  import FooterBox from "components/FooterBox.svelte";

  export let role: Role;

  let formValidator = new FormValidator();
  let loading = false;

  let activities: Readable<Promise<ActivityDescription[]>> = derived(params, ($params) => {
    return ApiClient.Role.GetActivities($params.organization);
  });

  async function save(): Promise<void> {
    try {
      loading = true;
      if (role.NameKey) {
        await ApiClient.Role.Update($params.organization, role);
      } else {
        await ApiClient.Role.Add($params.organization, role);
      }
      Toast(Translate("saved"));
      formValidator.MarkClean();
    } catch (e) {
      ErrorToast(e);
    } finally {
      loading = false;
    }
  }

  if (!role.Activities) {
    role.Activities = [];
  }

  interface EditForm<T> {
    Delete(): Promise<void>;
    ListTitle: string;
    AddTitle: string;
    ItemHeader: (item: T) => string;
    NoItemsMessage: string;
  }
</script>

<style lang="scss">
  h6 {
    opacity: 0.6;
    margin-bottom: 1.093rem;
  }
</style>
<ContentBox>
<Textbox
  bind:value={role.DisplayName}
  validation={formValidator
    .Is(NotEmpty($_('name_is_required')))
    .Is(ShorterThan(64, 'name_length_error'))}>
  {$_('name')}
</Textbox>
<Textbox
  bind:value={role.Description}
  validation={formValidator
    .Is(NotEmpty($_('description_is_required')))
    .Is(ShorterThan(64, 'description_length_error'))}>
  {$_('description')}
</Textbox>
{#await $activities}
  <Spinner />
{:then _activities}
  <h6>{$_('permissions')}</h6>
  <Collapsable accordion={true}>
    {#each _activities.groupBy((a) => a.Category) as group}
      <CollapsableItem>
        <span slot="header">{Translate(group[0].Category, { default: group[0].Category })}</span>
        {#each group as activity}
          {#if !activity.KatoOnly || $IsGenosysAdmin}
            <CheckBox
              bind:group={role.Activities}
              groupValue={activity.Value}
              validation={formValidator.Is(Nop)}>
              {Translate(activity.Name, { default: activity.Description })}
              {#if activity.KatoOnly}
                <span class="red-text">Caution Nidec only</span>
              {/if}
            </CheckBox>
          {/if}
        {/each}
      </CollapsableItem>
    {/each}
  </Collapsable>
{:catch err}
  <div in:fade class="center">{err}</div>
{/await}
</ContentBox>
<FooterBox>
<Button {loading} disabled={!$formValidator.IsDirty() || !$formValidator.IsValid()} on:click={save}>
  {#if role.NameKey}{$_('save')}{:else}{$_('add')}{/if}
</Button>
</FooterBox>
