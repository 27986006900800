export default {
    name_length_error: "Le nom doit comporter moins de 64 caractères.",
    username_is_required: "E-mail est obligatoire.",
    data_bits_is_required: "Le nombre de bits de données est obligatoire.",
    port_must_be_an_integer: "Le port doit être un entier.",
    order_must_be_greater_than0: "L'ordre doit être supérieur à 0.",
    please_enter_a_message: "Saisissez un message.",
    order_must_be_an_integer: "L'ordre doit être un entier.",
    slave_id_must_be_an_integer: "L'ID esclave doit être un entier.",
    at_least_one_connection_type_is_required: "Au moins un type de connexion est obligatoire.",
    baud_rate_must_be_between50_and460800: "Le débit doit être compris entre 50 et 460 800.",
    location_is_required: "L'emplacement est obligatoire.",
    type_is_required: "Le type est obligatoire.",
    legal_consent_is_required: "Le consentement est requis.",
    slave_id_is_required: "L'ID esclave est obligatoire.",
    baud_rate_is_required: "Le débit est obligatoire.",
    operation_is_required: "L'opération est obligatoire.",
    units_is_required: "Les unités sont obligatoires.",
    value_must_be_an_integer: "La valeur doit être un entier.",
    order_must_be_unique: "L'ordre doit être unique.",
    timeout_is_required: "Le délai d'attente est obligatoire.",
    last_name_is_required: "Le nom est obligatoire.",
    timeout_must_be_an_integer: "Le délai d'attente doit être un entier.",
    value_must_be_between_values: "La valeur doit être comprise entre {0} et {1}",
    maximum_register_grouping_distance_must_be_an_integer: "La distance maximale de regroupement de registres doit être un entier.",
    confirm_password_is_required: "La confirmation du mot de passe est obligatoire.",
    address_is_required: "L'adresse est obligatoire.",
    request_name_contains_illegal_characters: "Le nom ne peut pas contenir les caractères /, #, ? ou \\",
    address_must_be_an_integer: "L'adresse doit être un entier.",
    confirm_new_password_is_required: "La confirmation du nouveau mot de passe est obligatoire.",
    minimum_request_period_must_be_greater_than_zero: "La période de demande minimale doit être supérieure à zéro.",
    minimum_request_period_is_required: "La période de demande minimale est obligatoire.",
    stop_bits_is_required: "Le nombre de bits d'arrêt est obligatoire.",
    modbus_function_code_required: "Le code de fonction Modbus est requis",
    value_is_required: "La valeur est obligatoire.",
    minimum_request_period_must_be_an_integer: "La période de demande minimale doit être un entier.",
    maximum_register_grouping_distance_is_required: "La distance maximale de regroupement de registres est obligatoire.",
    type_is_invalid: "Le type n'est pas valide.",
    maximum_register_grouping_distance_must_be_between0_and125: "La distance maximale de regroupement de registres doit être comprise entre 0 et 125.",
    description_is_required: "La description est obligatoire.",
    new_password_is_required: "Le nouveau mot de passe est obligatoire.",
    time_zone_is_required: "Le fuseau horaire est obligatoire.",
    parity_is_required: "La parité est obligatoire.",
    slave_id_must_be_between1_and247: "L'ID esclave doit être compris entre 1 et 247.",
    name_is_required: "Le nom est obligatoire.",
    email_is_required: "Le email est obligatoire.",
    address_must_be_between1_and65535: "L'adresse doit être comprise entre 1 et 65535.",
    first_name_is_required: "Le prénom est obligatoire.",
    current_password_is_required: "Le mot de passe actuel est obligatoire.",
    timeout_must_be_greater_than_zero: "Le délai d'attente doit être supérieur à 0.",
    port_must_be_between1_and65535: "Le port doit être compris entre 1 et 65535.",
    baud_rate_must_be_an_integer: "Le débit doit être un entier.",
    please_enter_an_email_address: "Saisissez une adresse e-mail.",
    port_is_required: "Le port est obligatoire.",
    request_name_must_be_unique: "Le nom de la demande doit être unique.",
    password_is_required: "Le mot de passe est obligatoire.",
    order_is_required: "L'ordre est obligatoire.",
};
