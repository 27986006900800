<script lang="ts">
  import { goto, params } from "@sveltech/routify";
  import Button from "components/Button.svelte";
  import Card from "components/Card.svelte";
  import ContentBox from "components/ContentBox.svelte";
  import FooterBox from "components/FooterBox.svelte";
  import Textbox from "components/Textbox.svelte";
  import TitleBox from "components/TitleBox.svelte";
  import { ApiClient } from "setup";
  import AuthState from "state/Authentication";
  import { _ } from "svelte-i18n";
  import { FormValidator, NotEmpty, Regexp } from "utils/validate";

  let username: string;
  let password: string;
  let errorMessage: string = "";
  let validator = new FormValidator();

  async function Login() {
    try {
      loading = true;
      await ApiClient.Login(username, password);
      loading = false;
      if ($params.return_url) {
        $goto($params.return_url);
      } else {
        $goto("/");
      }
    } catch (e) {
      errorMessage = e;
      loading = false;
    }
  }

  let loading = false;

  if ($AuthState.AuthHeader) {
    $goto("/orgs");
  }
</script>

<style lang="scss">
  @import "theme.scss";
  .title {
    text-align: center;
  }
  .action {
    text-align: center;
  }

  #forgot-password {
    margin-right: 0;
  }

  .error-message {
    color: #da291c;
    font-size: 14px;
    font-weight: 500;
  }

  #forgot-password {
    color: $primary-color;
  }
</style>

<div class="row">
  <div class="col s12 l6 offset-l3 xl4 offset-xl4">
    <Card>
      <TitleBox>
        <h3 class="title">{$_('login')}</h3>
      </TitleBox>
      <form on:submit|preventDefault={Login}>
        <ContentBox>
          <div class="error-message">{errorMessage}</div>
          <div class="row">
            <div class="col s12">
              <Textbox
                id="email"
                bind:value={username}
                validation={validator.Is(NotEmpty($_('email_is_required')), Regexp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, $_('please_enter_an_email_address')))}>
                {$_('email')}
              </Textbox>
              <Textbox
                id="password"
                type="password"
                bind:value={password}
                validation={validator.Is(NotEmpty($_('password_is_required')))}>
                {$_('password')}
              </Textbox>
            </div>
          </div>
        </ContentBox>
        <FooterBox>
          <div class="action">
            <Button {loading}>{$_('login')}</Button>
            <a class="forgot-password" id="forgot-password" href="/forgot_password">
              {$_('forgot_password')}
            </a>
          </div>
        </FooterBox>
      </form>
    </Card>
  </div>
</div>
