<script lang="typescript">
  import AuthState from "../state/Authentication";
  import { goto } from "@sveltech/routify";

  if ($AuthState.AuthHeader) {
    $goto("/orgs");
  } else {
    $goto(`/login?return_url=${window.location.pathname}`);
  }
</script>
