<script lang="ts">
  import { goto } from "@sveltech/routify";
  import params from "utils/params";
  import type AlertConfig from "api/alertConfig/AlertConfig";
  import type Unit from "api/unit/Unit";
  import ContentBox from "components/ContentBox.svelte";
  import Modal from "components/Modal.svelte";
  import Select from "components/Select.svelte";
  import Spinner from "components/Spinner.svelte";
  import HiddenControls from "components/HiddenControls.svelte";
  import Button from "components/Button.svelte";
  import { ApiClient } from "setup";
  import { _ } from "svelte-i18n";
  import { derived, Readable } from "svelte/store";
  import type { Configuration } from "api/graphs/GraphConfiguration";
  import { GraphType } from "api/graphs/GraphType";
  import { CalculateAlerts, GetSensors } from "chart";
  import type { ChartOptions } from "chart";
  import type { GraphData } from "api/graphs/GraphData";
  import Chart from "components/Chart.svelte";

  export let unit: Unit;

  let aggregation: string = "day";
  let graphKey: string;
  let windowWidth;

  let configurations: Readable<Promise<Configuration[]>> = derived(
    params,
    ($params: any) => {
      return ApiClient.Graph.List(
        $params.orgID,
        $params.zoneID,
        $params.unitID
      );
    }
  );

  let alertConfigs: Readable<Promise<AlertConfig[]>> = derived(
    params,
    ($params: any) => {
      return ApiClient.AlertConfig.List(
        $params.orgID,
        $params.zoneID,
        $params.unitID
      );
    }
  );

  let graphData: Readable<Promise<ChartOptions>> = derived(
    params,
    async ($params:any) => {
      if (!$params.graph ) {
        return null;
      }
      let config = await  ApiClient.Graph.Get(
        $params.orgID,
        $params.zoneID,
        $params.unitID,
        $params.graph
      );

      aggregation = $params.aggregation ?? GraphType.DayAvg;

      let configs = await $alertConfigs;
      configs = configs.filter((c) =>
        config.Alerts.some((key) => c.Key == key)
      );

      let additionalSensors: string[] = GetSensors(...configs);

      let values: GraphData[] = await ApiClient.Graph.Data(
        $params.orgID,
        $params.zoneID,
        $params.unitID,
        $params.aggregation,
        [...config.Sensors, ...additionalSensors].unique()
      );

      for (let graph of values) {
        let sensor = unit.Sensors.first((s) => s.Name == graph.Sensor);
        graph.ShownName = sensor.ShownName;
        graph.Unit = sensor.Unit;
      }

      let data: ChartOptions = {
        Title: config.Title,
        Culture: "en-us", //Not sure what to do here.
        Traces: [],
      };

      for (let sensor of config.Sensors) {
        let value = values.first((v) => {
          return v.Sensor == sensor;
        });
        data.Traces.push({
          Key: sensor,
          Name: value.ShownName,
          Readings: value.Readings.map((r) => {
            return { Time: r.TimeStamp, Value: r.Value };
          }),
          Units: value.Unit 
        });
      }

      let alertTraces = CalculateAlerts(
        config.Sensors,
        values.map((v) => {
          return {
            Key: v.Sensor,
            Name: v.ShownName,
            Readings: v.Readings.map((r) => {
              return { Time: r.TimeStamp, Value: r.Value };
            }),
            Units: v.Unit
          };
        }),
        configs
      );
      data.Traces.push(...alertTraces);

      return data;
    }
  );
</script>

{#if $params.graph != undefined}
  <Modal open={$params.graph != undefined} on:close:end={() => $goto('./')}>
    <ContentBox>
      <HiddenControls
        on:close={() => {
          $params.aggregation = aggregation;
          $params.graph = graphKey;
        }}>
        <div slot="controls" let:close>
          {#await $configurations}
            <Spinner size="large" />
          {:then _configurations}
            <Select label={$_('graphs')} bind:value={graphKey}>
              {#each _configurations as config}
                <option value={config.Key}>{config.Title}</option>
              {/each}
            </Select>
            <Select bind:value={aggregation} label={$_('range')}>
              {#each _configurations
                .mapMany((c) => c.Aggregations)
                .unique() as availableAggregation}
                <option value={availableAggregation}>
                  {$_(availableAggregation)}
                </option>
              {/each}
            </Select>
            <Button on:click={close}>{$_('close')}</Button>
          {:catch err}
            {err}
          {/await}
        </div>
        {#await $graphData}
          <Spinner size="large" />
        {:then _graphData}
          <Chart
            width={windowWidth}
            height={window.innerWidth < 1000 ? 400 : 800}
            data={_graphData} />
        {:catch err}
          {err}
        {/await}
      </HiddenControls>
    </ContentBox>
  </Modal>
{/if}

<svelte:window bind:innerWidth={windowWidth} />
