import { params, redirect } from "@sveltech/routify";
import { get } from "svelte/store";
import DeepCopy from "./deep_copy";
import DeepEqual from "./deep_equal";
let { subscribe } = params;
let oldValue = undefined;
let $goto;
export default {
    subscribe: (run, invalidate) => {
        $goto = get(redirect);
        return subscribe(run, invalidate);
    },
    set: (value) => {
        for (let key in value) {
            if (value[key] == undefined || value[key] == 'undefined') {
                delete (value[key]);
            }
        }
        if (!DeepEqual(value, oldValue)) {
            oldValue = DeepCopy(value);
            $goto("", value);
        }
    },
};
